import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import {
  getManagerAction,
  ManagerActionType,
  updateManagerAction,
} from '@src/admin/reducers/manager/managerActions'
import { StoreState } from '@src/admin/reducers'
import { Button } from '@mui/material'
import { useGlobalSnackbar, useLoading } from '@src/hooks'
import ContentHeader from '@components/ContentHeader'
import DataTable from '@components/DataTable'
import Snackbar, { SnackbarState } from '@src/components/Snackbar'
import { ControlledSelect, ControlledTextInput } from '@components/hookForm'
import { ManagerDetail, ManagerKeys } from '@src/admin/models/manager'
import {
  CHARACTER_LIMIT,
  emailPattern,
  INVALID_FORMAT,
  REQUIRED,
} from '@src/utils/validation'
import commonMessages from '@common/messages'
import messages from '@admin/utils/messages'
import { ReactComponent as IconSave } from '@src/assets/icons/iconSave.svg'
import { adminPath, toAdminRoute } from '@common/appConfig'
import { ErrorResponse } from '@src/utils/type'
import { DETAIL_BREADCRUMBS } from './utils'

export default function ManagerEdit() {
  const dispatch = useDispatch()
  const { currentManager } = useSelector((state: StoreState) => state.manager)
  const { formatMessage: f } = useIntl()
  const navigate = useNavigate()
  const { id } = useParams()
  const { control, handleSubmit } = useForm<ManagerDetail>({
    defaultValues: currentManager,
  })
  const { status, setSnackbar, clearSnackbar } = useGlobalSnackbar({
    page: 'manager',
  })
  const { openSpinner } = useLoading({
    endActions: {
      success: [
        ManagerActionType.GET_MANAGER_SUCCESS,
        ManagerActionType.UPDATE_MANAGER_SUCCESS,
      ],
      error: [
        ManagerActionType.GET_MANAGER_ERROR,
        ManagerActionType.UPDATE_MANAGER_ERROR,
      ],
    },
    cbk: {
      success: ({ type }: { type: ManagerActionType }) => {
        if (type === ManagerActionType.UPDATE_MANAGER_SUCCESS) {
          setSnackbar({
            state: SnackbarState.Success,
            message: f(commonMessages.updateSuccess),
            notKeepData: false,
          })
          navigate(generatePath(toAdminRoute(adminPath.manager.detail), { id }))
        }
      },
      error: ({ error }: { error: ErrorResponse }) => {
        setSnackbar({
          state: SnackbarState.Error,
          message: error.message,
          notKeepData: true,
        })
        document.documentElement.scrollTop = 0
      },
    },
  })

  useEffect(() => {
    openSpinner()
    dispatch(getManagerAction(id!))
  }, [dispatch, openSpinner, id])

  const onSubmit = (data: ManagerDetail) => {
    openSpinner()
    dispatch(updateManagerAction({ id: id!, data }))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {status && (
        <Snackbar
          severity={status.state}
          message={status.message!}
          onClose={clearSnackbar}
        />
      )}
      <ContentHeader
        title={f(messages.managerEdit)}
        breadcrumbs={DETAIL_BREADCRUMBS.concat({
          title: messages.managerEdit,
        })}
        buttonList={[
          <Button variant="contained" type="submit" startIcon={<IconSave />}>
            {f(commonMessages.save)}
          </Button>,
        ]}
      />
      <DataTable
        title={f(commonMessages.basicInfo)}
        dataSets={[
          {
            label: f(commonMessages.name),
            content: (
              <ControlledTextInput
                name={ManagerKeys.Name}
                control={control}
                required={REQUIRED}
                rules={{
                  maxLength: {
                    value: CHARACTER_LIMIT,
                    message: INVALID_FORMAT,
                  },
                }}
              />
            ),
          },
          {
            label: f(commonMessages.email),
            content: (
              <ControlledTextInput
                name={ManagerKeys.Email}
                control={control}
                required={REQUIRED}
                rules={{
                  pattern: emailPattern,
                }}
              />
            ),
          },
          {
            label: f(commonMessages.status),
            content: (
              <ControlledSelect
                name={ManagerKeys.Active}
                control={control}
                options={[
                  { label: f(commonMessages.active), value: true },
                  { label: f(commonMessages.inactive), value: false },
                ]}
              />
            ),
          },
        ]}
      />
    </form>
  )
}
