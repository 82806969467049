import { Agency } from '@user/models/agency'
import { ActionType } from '@src/utils/type'
import { AgencyActionType, ListParams } from './agencyActions'

export interface AgencyState {
  agencyList: Agency[]
  agencyTotal: number
}

const initState: AgencyState = {
  agencyList: [],
  agencyTotal: 0,
}

export const AgencyPreloadState = initState

const handler: Record<
  string,
  (state: AgencyState, action: ActionType<any>) => AgencyState
> = {
  [AgencyActionType.GET_LIST_SUCCESS]: (
    state: AgencyState = initState,
    action: ActionType<ListParams>
  ): AgencyState => {
    return {
      ...state,
      agencyList: action.payload.Items,
      agencyTotal: action.payload.Count,
    }
  },
}

export default function agencyReducer(
  state: AgencyState = initState,
  action: ActionType<any>
) {
  if (handler.hasOwnProperty(action.type)) {
    return handler[action.type](state, action)
  }
  return state
}
