export enum DeviceKeys {
  UserId = 'UserID',
  GroupId = 'GroupID',
  SN = 'SN',
  DeviceType = 'DeviceType',
  EnrollTime = 'EnrollTime',
  ConnectReady = 'ConnectReady',
  BatteryHMD = 'BatteryHMD',
  BatteryCL = 'BatteryCL',
  BatteryCR = 'BatteryCR',
  Status = 'Status',
  LastTask = 'LastTask',
  LastTaskTime = 'LastTaskTime',

  DeviceUserId = 'U',
  EnrollUrl = 'E',
  DeviceName = 'D',
  EnrollKey = 'K',
  WifiSetting = 'W',
  SSDId = 'S',
  Type = 'T',
  Password = 'P',

  EAPMethod = 'M',
  Phase2Method = 'P2',
  Domain = 'D',
  Cert = 'C',
  ClientCert = 'CC',
  Identify = 'I',
  AnonymousIdentify = 'AI',

  Serial = 'Serial',
  AutoIncrease = 'AutoIncrease',
  DeviceNameEnable = 'DeviceNameEnable',
  WifiSettingEnable = 'WifiSettingEnable',
}

export interface Device {
  [DeviceKeys.UserId]: string
  [DeviceKeys.GroupId]: string
  [DeviceKeys.SN]: string
  [DeviceKeys.DeviceType]: string
  [DeviceKeys.EnrollTime]: string
  [DeviceKeys.ConnectReady]: boolean
  [DeviceKeys.BatteryHMD]: number
  [DeviceKeys.BatteryCL]: number
  [DeviceKeys.BatteryCR]: number
  [DeviceKeys.Status]: string
  [DeviceKeys.LastTask]: string
  [DeviceKeys.LastTaskTime]: string
}

export interface DeviceEnroll {
  [DeviceKeys.DeviceUserId]?: string
  [DeviceKeys.EnrollUrl]?: string
  [DeviceKeys.EnrollKey]?: string
  [DeviceKeys.DeviceName]?: string
  [DeviceKeys.Serial]?: number
  [DeviceKeys.AutoIncrease]?: boolean
  [DeviceKeys.DeviceNameEnable]?: boolean
  [DeviceKeys.WifiSettingEnable]?: boolean
  [DeviceKeys.WifiSetting]?: WifiSetting
}

export interface WifiSetting {
  [DeviceKeys.SSDId]?: string
  [DeviceKeys.Type]?: WifiType
  [DeviceKeys.Password]?: string
  [DeviceKeys.EAPMethod]?: EAPMethod
  [DeviceKeys.Phase2Method]?: Phase2Method
  [DeviceKeys.Domain]?: string
  [DeviceKeys.Cert]?: Cert
  [DeviceKeys.ClientCert]?: ClientCert
  [DeviceKeys.Identify]?: string
  [DeviceKeys.AnonymousIdentify]?: string
}

export enum WifiType {
  AnonymousLogin = 'AL',
  WEP = 'WEP',
  PSK = 'PSK',
  SAE = 'SAE',
  EAP = 'EAP',
}

export enum EAPMethod {
  PEAP = 'PEAP',
  TLS = 'TLS',
  TTLS = 'TTLS',
  PWD = 'PWD',
}

export enum Phase2Method {
  NONE = 'NONE',
  MSCHAPV2 = 'MSCHAPV2',
  GTC = 'GTC',
}

export enum Cert {
  Unspecified = 'Unspecified',
  DoNotValidate = 'Do not validate',
  UseSystemCerts = 'Use system certs',
}

export enum ClientCert {
  Unspecified = 'Unspecified',
  DoNotProvide = 'Do not provide',
}
