export enum ManagerKeys {
  Name = 'FullName',
  Id = 'ID',
  Email = 'Email',
  Active = 'Active',
}

export interface ManagerDetail {
  [ManagerKeys.Id]: string
  [ManagerKeys.Email]: string
  [ManagerKeys.Name]: string
  [ManagerKeys.Active]: boolean
}
