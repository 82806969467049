import { UserDashboardDetail } from '@src/manager/models/userDashboard'
import { ActionType } from '@src/utils/type'
import { UserDashboardActionType } from './userDashboardActions'

export interface UserDashboardState {
  currentUserDashboard?: UserDashboardDetail
}

const initState: UserDashboardState = {}

export const UserDashboardPreloadState = initState

const updateUserDashboardHandler = (
  state: UserDashboardState = initState,
  action: ActionType<UserDashboardDetail | undefined>
): UserDashboardState => {
  return {
    ...state,
    currentUserDashboard: action.payload,
  }
}

const handler: Record<
  string,
  (state: UserDashboardState, action: ActionType<any>) => UserDashboardState
> = {
  [UserDashboardActionType.GET_USER_DASHBOARD_SUCCESS]:
    updateUserDashboardHandler,
  [UserDashboardActionType.RESET_USER_DASHBOARD]: updateUserDashboardHandler,
}

export default function userDashboardReducer(
  state: UserDashboardState = initState,
  action: ActionType<any>
) {
  if (handler.hasOwnProperty(action.type)) {
    return handler[action.type](state, action)
  }
  return state
}
