import React from 'react'
import { useIntl } from 'react-intl'
import { styled } from '@mui/system'
import { Box, Paper } from '@mui/material'
import { ReactComponent as IconSuccess } from '../../assets/icons/iconRoundCheck.svg'
import { ReactComponent as IconFailed } from '../../assets/icons/iconError.svg'
import messages from '../../common/messages'
import { backgroundBlack } from '../../common/styles'

const SuccessImg = styled(IconSuccess)(({ theme }) => ({
  marginRight: theme.spacing(2),
  ' & path': {
    fill: theme.palette.primary.main,
  },
}))
const FailedImg = styled(IconFailed)(({ theme }) => ({
  marginRight: theme.spacing(2),
  ' & path': {
    fill: theme.palette.primary.main,
  },
}))

const LoginWrap = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      height="100vh"
      bgcolor={backgroundBlack}
      display="flex"
      alignItems="center">
      <Box mx="auto" minWidth="400px">
        <Paper>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding={2}>
            {children}
          </Box>
        </Paper>
      </Box>
    </Box>
  )
}

export function LoginSuccess() {
  const { formatMessage: f } = useIntl()

  return (
    <LoginWrap>
      <SuccessImg />
      {f(messages.vbcLoginSuccess)}
    </LoginWrap>
  )
}

export function LoginFailed() {
  const { formatMessage: f } = useIntl()

  return (
    <LoginWrap>
      <FailedImg />
      {f(messages.loginFailed)}
    </LoginWrap>
  )
}
