import { JSON_CONTENT } from '@src/utils/ajaxUtils'
import authAjax from '@manager/utils/ajaxUtils'
import { AgencyPayload } from '@manager/models/agency'
import { objToQueryStr } from '@src/utils/function'

const API_PATH = '/user'

export const getListAjax = (params: Object) =>
  authAjax.get(`${API_PATH}/jump${objToQueryStr(params)}`, JSON_CONTENT)

export const createAgencyAjax = (data: AgencyPayload) =>
  authAjax.post(API_PATH, data, JSON_CONTENT)

export const deleteAgencyAjax = (id: string) =>
  authAjax.delete(`${API_PATH}/${id}`, JSON_CONTENT)
