import { AnyAction } from 'redux'
import { ofType } from 'redux-observable'
import {
  empty,
  exhaustMap,
  iif,
  map,
  mergeMap,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs'
import { errorSubject, successSubject } from '@src/utils/responseSubject'
import { catchErrorHandler } from '@manager/utils/ajaxUtils'
import * as memberServices from './memberServices'
import {
  createMemberFailedAction,
  createMemberSuccessAction,
  deleteMemberSuccessAction,
  getMemberSuccessAction,
  MemberActionType,
  updateMemberSuccessAction,
} from '@src/manager/reducers/member/memberActions'
import { AjaxResponse } from 'rxjs/ajax'
import { SUCCESS_BRIEF_CODE } from '@src/utils/ajaxUtils'

const createMember = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(MemberActionType.CREATE_MEMBER),
    exhaustMap(({ payload }) =>
      memberServices.createMemberAjax(payload).pipe(
        mergeMap((result: AjaxResponse<any>) =>
          iif(
            () => result?.response?.BriefCode === SUCCESS_BRIEF_CODE,
            of(createMemberSuccessAction()).pipe(
              tap(() =>
                successSubject.next({
                  type: MemberActionType.CREATE_MEMBER_SUCCESS,
                })
              )
            ),
            of(createMemberFailedAction()).pipe(
              tap(() =>
                errorSubject.next({
                  type: MemberActionType.CREATE_MEMBER_ERROR,
                  error: result?.response?.Message,
                })
              )
            )
          )
        ),
        catchErrorHandler((error) => {
          errorSubject.next({
            type: MemberActionType.CREATE_MEMBER_ERROR,
            error: error.response?.data,
          })
          return empty()
        })
      )
    )
  )

const getMember = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(MemberActionType.GET_MEMBER),
    switchMap(({ payload }) =>
      memberServices.getMemberAjax(payload).pipe(
        map((res) => getMemberSuccessAction(res.response.Message)),
        tap(() =>
          successSubject.next({
            type: MemberActionType.GET_MEMBER_SUCCESS,
          })
        ),
        catchErrorHandler((error) => {
          errorSubject.next({
            type: MemberActionType.GET_MEMBER_ERROR,
            error: error.response?.Message,
          })
          return empty()
        })
      )
    )
  )

const updateMember = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(MemberActionType.UPDATE_MEMBER),
    exhaustMap(({ payload }) =>
      memberServices.updateMemberAjax(payload).pipe(
        map(() => updateMemberSuccessAction()),
        tap(() =>
          successSubject.next({
            type: MemberActionType.UPDATE_MEMBER_SUCCESS,
          })
        ),
        catchErrorHandler((error) => {
          errorSubject.next({
            type: MemberActionType.UPDATE_MEMBER_ERROR,
            error: error.response?.data,
          })
          return empty()
        })
      )
    )
  )

const deleteMember = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(MemberActionType.DELETE_MEMBER),
    exhaustMap(({ payload }) =>
      memberServices.deleteMemberAjax(payload).pipe(
        map(() => deleteMemberSuccessAction()),
        tap(() =>
          successSubject.next({
            type: MemberActionType.DELETE_MEMBER_SUCCESS,
          })
        ),
        catchErrorHandler((error) => {
          errorSubject.next({
            type: MemberActionType.DELETE_MEMBER_ERROR,
            error: error.response?.data,
          })
          return empty()
        })
      )
    )
  )

const memberEpics = [createMember, getMember, updateMember, deleteMember]

export default memberEpics
