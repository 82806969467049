import { JSON_CONTENT } from '@src/utils/ajaxUtils'
import authAjax from '@manager/utils/ajaxUtils'
import { UserInfoRequest } from '@src/manager/models/userDashboard'

const API_PATH = '/user'

export const getDashboardAjax = (id: string) =>
  authAjax.get(`${API_PATH}/${id}`, JSON_CONTENT)

export const updateUserInfoAjax = ({ id, data }: UserInfoRequest) =>
  authAjax.put(`${API_PATH}/${id}`, data, JSON_CONTENT)
