import { _uuid } from '@src/utils/function'

const {
  REACT_APP_HOST,
  REACT_APP_VBC_GOOGLE_CLIENT_ID,
  REACT_APP_VBC_GOOGLE_REDIRECT_URI,
  REACT_APP_ADMIN_MICROSOFT_CLIENT_ID,
  REACT_APP_ADMIN_MICROSOFT_TENANT,
  REACT_APP_MANAGER_MICROSOFT_CLIENT_ID,
  REACT_APP_MANAGER_MICROSOFT_TENANT,
  REACT_APP_USER_GOOGLE_CLIENT_ID,
  REACT_APP_USER_HTC_CLIENT_ID,
  REACT_APP_VBC_HTC_REDIRECT_URL,
  REACT_APP_ENROLL_URL,
} = process.env

export const ACCOUNT_TERMS = 'https://dl.vive.com/EULA/Vive+EULA_ENG.PDF'
export const PRIVACY_POLICY = 'https://www.htc.com/us/terms/privacy/'

export const PAGE_LIMIT = 50
export const AUTHORIZATION = 'authorization'
export const ACCOUNT = 'account'
export const ENROLL_URL = REACT_APP_ENROLL_URL ?? ''

export const GOOGLE_OAUTH = 'https://accounts.google.com/o/oauth2/v2/auth'
export const STATE = 'state'
export const GOOGLE_CODE_KEY = 'code'
export const GOOGLE_VBC_OAUTH_PRAM = {
  redirect_uri: REACT_APP_VBC_GOOGLE_REDIRECT_URI ?? '',
  prompt: 'consent',
  response_type: 'code',
  client_id: REACT_APP_VBC_GOOGLE_CLIENT_ID ?? '',
  scope: 'https://www.googleapis.com/auth/userinfo.email',
  access_type: 'offline',
  flowName: 'GeneralOAuthFlow',
}
export const GOOGLE_USER_OAUTH_PRAM = {
  redirect_uri: `${REACT_APP_HOST ?? ''}/user/login`,
  prompt: 'consent',
  response_type: 'code',
  client_id: REACT_APP_USER_GOOGLE_CLIENT_ID ?? '',
  scope: 'https://www.googleapis.com/auth/userinfo.email',
  access_type: 'offline',
}

const ADMIN_MSAL_REDIRECT_URI = encodeURIComponent(
  `${REACT_APP_HOST}/admin/login`
)
const ADMIN_MSAL_CLIENT_ID = REACT_APP_ADMIN_MICROSOFT_CLIENT_ID
export const MSAL_ADMIN_OAUTH = `https://login.microsoftonline.com/${REACT_APP_ADMIN_MICROSOFT_TENANT}/oauth2/v2.0/authorize?scope=openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2Fmail.read&client_id=${ADMIN_MSAL_CLIENT_ID}&response_type=code&redirect_uri=${ADMIN_MSAL_REDIRECT_URI}&response_mode=fragment`
export const MSAL_ADMIN_LOGOUT = `https://login.microsoftonline.com/${REACT_APP_ADMIN_MICROSOFT_TENANT}/oauth2/v2.0/logout?client_id=${ADMIN_MSAL_CLIENT_ID}&post_logout_redirect_uri=${ADMIN_MSAL_REDIRECT_URI}`

const MANAGER_MSAL_REDIRECT_URI = encodeURIComponent(
  `${REACT_APP_HOST}/manager/login`
)
const MANAGER_MSAL_CLIENT_ID = REACT_APP_MANAGER_MICROSOFT_CLIENT_ID
export const MSAL_MANAGER_OAUTH = `https://login.microsoftonline.com/${REACT_APP_MANAGER_MICROSOFT_TENANT}/oauth2/v2.0/authorize?scope=openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2Fmail.read&client_id=${MANAGER_MSAL_CLIENT_ID}&response_type=code&redirect_uri=${MANAGER_MSAL_REDIRECT_URI}&response_mode=fragment`
export const MSAL_MANAGER_LOGOUT = `https://login.microsoftonline.com/${REACT_APP_MANAGER_MICROSOFT_TENANT}/oauth2/v2.0/logout?client_id=${MANAGER_MSAL_CLIENT_ID}&post_logout_redirect_uri=${MANAGER_MSAL_REDIRECT_URI}`

export const HTC_OAUTH =
  'https://account.htcvive.com/SS/Services/OAuth/Authorize'
const USER_HTC_REDIRECT_URI = `${REACT_APP_HOST}/user/login`
const USER_HTC_CLIENT_ID = REACT_APP_USER_HTC_CLIENT_ID ?? ''
export const HTC_USER_OAUTH_PRAM = {
  redirection_url: USER_HTC_REDIRECT_URI,
  client_id: USER_HTC_CLIENT_ID,
  immediate: 'FALSE',
  response_type: 'code',
  state: _uuid(),
  scope: 'email',
}
export const HTC_USER_LOGOUT = `https://account.htcvive.com/logout?client_id=${USER_HTC_CLIENT_ID}&redirect_url=${encodeURIComponent(
  USER_HTC_REDIRECT_URI
)}`
export const HTC_VBC_OAUTH_PRAM = {
  redirection_url: REACT_APP_VBC_HTC_REDIRECT_URL,
  client_id: USER_HTC_CLIENT_ID,
  immediate: 'FALSE',
  response_type: 'code',
  scope: 'email',
}
