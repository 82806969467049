import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { setDeviceEnrollAction } from '@src/user/reducers/device/deviceActions'
import { StoreState } from '@src/user/reducers'
import {
  DeviceEnroll as DeviceEnrollModel,
  DeviceKeys,
} from '@src/user/models/device'
import ContentHeader from '@components/ContentHeader'
import { toUserRoute, userPath } from '@common/appConfig'
import GlobalDialog from '@src/components/GlobalDialog'
import EnrollQRCode from './components/EnrollQRCode'
import EnrollForm from './components/EnrollForm'
import { _uuid } from '@src/utils/function'
import commonMessages from '@common/messages'
import messages from './messages'
import { BREADCRUMBS, covertToStorageEnroll, toEnrollData } from './utils'
import { ENROLL_URL } from '@src/common/constants'

export default function DeviceEnroll() {
  const { formatMessage: f } = useIntl()
  const { id } = useParams()
  const { deviceEnroll } = useSelector((state: StoreState) => state.device)
  const dispatch = useDispatch()

  const handleEnroll = (values: DeviceEnrollModel) => {
    const enrollKey = _uuid()
    const { deviceName, data } = toEnrollData({
      ...values,
      [DeviceKeys.DeviceUserId]: id,
      [DeviceKeys.EnrollUrl]: ENROLL_URL,
      [DeviceKeys.EnrollKey]: enrollKey,
    })
    console.log('on submit', data)
    GlobalDialog.open({
      onClose: () => handleClose(values),
      closeText: f(commonMessages.close),
      content: (
        <EnrollQRCode
          deviceName={deviceName ?? ''}
          enrollKey={enrollKey}
          value={data}
        />
      ),
      fullScreen: true,
    })
  }

  const handleClose = (values: DeviceEnrollModel) => {
    dispatch(setDeviceEnrollAction(covertToStorageEnroll(values)))
  }

  return (
    <>
      <ContentHeader
        title={f(messages.deviceEnroll)}
        breadcrumbs={BREADCRUMBS.concat([
          {
            title: messages.deviceList,
            route: generatePath(toUserRoute(userPath.device.list), {
              id,
            }),
          },
          {
            title: messages.deviceEnroll,
          },
        ])}
      />
      <EnrollForm deviceEnroll={deviceEnroll} onSubmit={handleEnroll} />
    </>
  )
}
