import { defineMessages } from 'react-intl'

const PREFIX = 'admin.'

export default defineMessages({
  adminManagement: {
    id: PREFIX + 'admin_management',
    defaultMessage: 'Admin',
  },
  managerManagement: {
    id: PREFIX + 'manager_management',
    defaultMessage: 'Manager',
  },
  adminList: {
    id: PREFIX + 'admin_management.list',
    defaultMessage: 'Admin List',
  },
  adminDetail: {
    id: PREFIX + 'admin_management.detail',
    defaultMessage: 'Admin Detail',
  },
  adminEdit: {
    id: PREFIX + 'admin_management.edit',
    defaultMessage: 'Admin Edit',
  },
  adminCreate: {
    id: PREFIX + 'admin_management.create',
    defaultMessage: 'Admin Create',
  },
  managerList: {
    id: PREFIX + 'manager_management.list',
    defaultMessage: 'Manager List',
  },
  managerDetail: {
    id: PREFIX + 'manager_management.detail',
    defaultMessage: 'Manager Detail',
  },
  managerEdit: {
    id: PREFIX + 'manager_management.edit',
    defaultMessage: 'Manager Edit',
  },
  managerCreate: {
    id: PREFIX + 'manager_management.create',
    defaultMessage: 'Manager Create',
  },
})
