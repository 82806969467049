import { ManagerDetail } from '@src/admin/models/manager'
import { ActionType } from '@src/utils/type'
import { ManagerActionType, ListParams } from './managerActions'

export interface ManagerState {
  managerList: ManagerDetail[]
  managerTotal: number
  currentManager?: ManagerDetail
}

const initState: ManagerState = {
  managerList: [],
  managerTotal: 0,
}

export const ManagerPreloadState = initState

const updateCurrentManagerHandler = (
  state: ManagerState = initState,
  action: ActionType<ManagerDetail | undefined>
): ManagerState => {
  return {
    ...state,
    currentManager: action.payload,
  }
}

const handler: Record<
  string,
  (state: ManagerState, action: ActionType<any>) => ManagerState
> = {
  [ManagerActionType.GET_LIST_SUCCESS]: (
    state: ManagerState = initState,
    action: ActionType<ListParams>
  ): ManagerState => {
    return {
      ...state,
      managerList: action.payload.Items,
      managerTotal: action.payload.Count,
    }
  },
  [ManagerActionType.GET_MANAGER_SUCCESS]: updateCurrentManagerHandler,
  [ManagerActionType.CREATE_MANAGER_SUCCESS]: updateCurrentManagerHandler,
  [ManagerActionType.UPDATE_MANAGER_SUCCESS]: updateCurrentManagerHandler,
  [ManagerActionType.DELETE_MANAGER_SUCCESS]: updateCurrentManagerHandler,
  [ManagerActionType.RESET_MANAGER]: updateCurrentManagerHandler,
}

export default function managerReducer(
  state: ManagerState = initState,
  action: ActionType<any>
) {
  if (handler.hasOwnProperty(action.type)) {
    return handler[action.type](state, action)
  }
  return state
}
