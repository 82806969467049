import React from 'react'
import { useIntl } from 'react-intl'
import { Control } from 'react-hook-form'
import { MemberDetail, UserDashboardKeys } from '@manager/models/userDashboard'
import {
  ControlledSelect,
  ControlledTextArea,
  ControlledTextInput,
} from '@src/components/hookForm'
import DataTable from '@src/components/DataTable'
import commonMessages from '@common/messages'
import {
  CHARACTER_LIMIT,
  DESCRIPTIONd_LIMIT,
  emailPattern,
  INVALID_FORMAT,
  REQUIRED,
} from '@src/utils/validation'

export default function MemberForm({
  control,
}: {
  control: Control<MemberDetail>
}) {
  const { formatMessage: f } = useIntl()

  return (
    <DataTable
      dataSets={[
        {
          label: f(commonMessages.name),
          content: (
            <ControlledTextInput
              name={UserDashboardKeys.Name}
              control={control}
              required={REQUIRED}
              rules={{
                maxLength: {
                  value: CHARACTER_LIMIT,
                  message: INVALID_FORMAT,
                },
              }}
            />
          ),
        },
        {
          label: f(commonMessages.email),
          content: (
            <ControlledTextInput
              name={UserDashboardKeys.Email}
              control={control}
              required={REQUIRED}
              rules={{
                pattern: emailPattern,
              }}
            />
          ),
        },
        {
          label: f(commonMessages.status),
          content: (
            <ControlledSelect
              name={UserDashboardKeys.Active}
              control={control}
              options={[
                { label: f(commonMessages.active), value: true },
                { label: f(commonMessages.inactive), value: false },
              ]}
            />
          ),
        },
        {
          label: f(commonMessages.description),
          content: (
            <ControlledTextArea
              name={UserDashboardKeys.Description}
              control={control}
              rules={{
                maxLength: {
                  value: DESCRIPTIONd_LIMIT,
                  message: INVALID_FORMAT,
                },
              }}
            />
          ),
        },
      ]}
    />
  )
}
