import React from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Button } from '@mui/material'
import ContentHeader from '@components/ContentHeader'
import DataTable from '@components/DataTable'
import {
  ControlledNumberInput,
  ControlledTextArea,
  ControlledTextInput,
} from '@components/hookForm'
import { AgencyKeys, AgencyPayload } from '@manager/models/agency'
import { useGlobalSnackbar, useLoading } from '@src/hooks'
import {
  CHARACTER_LIMIT,
  DESCRIPTIONd_LIMIT,
  emailPattern,
  INVALID_FORMAT,
  REQUIRED,
} from '@src/utils/validation'
import commonMessages from '@common/messages'
import messages from '@manager/utils/messages'
import { ReactComponent as IconAdd } from '@src/assets/icons/iconAdd.svg'
import { DETAIL_BREADCRUMBS } from './utils'
import {
  createAgencyAction,
  AgencyActionType,
} from '@manager/reducers/agency/agencyActions'
import {
  managerPath,
  RouteSection,
  toManagerRoute,
} from '@src/common/appConfig'
import { ErrorResponse } from '@src/utils/type'
import Snackbar, { SnackbarState } from '@src/components/Snackbar'

export default function AgencyListCreate() {
  const dispatch = useDispatch()
  const { formatMessage: f } = useIntl()
  const { control, handleSubmit } = useForm<AgencyPayload>()
  const navigate = useNavigate()
  const { status, setSnackbar, clearSnackbar } = useGlobalSnackbar({
    page: RouteSection.Agency,
  })
  const { openSpinner } = useLoading({
    endActions: {
      success: [AgencyActionType.CREATE_AGENCY_SUCCESS],
      error: [AgencyActionType.CREATE_AGENCY_ERROR],
    },
    cbk: {
      success: () => {
        navigate(toManagerRoute(managerPath.agencyList.list))
      },
      error: ({ error }: { error: ErrorResponse }) => {
        setSnackbar({
          state: SnackbarState.Error,
          message: error.message,
          notKeepData: true,
        })
        document.documentElement.scrollTop = 0
      },
    },
  })

  const onSubmit = (data: AgencyPayload) => {
    openSpinner()
    dispatch(createAgencyAction(data))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ContentHeader
        title={f(messages.agencyCreate)}
        breadcrumbs={DETAIL_BREADCRUMBS.concat({
          title: messages.agencyCreate,
        })}
        buttonList={[
          <Button variant="contained" type="submit" startIcon={<IconAdd />}>
            {f(commonMessages.create)}
          </Button>,
        ]}
      />
      {status && (
        <Snackbar
          severity={status.state}
          message={status.message!}
          onClose={clearSnackbar}
        />
      )}
      <DataTable
        title={f(commonMessages.basicInfo)}
        dataSets={[
          {
            label: f(commonMessages.name),
            content: (
              <ControlledTextInput
                name={AgencyKeys.Name}
                control={control}
                required={REQUIRED}
                rules={{
                  maxLength: {
                    value: CHARACTER_LIMIT,
                    message: INVALID_FORMAT,
                  },
                }}
              />
            ),
          },
          {
            label: f(commonMessages.maxDeviceCount),
            content: (
              <ControlledNumberInput
                name={AgencyKeys.MaxDeviceCount}
                control={control}
                required={REQUIRED}
                rules={{
                  min: {
                    value: 0,
                    message: INVALID_FORMAT,
                  },
                }}
              />
            ),
          },
          {
            label: f(commonMessages.description),
            content: (
              <ControlledTextArea
                name={AgencyKeys.Description}
                control={control}
                rules={{
                  maxLength: {
                    value: DESCRIPTIONd_LIMIT,
                    message: INVALID_FORMAT,
                  },
                }}
              />
            ),
          },
        ]}
      />
      <DataTable
        title={f(messages.createMemberInfo)}
        dataSets={[
          {
            label: f(commonMessages.name),
            content: (
              <ControlledTextInput
                name={`${AgencyKeys.Member}.${AgencyKeys.Name}`}
                control={control}
                required={REQUIRED}
                rules={{
                  maxLength: {
                    value: CHARACTER_LIMIT,
                    message: INVALID_FORMAT,
                  },
                }}
              />
            ),
          },
          {
            label: f(commonMessages.email),
            content: (
              <ControlledTextInput
                name={`${AgencyKeys.Member}.${AgencyKeys.Email}`}
                control={control}
                required={REQUIRED}
                rules={{
                  pattern: emailPattern,
                }}
              />
            ),
          },
        ]}
      />
    </form>
  )
}
