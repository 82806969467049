import React from 'react'
import { Outlet } from 'react-router-dom'
import { styled } from '@mui/material'
import Header from './components/Header'
import Sidebar, { SidebarTab } from './components/Sidebar'

export const Content = styled('main')(({ theme }) => ({
  marginTop: theme.custom.height.header,
  marginLeft: theme.custom.width.sidebar,
  padding: theme.spacing(2),
}))

export const ContentWithoutSidebar = styled('main')(({ theme }) => ({
  marginTop: theme.custom.height.header,
  padding: theme.spacing(2),
}))

export default function Layout({ sidebarTabs }: { sidebarTabs: SidebarTab[] }) {
  return (
    <>
      <Header identity="" />
      <Sidebar tabs={sidebarTabs} />
      <Content>
        <Outlet />
      </Content>
    </>
  )
}
