import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export const objToQueryStr = (params?: object) =>
  params
    ? '?' +
      Object.entries(params)
        .filter(([_, value]) => value !== undefined)
        .map(([key, value]) =>
          Array.isArray(value)
            ? `${key}=${encodeURIComponent(value.join(','))}`
            : `${key}=${encodeURIComponent(value)}`
        )
        .join('&')
    : ''

export const _range = (start: number, end: number) => {
  const stepper = start > end ? -1 : 1
  return new Array(Math.abs(end - start))
    .fill(start)
    .map((num, idx) => num + idx * stepper)
}

export const toMsalCode: (hash: string) => string = (hash: string) =>
  hash.split('&session_state=')[0].split('#code=')[1]

export const toCamelCase: (data: { [key: string]: any }) => object = (data) =>
  Object.entries(data).reduce((acc, [key, value]) => {
    const curKey = key.charAt(0).toLowerCase() + key.substr(1)
    acc[curKey] = value
    return acc
  }, {} as { [key: string]: any })

export const toDateTime = (data: string | null) =>
  data ? dayjs(data).format('YYYY-MM-DD HH:mm') : ''

export const millisecondToMinute = (time: number) =>
  Math.floor(time / (60 * 1000))

export const minuteToMillisecond = (time: number) => time * 60 * 1000

export const flatObj: (obj: any, prefix?: string) => { [key: string]: any } = (
  obj,
  prefix
) => {
  if (obj !== null && typeof obj !== 'object') {
    return {}
  }

  return Object.keys(obj).reduce((acc, k) => {
    const value = obj[k]
    const key = prefix ? `${prefix}.${k}` : k
    return typeof value === 'object'
      ? { ...acc, ...flatObj(value, key) }
      : { ...acc, [key]: value }
  }, {} as { [key: string]: any })
}

export function _uuid() {
  let d = Date.now()
  if (
    typeof performance !== 'undefined' &&
    typeof performance.now === 'function'
  ) {
    d += performance.now() //use high-precision timer if available
  }
  return 'xxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (d + Math.random() * 16) % 16 | 0
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16).toUpperCase()
  })
}
