import React from 'react'
import { MessageDescriptor, useIntl } from 'react-intl'
import { MenuItem, MenuList, styled } from '@mui/material'
import { generatePath, Link, useParams } from 'react-router-dom'

export interface SidebarTab {
  icon: React.FC
  title: MessageDescriptor
  basePath?: string
  route: string
}

const TabIcon = ({ icon }: { icon: React.FC }) => {
  const Svg = styled(icon)(({ theme }) => ({
    display: 'inline-block',
    marginRight: theme.spacing(1),
    width: 28,
  }))
  return <Svg />
}

export default function Sidebar({ tabs }: { tabs: SidebarTab[] }) {
  const { formatMessage: f } = useIntl()
  const { id } = useParams()

  return (
    <MenuList
      sx={(theme) => ({
        width: theme.custom.width.sidebar,
        backgroundColor: theme.custom.background.sidebar,
        position: 'fixed',
        height: '100vh',
        '&> a': {
          color: theme.palette.common.white,
        },
      })}>
      {tabs.map((tab) => (
        <Link key={tab.title.id} to={generatePath(tab.route, { id })}>
          <MenuItem>
            <TabIcon icon={tab.icon} />
            {f(tab.title)}
          </MenuItem>
        </Link>
      ))}
    </MenuList>
  )
}
