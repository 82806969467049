import { defineMessages } from 'react-intl'

const PREFIX = 'user.dashboard.'

export default defineMessages({
  dashboard: {
    id: PREFIX + 'dashboard',
    defaultMessage: 'Dashboard',
  },
  defaultCFG: {
    id: PREFIX + 'default_CFG',
    defaultMessage: 'Default Config',
  },
  denyCFG: {
    id: PREFIX + 'deny_CFG',
    defaultMessage: 'Deny Config',
  },
  cfgCode: {
    id: PREFIX + 'cfg_code',
    defaultMessage: 'Code',
  },
  confirmDeleteDevice: {
    id: PREFIX + 'confirm_delete_device',
    defaultMessage: 'Would you want to delete this device?',
  },
  memberList: { id: PREFIX + 'member_list', defaultMessage: 'Member List' },
  confirmDeleteMember: {
    id: PREFIX + 'confirm_delete_member',
    defaultMessage: 'Would you want to delete this Member?',
  },
})
