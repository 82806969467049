import { JSON_CONTENT } from '@src/utils/ajaxUtils'
import authAjax from '@manager/utils/ajaxUtils'
import { objToQueryStr } from '@src/utils/function'
import { DeleteActivatedDevicesPayload } from '@src/manager/models/userDashboard'

const ACTIVATED_DEVICES_API_PATH = '/user/activateddevices/delete'

export const deleteActivatedDevicesAjax = (
  payload: DeleteActivatedDevicesPayload
) =>
  authAjax.delete(
    ACTIVATED_DEVICES_API_PATH + objToQueryStr(payload),
    JSON_CONTENT
  )
