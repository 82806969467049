import React from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import ListTableRow, { TBodyRow } from './ListTableRow'

export interface THead {
  id: string
  label: string | JSX.Element
  formatter?: (value: any) => any
  innerId?: string
}

interface Props {
  theadList: THead[]
  dataList: TBodyRow[]
  noMarginTop?: boolean
  rowIdKey?: string
  onRowClick?: (id: string) => void
}

export default function ListTable({
  theadList,
  dataList,
  noMarginTop = false,
  rowIdKey = 'id',
  onRowClick,
}: Props) {
  return (
    <TableContainer
      component={Paper}
      sx={{
        marginTop: noMarginTop ? '0' : 2,
      }}>
      <Table>
        <TableHead>
          <TableRow>
            {theadList.map(({ id, label }) => (
              <TableCell key={`${id}_${label}`}>{label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataList.map((item, idx) => (
            <ListTableRow
              headers={theadList}
              key={`${item.id}_${idx}`}
              data={item}
              onClick={onRowClick && (() => onRowClick(item[rowIdKey]))}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
