export enum UserDashboardKeys {
  Id = 'ID',
  Email = 'Email',
  Name = 'FullName',
  Active = 'Active',
  Description = 'Description',
  MaxDeviceCount = 'MaxDeviceCount',
  TokenLifeTime = 'TokenLifeTime',
  DefaultCFG = 'DefaultCFG',
  DenyCFG = 'DenyCFG',
  ActivatedDevices = 'ActivatedDevices',
  Member = 'Member',

  Code = 'Code',

  Date = 'Date',
  SN = 'SN',
  DeviceType = 'DeviceType',

  RegisteredDeviceCount = 'registeredDeviceCount',
}

export interface UserInfoDetail {
  [UserDashboardKeys.Id]: string
  [UserDashboardKeys.Name]: string
  [UserDashboardKeys.Active]: boolean
  [UserDashboardKeys.Description]: string
  [UserDashboardKeys.MaxDeviceCount]: number
  [UserDashboardKeys.TokenLifeTime]: number
  [UserDashboardKeys.RegisteredDeviceCount]?: number
}

export interface MemberDetail {
  [UserDashboardKeys.Name]: string
  [UserDashboardKeys.Email]: string
  [UserDashboardKeys.Active]: boolean
  [UserDashboardKeys.Description]: string
}

export interface ConfigDetail {
  [UserDashboardKeys.Code]: string
  [UserDashboardKeys.Active]: boolean
  [UserDashboardKeys.Description]: string
}

export interface ActivatedDeviceDetail {
  [UserDashboardKeys.Id]: string
  [UserDashboardKeys.Date]: string
  [UserDashboardKeys.SN]: string
  [UserDashboardKeys.DeviceType]?: string
}

export interface UserDashboardDetail extends UserInfoDetail {
  [UserDashboardKeys.DefaultCFG]: ConfigDetail[]
  [UserDashboardKeys.DenyCFG]: ConfigDetail[]
  [UserDashboardKeys.ActivatedDevices]: ActivatedDeviceDetail[]
  [UserDashboardKeys.Member]: MemberDetail[]
}

export interface UserInfoRequest {
  id: string
  data: Omit<UserInfoDetail, UserDashboardKeys.Id>
}

export interface DeleteActivatedDevicesPayload {
  id: string
  device: string
}

export interface MemberPayload {
  id: string
  email: string
}

export interface UpdateMemberPayload {
  id: string
  email: string
  data: MemberDetail
}

export interface CfgPayload {
  id: string
  data: Partial<ConfigDetail>
}

export interface DefaultCfgPayload {
  id: string
  defaultcfg: string
}

export interface DenyCfgPayload {
  id: string
  denycfg: string
}
