import {
  CfgPayload,
  ConfigDetail,
  DefaultCfgPayload,
  DenyCfgPayload,
} from '@src/manager/models/userDashboard'

export enum UserConfigActionType {
  GET_DEFAULT_CONFIG = '@userConfig/GET_DEFAULT_CONFIG',
  GET_DEFAULT_CONFIG_SUCCESS = '@userConfig/GET_DEFAULT_CONFIG_SUCCESS',
  GET_DEFAULT_CONFIG_ERROR = '@userConfig/GET_DEFAULT_CONFIG_ERROR',

  CREATE_DEFAULT_CONFIG = '@userConfig/CREATE_DEFAULT_CONFIG',
  CREATE_DEFAULT_CONFIG_SUCCESS = '@userConfig/CREATE_DEFAULT_CONFIG_SUCCESS',
  CREATE_DEFAULT_CONFIG_ERROR = '@userConfig/CREATE_DEFAULT_CONFIG_ERROR',

  UPDATE_DEFAULT_CONFIG = '@userConfig/UPDATE_DEFAULT_CONFIG',
  UPDATE_DEFAULT_CONFIG_SUCCESS = '@userConfig/UPDATE_DEFAULT_CONFIG_SUCCESS',
  UPDATE_DEFAULT_CONFIG_ERROR = '@userConfig/UPDATE_DEFAULT_CONFIG_ERROR',

  DELETE_DEFAULT_CONFIG = '@userConfig/DELETE_DEFAULT_CONFIG',
  DELETE_DEFAULT_CONFIG_SUCCESS = '@userConfig/DELETE_DEFAULT_CONFIG_SUCCESS',
  DELETE_DEFAULT_CONFIG_ERROR = '@userConfig/DELETE_DEFAULT_CONFIG_ERROR',

  RESET_DEFAULT_CONFIG = '@userConfig/RESET_DEFAULT_CONFIG',

  GET_DENY_CONFIG = '@userConfig/GET_DENY_CONFIG',
  GET_DENY_CONFIG_SUCCESS = '@userConfig/GET_DENY_CONFIG_SUCCESS',
  GET_DENY_CONFIG_ERROR = '@userConfig/GET_DENY_CONFIG_ERROR',

  CREATE_DENY_CONFIG = '@userConfig/CREATE_DENY_CONFIG',
  CREATE_DENY_CONFIG_SUCCESS = '@userConfig/CREATE_DENY_CONFIG_SUCCESS',
  CREATE_DENY_CONFIG_ERROR = '@userConfig/CREATE_DENY_CONFIG_ERROR',

  UPDATE_DENY_CONFIG = '@userConfig/UPDATE_DENY_CONFIG',
  UPDATE_DENY_CONFIG_SUCCESS = '@userConfig/UPDATE_DENY_CONFIG_SUCCESS',
  UPDATE_DENY_CONFIG_ERROR = '@userConfig/UPDATE_DENY_CONFIG_ERROR',

  DELETE_DENY_CONFIG = '@userConfig/DELETE_DENY_CONFIG',
  DELETE_DENY_CONFIG_SUCCESS = '@userConfig/DELETE_DENY_CONFIG_SUCCESS',
  DELETE_DENY_CONFIG_ERROR = '@userConfig/DELETE_DENY_CONFIG_ERROR',

  RESET_DENY_CONFIG = '@userConfig/RESET_DENY_CONFIG',
}

export const getDefaultConfigAction = (payload: DefaultCfgPayload) => ({
  type: UserConfigActionType.GET_DEFAULT_CONFIG,
  payload,
})

export const getDefaultConfigSuccessAction = (payload: ConfigDetail) => ({
  type: UserConfigActionType.GET_DEFAULT_CONFIG_SUCCESS,
  payload,
})

export const createDefaultConfigAction = (payload: CfgPayload) => ({
  type: UserConfigActionType.CREATE_DEFAULT_CONFIG,
  payload,
})

export const createDefaultConfigSuccessAction = () => ({
  type: UserConfigActionType.CREATE_DEFAULT_CONFIG_SUCCESS,
})

export const createDefaultConfigFailedAction = () => ({
  type: UserConfigActionType.CREATE_DEFAULT_CONFIG_ERROR,
})

export const updateDefaultConfigAction = (payload: CfgPayload) => ({
  type: UserConfigActionType.UPDATE_DEFAULT_CONFIG,
  payload,
})

export const updateDefaultConfigSuccessAction = () => ({
  type: UserConfigActionType.UPDATE_DEFAULT_CONFIG_SUCCESS,
})

export const updateDefaultConfigFailedAction = () => ({
  type: UserConfigActionType.UPDATE_DEFAULT_CONFIG_ERROR,
})

export const deleteDefaultConfigAction = (payload: DefaultCfgPayload) => ({
  type: UserConfigActionType.DELETE_DEFAULT_CONFIG,
  payload,
})

export const deleteDefaultConfigSuccessAction = () => ({
  type: UserConfigActionType.DELETE_DEFAULT_CONFIG_SUCCESS,
})

export const deleteDefaultConfigErrorAction = () => ({
  type: UserConfigActionType.DELETE_DEFAULT_CONFIG_ERROR,
})

export const resetDefaultConfigErrorAction = () => ({
  type: UserConfigActionType.RESET_DEFAULT_CONFIG,
})

export const getDenyConfigAction = (payload: DenyCfgPayload) => ({
  type: UserConfigActionType.GET_DENY_CONFIG,
  payload,
})

export const getDenyConfigSuccessAction = (payload: ConfigDetail) => ({
  type: UserConfigActionType.GET_DENY_CONFIG_SUCCESS,
  payload,
})

export const createDenyConfigAction = (payload: CfgPayload) => ({
  type: UserConfigActionType.CREATE_DENY_CONFIG,
  payload,
})

export const createDenyConfigSuccessAction = () => ({
  type: UserConfigActionType.CREATE_DENY_CONFIG_SUCCESS,
})

export const createDenyConfigFailedAction = () => ({
  type: UserConfigActionType.CREATE_DENY_CONFIG_ERROR,
})

export const updateDenyConfigAction = (payload: CfgPayload) => ({
  type: UserConfigActionType.UPDATE_DENY_CONFIG,
  payload,
})

export const updateDenyConfigSuccessAction = () => ({
  type: UserConfigActionType.UPDATE_DENY_CONFIG_SUCCESS,
})

export const updateDenyConfigFailedAction = () => ({
  type: UserConfigActionType.UPDATE_DENY_CONFIG_ERROR,
})

export const deleteDenyConfigAction = (payload: DenyCfgPayload) => ({
  type: UserConfigActionType.DELETE_DENY_CONFIG,
  payload,
})

export const deleteDenyConfigSuccessAction = () => ({
  type: UserConfigActionType.DELETE_DENY_CONFIG_SUCCESS,
})

export const deleteDenyConfigErrorAction = () => ({
  type: UserConfigActionType.DELETE_DENY_CONFIG_ERROR,
})

export const resetDenyConfigErrorAction = () => ({
  type: UserConfigActionType.RESET_DENY_CONFIG,
})
