import { PAGE_LIMIT } from '@common/constants'
import { ConfigKeys } from '@src/user/models/config'
import { _range } from '@src/utils/function'

export const mockConfigList = {
  count: 10,
  items: _range(0, PAGE_LIMIT).map((num) => ({
    [ConfigKeys.Id]: `config_${num}`,
    [ConfigKeys.UserId]: 'hx_no_1',
    [ConfigKeys.ConfigName]: `Config Name ${num}`,
    [ConfigKeys.CreateTime]: '2022-04-08T10:29:59.138Z',
    [ConfigKeys.Description]: 'Description',
    [ConfigKeys.DownloadLink]: 'DownloadLink',
  })),
}
