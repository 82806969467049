import React from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Button } from '@mui/material'
import ContentHeader from '@components/ContentHeader'
import DataTable from '@components/DataTable'
import { ControlledTextInput } from '@components/hookForm'
import { AdminDetail, AdminKeys } from '@admin/models/admin'
import { useGlobalSnackbar, useLoading } from '@src/hooks'
import {
  CHARACTER_LIMIT,
  emailPattern,
  INVALID_FORMAT,
  REQUIRED,
} from '@src/utils/validation'
import commonMessages from '@common/messages'
import messages from '@admin/utils/messages'
import { ReactComponent as IconAdd } from '@src/assets/icons/iconAdd.svg'
import { DETAIL_BREADCRUMBS } from './utils'
import {
  AdminActionType,
  createAdminAction,
} from '@src/admin/reducers/admin/adminActions'
import { adminPath, toAdminRoute } from '@src/common/appConfig'
import { ErrorResponse } from '@src/utils/type'
import Snackbar, { SnackbarState } from '@src/components/Snackbar'

export default function AdminCreate() {
  const { formatMessage: f } = useIntl()
  const dispatch = useDispatch()
  const { control, handleSubmit } = useForm<AdminDetail>({})
  const navigate = useNavigate()
  const { status, setSnackbar, clearSnackbar } = useGlobalSnackbar({
    page: 'admin',
  })
  const { openSpinner } = useLoading({
    endActions: {
      success: [AdminActionType.CREATE_ADMIN_SUCCESS],
      error: [AdminActionType.CREATE_ADMIN_ERROR],
    },
    cbk: {
      success: () => {
        navigate(toAdminRoute(adminPath.admin.list))
      },
      error: ({ error }: { error: ErrorResponse }) => {
        setSnackbar({
          state: SnackbarState.Error,
          message: error.message,
          notKeepData: true,
        })
        document.documentElement.scrollTop = 0
      },
    },
  })

  const onSubmit = (data: AdminDetail) => {
    openSpinner()
    dispatch(createAdminAction(data))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ContentHeader
        title={f(messages.adminCreate)}
        breadcrumbs={DETAIL_BREADCRUMBS.concat({
          title: messages.adminCreate,
        })}
        buttonList={[
          <Button variant="contained" type="submit" startIcon={<IconAdd />}>
            {f(commonMessages.create)}
          </Button>,
        ]}
      />
      {status && (
        <Snackbar
          severity={status.state}
          message={status.message!}
          onClose={clearSnackbar}
        />
      )}
      <DataTable
        title={f(commonMessages.basicInfo)}
        dataSets={[
          {
            label: f(commonMessages.name),
            content: (
              <ControlledTextInput
                name={AdminKeys.Name}
                control={control}
                required={REQUIRED}
                rules={{
                  maxLength: {
                    value: CHARACTER_LIMIT,
                    message: INVALID_FORMAT,
                  },
                }}
              />
            ),
          },
          {
            label: f(commonMessages.email),
            content: (
              <ControlledTextInput
                name={AdminKeys.Email}
                control={control}
                required={REQUIRED}
                rules={{
                  pattern: emailPattern,
                }}
              />
            ),
          },
          {
            label: f(commonMessages.status),
          },
        ]}
      />
    </form>
  )
}
