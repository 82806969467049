import React from 'react'
import { styled } from '@mui/material'
import { useIntl } from 'react-intl'
import messages from '../../common/messages'

const Button = styled('div')({
  display: 'inline-flex',
  alignItems: 'center',
  cursor: 'pointer',
  color: 'rgba(0, 0, 0, .54)',
  boxShadow: '0 2px 2px 0 rgba(0, 0, 0, .24), 0 0 1px 0 rgba(0, 0, 0, .24)',
  padding: 0,
  borderRadius: 2,
  border: '1px solid transparent',
  fontSize: 14,
  '> span': {
    paddingRight: 10,
    fontWeight: 500,
    paddingTop: 10,
    paddingBottom: 10,
  },
})
const Icon = styled('img')(({ theme }) => ({
  height: '2rem',
  margin: theme.spacing(1),
}))

interface Props {
  text?: React.FC
  icon: React.ImgHTMLAttributes<HTMLImageElement>
  onClick: () => void
}

export default function OAthButton({ text, icon, onClick }: Props) {
  const { formatMessage: f } = useIntl()

  return (
    <Button onClick={onClick}>
      <Icon {...icon} />
      <span>{text ?? f(messages.login)}</span>
    </Button>
  )
}
