import React from 'react'
import { TableCell, TableRow } from '@mui/material'
import { THead } from './ListTable'

export interface TBodyRow {
  [key: string]: any
  id?: string | number
}

interface Props {
  headers: THead[]
  data: TBodyRow
  onClick?: () => void
}

export default function ListTableRow({ headers, data, onClick }: Props) {
  const handleClick = (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>
  ) => {
    if (document.getSelection()?.type === 'Range') {
      event.stopPropagation()
    } else {
      onClick && onClick()
    }
  }

  return (
    <TableRow
      sx={{ cursor: onClick ? 'pointer' : 'auto' }}
      onClick={handleClick}>
      {headers.map(({ id, formatter, innerId }, idx) =>
        innerId ? (
          <TableCell key={`${data[id]}_${id}_${idx}`}>
            {formatter!(data[id])(data[innerId])}
          </TableCell>
        ) : (
          <TableCell key={`${data[id]}_${id}_${idx}`}>
            {formatter ? formatter(data[id]) : data[id]}
          </TableCell>
        )
      )}
    </TableRow>
  )
}
