import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { theme } from './common/styles'
import Vbc from './vbc'
import Admin from './admin'
import Manager from './manager'
import LangProvider from './components/LangProvider'
import User from './user'
import GlobalDialog from './components/GlobalDialog'
import GlobalSpinner from './components/GlobalSpinner'
import { userPath } from './common/appConfig'

export default function App() {
  if (process.env.REACT_APP_VERSION) {
    console.log('version:', process.env.REACT_APP_VERSION)
  }
  return (
    <BrowserRouter>
      <LangProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalDialog />
          <GlobalSpinner />
          <Routes>
            <Route path="/vbc/*" element={<Vbc />} />
            <Route path="/admin/*" element={<Admin />} />
            <Route path="/manager/*" element={<Manager />} />
            <Route path="/user/*" element={<User />} />
            <Route
              path="*"
              element={
                <Navigate to={`/user/${userPath.login.login}`} replace />
              }
            />
          </Routes>
        </ThemeProvider>
      </LangProvider>
    </BrowserRouter>
  )
}
