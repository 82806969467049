import { catchError, Observable, of } from 'rxjs'
import { ajax, AjaxError } from 'rxjs/ajax'
import { SuccessResponse } from '@src/utils/type'
import { ACCOUNT, AUTHORIZATION } from '@common/constants'
import messages from '@src/common/messages'
import { unauthorizedAction } from '../reducers/account/accountActions'
import { StorageKey } from './constants'
import { RootAction } from '../epics'

const { REACT_APP_USER_API_HOST = '' } = process.env
const API_SUB_PATH = '/dashboard'
let hostUrl = REACT_APP_USER_API_HOST + API_SUB_PATH
let commonHeaders: { [key: string]: any } = {}

const urlWithHost = (url: string) => hostUrl + url
const authAjax = {
  get: (url: string, headers?: Record<string, string>) =>
    ajax.get<SuccessResponse>(urlWithHost(url), {
      ...commonHeaders,
      ...headers,
    }),
  post: (url: string, body?: any, headers?: Record<string, string>) =>
    ajax.post(urlWithHost(url), body, { ...commonHeaders, ...headers }),
  put: (url: string, body?: any, headers?: Record<string, string>) =>
    ajax.put(urlWithHost(url), body, { ...commonHeaders, ...headers }),
  delete: (url: string, headers?: Record<string, string>) =>
    ajax.delete(urlWithHost(url), { ...commonHeaders, ...headers }),
  patch: (url: string, body?: any, headers?: Record<string, string>) =>
    ajax.patch(urlWithHost(url), body, { ...commonHeaders, ...headers }),
}

export const setAuthHeader = (token: string) =>
  (commonHeaders[AUTHORIZATION] = token)

export const removeAuthHeader = () => {
  delete commonHeaders[AUTHORIZATION]
}

export const setAccountHeader = (account: string) =>
  (commonHeaders[ACCOUNT] = account)

export const removeAccountHeader = () => {
  delete commonHeaders[ACCOUNT]
}

const defaultToken = localStorage.getItem(StorageKey.Token)
defaultToken && setAuthHeader(defaultToken)

const defaultAccount = localStorage.getItem(StorageKey.Account)
defaultAccount && setAccountHeader(defaultAccount)

export const catchErrorHandler = (
  errorCbk: (error: AjaxError) => Observable<any>,
  unAuthAction?: {
    type: RootAction
    payload?: any
  }[]
) =>
  catchError((error) => {
    if (error.status === 401) {
      return of(
        unauthorizedAction(messages.unauthorized),
        ...(unAuthAction ?? [])
      )
    }
    if (error.status === 403) {
      return of(unauthorizedAction(messages.expired), ...(unAuthAction ?? []))
    }
    return errorCbk(error)
  })

export default authAjax
