import React from 'react'
import { UseControllerProps } from 'react-hook-form'
import { MenuItem, Select } from '@mui/material'
import FieldController from './FieldController'

export interface SelectOption {
  label: string | React.FC
  value: any
}

interface Props<T> extends UseControllerProps<T> {
  options: SelectOption[]
  required?: boolean | string
  disabled?: boolean
  withNullOptions?: boolean
  fullWidth?: boolean
}

export default function ControlledSelect<T>({
  options,
  fullWidth = true,
  required = false,
  disabled = false,
  rules,
  withNullOptions,
  ...rest
}: Props<T>) {
  return (
    <FieldController
      rules={{ required: disabled ? undefined : required, ...rules }}
      {...rest}>
      <Select disabled={disabled} displayEmpty>
        {withNullOptions && (
          <MenuItem
            value=""
            sx={{
              height: '40px',
            }}
          />
        )}
        {options.map(({ value, label }, idx) => (
          <MenuItem key={`${value}_${idx}`} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FieldController>
  )
}
