import { createTheme } from '@mui/material'

export const textPrimary = '#2C3A50'
export const disableGrey = '#4B596C'
const textGrey = '#364356'
const mainBlue = '#097CDA'
export const backgroundBlack = '#131B26'
export const successGreen = '#49EF6E'
export const noticeYellow = '#F8E64A'
export const errorHighlightRed = '#F75B5B'
export const errorRed = '#F53232'
export const errorPressed = '#C42828'

declare module '@mui/material' {
  export interface Theme {
    custom: {
      [key: string]: any
    }
  }
  interface ThemeOptions {
    custom: {
      [key: string]: any
    }
  }
}

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  palette: {
    primary: {
      light: '#3A96E1',
      main: mainBlue,
      dark: '#0763AE',
    },
    secondary: {
      main: '#343a40',
    },
    text: {
      primary: textPrimary,
      disabled: disableGrey,
    },
    grey: {
      50: '#F1F7FE',
      100: '#E3EFFC',
      200: '#D5E2F2',
      300: '#B6C3D6',
      400: '#6E7C8E',
      500: '#596677',
      600: disableGrey,
      700: textGrey,
      800: '#2A3649',
      900: '#212A39',
    },
    success: { main: successGreen },
    error: {
      main: errorRed,
      light: errorHighlightRed,
      dark: errorPressed,
    },
    warning: {
      main: noticeYellow,
    },
  },
  typography: {
    h1: {
      fontWeight: 700,
      fontSize: 50,
      letterSpacing: 0.2,
    },
    h2: {
      fontWeight: 700,
      fontSize: 38,
      letterSpacing: 0.15,
    },
    h3: {
      fontWeight: 700,
      fontSize: 30,
      letterSpacing: 0.12,
    },
    h4: {
      fontWeight: 700,
      fontSize: 24,
      letterSpacing: 0.1,
    },
    h5: {
      fontWeight: 500,
      fontSize: 18,
      letterSpacing: 0.28,
    },
    h6: {
      fontWeight: 500,
      fontSize: 16,
      letterSpacing: 0.22,
    },
    subtitle1: {
      fontSize: 18,
      letterSpacing: 0.28,
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: 0.22,
    },
    body1: {
      fontSize: 16,
      letterSpacing: 0.25,
    },
    body2: {
      fontSize: 14,
      letterSpacing: 0.22,
    },
    button: {
      fontWeight: 700,
    },
    caption: {
      fontSize: 12,
      letterSpacing: 0.3,
    },
  },
  custom: {
    background: {
      black: backgroundBlack,
      sidebar: '#343a40',
    },
    height: {
      header: '70px',
    },
    width: {
      sidebar: '240px',
    },
    logo: {
      loginHeight: '1.4875rem',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        a: {
          textDecoration: 'none',
          color: textGrey,
          cursor: 'pointer',
          '&:visited': {
            color: mainBlue,
          },
          '&:hover': {
            color: mainBlue,
            textDecoration: 'underline',
          },
        },
        input: {
          '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
          },
          '&::-webkit-inner-spin-button': {
            MozAppearance: 'textfield',
          },
        },
        '.hidden': {
          display: 'none !important',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: '90px',
          padding: '0px 1rem',
          borderRadius: '8px',
          borderWidth: '2px',
          '&:hover': {
            borderWidth: '2px',
          },
        },
        startIcon: {
          width: '18px',
        },
      },
      variants: [
        {
          props: { variant: 'contained', color: 'success' },
          style: {
            color: '#fff',
          },
        },
        {
          props: { variant: 'contained', disabled: true },
          style: {
            color: '#000',
          },
        },
      ],
    },
    MuiInputBase: {
      styleOverrides: {
        root: { height: 40 },
        fullWidth: {
          width: 410,
        },
        multiline: {
          width: 410,
          minHeight: 120,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& > input': { padding: '1rem 0px' },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          minWidth: 180,
          verticalAlign: 'middle',
        },
      },
    },
  },
})
