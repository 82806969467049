import React from 'react'
import MicrosoftImg from '@assets/icons/iconMicrosoft.svg'
import OAthButton from './OAthButton'

export default function MicrosoftLogin({ msalOAth }: { msalOAth: string }) {
  const onClick = () => {
    window.location.assign(msalOAth)
  }

  return (
    <OAthButton
      onClick={onClick}
      icon={{ src: MicrosoftImg, alt: 'microsoft' }}
    />
  )
}
