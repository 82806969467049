import { defineMessages } from 'react-intl'

const PREFIX = 'manager.'

export default defineMessages({
  agency: {
    id: PREFIX + 'agency_list',
    defaultMessage: 'Agency',
  },
  agencyCreate: {
    id: PREFIX + 'agency_create',
    defaultMessage: 'Agency Create',
  },
  createMemberInfo: {
    id: PREFIX + 'agency.create_member_info',
    defaultMessage: 'Create member info',
  },
  userDashboard: {
    id: PREFIX + 'user_dashboard_detail',
    defaultMessage: 'User Dashboard',
  },
  userDashboardEdit: {
    id: PREFIX + 'user_dashboard_edit',
    defaultMessage: 'User Dashboard Edit',
  },
  contentManagement: {
    id: PREFIX + 'content_management',
    defaultMessage: 'Contents',
  },
  userInfoEdit: {
    id: PREFIX + 'user_dashboard.user_info_edit',
    defaultMessage: 'User Info Edit',
  },
  defaultCFGCreate: {
    id: PREFIX + 'user_config.default_CFG_create',
    defaultMessage: 'Default Config Create',
  },
  defaultCFGEdit: {
    id: PREFIX + 'user_config.default_CFG_edit',
    defaultMessage: 'Default Config Edit',
  },
  denyCFGCreate: {
    id: PREFIX + 'user_config.deny_CFG_create',
    defaultMessage: 'Deny Config Create',
  },
  denyCFGEdit: {
    id: PREFIX + 'user_config.deny_CFG_edit',
    defaultMessage: 'Deny Config Edit',
  },
  memberCreate: {
    id: PREFIX + 'member.member_create',
    defaultMessage: 'Member Create',
  },
  memberEdit: {
    id: PREFIX + 'member.member_edit',
    defaultMessage: 'Member Edit',
  },
})
