import { MessageDescriptor } from 'react-intl'
import { setAccountHeader, setAuthHeader } from '@user/utils/ajaxUtils'
import { AccountType, Profile } from '@src/user/models/account'
import { StorageKey } from '@user/utils/constants'
import { ActionType } from '@src/utils/type'
import GlobalSpinner from '@src/components/GlobalSpinner'
import { AuthDialog } from '@src/user/containers/Layout'
import { AccountActionType } from './accountActions'
import { HTC_USER_LOGOUT } from '@src/common/constants'

export interface AccountState {
  token?: string
  account?: string
  profile?: Profile
}

const initState: AccountState = {}

const cacheToken = localStorage.getItem(StorageKey.Token) ?? undefined
const cacheAccountn = localStorage.getItem(StorageKey.Account) ?? undefined
const cacheProfile = localStorage.getItem(StorageKey.Profile) ?? undefined

export const AccountPreloadState: AccountState = {
  ...initState,
  token: cacheToken,
  account: cacheAccountn,
  profile: cacheProfile && JSON.parse(cacheProfile),
}

const handler: Record<
  string,
  (state: AccountState, action: ActionType<any>) => AccountState
> = {
  [AccountActionType.BEFORE_LOGIN]: (
    state: AccountState = initState,
    action: ActionType<string>
  ) => {
    const account = action.payload
    localStorage.setItem(StorageKey.Account, account)
    setAccountHeader(account)

    return {
      ...state,
      account,
    }
  },
  [AccountActionType.LOGIN]: (
    state: AccountState = initState,
    action: ActionType<string>
  ) => {
    const token = action.payload
    localStorage.setItem(StorageKey.Token, token)
    setAuthHeader(token)

    return {
      ...state,
      token,
    }
  },
  [AccountActionType.LOGOUT_SUCCESS]: (state: AccountState = initState) => {
    localStorage.removeItem(StorageKey.Token)
    localStorage.removeItem(StorageKey.Account)
    localStorage.removeItem(StorageKey.Profile)
    GlobalSpinner.close()

    if (state.account === AccountType.Viveport) {
      window.location.assign(HTC_USER_LOGOUT)
    }

    return initState
  },
  [AccountActionType.LOGOUT_ERROR]: () => {
    localStorage.removeItem(StorageKey.Token)
    localStorage.removeItem(StorageKey.Account)
    localStorage.removeItem(StorageKey.Profile)
    GlobalSpinner.close()

    return initState
  },
  [AccountActionType.GET_PROFILE_SUCCESS]: (
    state: AccountState = initState,
    action: ActionType<Profile>
  ) => {
    const profile = action.payload
    localStorage.setItem(StorageKey.Profile, JSON.stringify(profile))

    return {
      ...state,
      profile,
    }
  },
  [AccountActionType.UNAUTHORIZED]: (
    state: AccountState = initState,
    action: ActionType<MessageDescriptor>
  ) => {
    AuthDialog.open({
      content: action.payload,
    })

    return state
  },
}

export default function accountReducer(
  state: AccountState = initState,
  action: ActionType<any>
) {
  if (handler.hasOwnProperty(action.type)) {
    return handler[action.type](state, action)
  }
  return state
}
