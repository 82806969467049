import { ConfigDetail } from '@src/manager/models/userDashboard'
import { ActionType } from '@src/utils/type'
import { UserConfigActionType } from './userConfigActions'

export interface UserConfigState {
  currentDefaultConfig?: ConfigDetail
  currentDenyConfig?: ConfigDetail
}

const initState: UserConfigState = {}

export const UserConfigPreloadState = initState

const updateDefaultConfigHandler = (
  state: UserConfigState = initState,
  action: ActionType<ConfigDetail | undefined>
): UserConfigState => {
  return {
    ...state,
    currentDefaultConfig: action.payload,
  }
}
const updateDenyConfigHandler = (
  state: UserConfigState = initState,
  action: ActionType<ConfigDetail | undefined>
): UserConfigState => {
  return {
    ...state,
    currentDenyConfig: action.payload,
  }
}

const handler: Record<
  string,
  (state: UserConfigState, action: ActionType<any>) => UserConfigState
> = {
  [UserConfigActionType.GET_DEFAULT_CONFIG_SUCCESS]: updateDefaultConfigHandler,
  [UserConfigActionType.RESET_DEFAULT_CONFIG]: updateDefaultConfigHandler,
  [UserConfigActionType.GET_DENY_CONFIG_SUCCESS]: updateDenyConfigHandler,
  [UserConfigActionType.RESET_DENY_CONFIG]: updateDenyConfigHandler,
}

export default function userConfigReducer(
  state: UserConfigState = initState,
  action: ActionType<any>
) {
  if (handler.hasOwnProperty(action.type)) {
    return handler[action.type](state, action)
  }
  return state
}
