import { defineMessages } from 'react-intl'

const PREFIX = 'user.'

export default defineMessages({
  agency: {
    id: PREFIX + 'agency',
    defaultMessage: 'Agency',
  },
  dashboard: {
    id: PREFIX + 'dashboard',
    defaultMessage: 'Dashboard',
  },
  deviceList: {
    id: PREFIX + 'device_list',
    defaultMessage: 'Devices',
  },
  group: {
    id: PREFIX + 'group',
    defaultMessage: 'Group',
  },
  config: {
    id: PREFIX + 'config',
    defaultMessage: 'Configuration',
  },
  map: {
    id: PREFIX + 'map',
    defaultMessage: 'Map',
  },
})
