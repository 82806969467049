export enum MapKeys {
  Id = 'ID',
  UserId = 'UserID',
  MapName = 'MapName',
  CreateTime = 'CreateTime',
  FromDeviceName = 'FromDeviceName',
  FromDeviceSN = 'FromDeviceSN',
  Description = 'Description',
  DownloadLink = 'DownLink',
}

export interface Map {
  [MapKeys.Id]: string
  [MapKeys.UserId]: string
  [MapKeys.MapName]: string
  [MapKeys.CreateTime]: string
  [MapKeys.FromDeviceName]: string
  [MapKeys.FromDeviceSN]: string
  [MapKeys.Description]: string
  [MapKeys.DownloadLink]: string
}
