import React, { useEffect } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { StoreState } from '@src/manager/reducers'
import {
  deleteAgencyAction,
  getListAction,
  AgencyActionType,
} from '@manager/reducers/agency/agencyActions'
import { Box, Button, IconButton } from '@mui/material'
import { useGlobalSnackbar, useLoading, usePagination } from '@src/hooks'
import { managerPath, RouteSection, toManagerRoute } from '@common/appConfig'
import ContentHeader from '@components/ContentHeader'
import Pagination from '@components/Pagination'
import ListTable from '@components/ListTable'
import { StatusCapsule } from '@components/Capsule'
import commonMessages from '@common/messages'
import messages from '@manager/utils/messages'
import { ReactComponent as IconAdd } from '@src/assets/icons/iconAdd.svg'
import { ReactComponent as IconDelete } from '@src/assets/icons/iconDelete.svg'
import { AgencyKeys } from '@src/manager/models/agency'
import GlobalDialog from '@src/components/GlobalDialog'
import { millisecondToMinute } from '@src/utils/function'
import { ErrorResponse } from '@src/utils/type'
import Snackbar, { SnackbarState } from '@src/components/Snackbar'

export default function AgencyList() {
  const { formatMessage: f } = useIntl()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { agencyList, agencyTotal } = useSelector(
    (state: StoreState) => state.agency
  )
  const { status, setSnackbar, clearSnackbar } = useGlobalSnackbar({
    page: RouteSection.Agency,
  })
  const {
    query,
    pagination: { count, page },
    handlePageChange,
  } = usePagination({
    count: agencyTotal,
  })
  const { openSpinner } = useLoading({
    endActions: {
      success: [
        AgencyActionType.GET_LIST_SUCCESS,
        AgencyActionType.DELETE_AGENCY_SUCCESS,
      ],
      error: [
        AgencyActionType.GET_LIST_ERROR,
        AgencyActionType.DELETE_AGENCY_ERROR,
      ],
    },
    cbk: {
      success: ({ type }: { type: AgencyActionType }) => {
        if (type === AgencyActionType.DELETE_AGENCY_SUCCESS) {
          setSnackbar({
            state: SnackbarState.Success,
            message: f(commonMessages.deleteSuccess),
            notKeepData: true,
          })
          document.documentElement.scrollTop = 0
          openSpinner()
          dispatch(getListAction({ ...query }))
        }
      },
      error: ({
        type,
        error,
      }: {
        type: AgencyActionType
        error: ErrorResponse
      }) => {
        const msg =
          type === AgencyActionType.DELETE_AGENCY_ERROR
            ? f(commonMessages.deleteFailed)
            : error.message

        setSnackbar({
          state: SnackbarState.Error,
          message: msg,
          notKeepData: true,
        })
        document.documentElement.scrollTop = 0
      },
    },
  })

  const handleRowClick = (id: string) =>
    navigate(
      generatePath(toManagerRoute(managerPath.userDashboard.dashboard), { id })
    )

  const handleDelete = (id: string) => {
    openSpinner()
    dispatch(deleteAgencyAction(id))
  }

  const beforeDelete =
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
    (id: string) => {
      event.stopPropagation()
      GlobalDialog.open({
        content: f(commonMessages.confirmDeleteUser),
        onAgree: () => handleDelete(id),
      })
    }

  const theadList = [
    {
      id: AgencyKeys.Name,
      label: f(commonMessages.name),
    },
    {
      id: AgencyKeys.Active,
      label: f(commonMessages.status),
      formatter: (isActivated: boolean) => (
        <StatusCapsule isActivated={isActivated} />
      ),
    },
    {
      id: AgencyKeys.MaxDeviceCount,
      label: f(commonMessages.maxDeviceCount),
      formatter: (num: number) => f(commonMessages.itemAmount, { num }),
    },
    {
      id: AgencyKeys.TokenLifeTime,
      label: f(commonMessages.tokenLifeTime),
      formatter: (time: number) =>
        f(commonMessages.timeMinutes, { num: millisecondToMinute(time) }),
    },
    {
      id: AgencyKeys.Id,
      label: '',
      formatter: (id: string) => (
        <Box display="flex" flexDirection="row-reverse">
          <IconButton
            sx={({ palette }) => ({
              bgcolor: palette.error.main,
              '&>svg': { width: 20, height: 20 },
            })}
            onClick={(e) => beforeDelete(e)(id)}>
            <IconDelete />
          </IconButton>
        </Box>
      ),
    },
  ]

  useEffect(() => {
    openSpinner()
    dispatch(getListAction({ ...query }))
  }, [dispatch, query, openSpinner])

  return (
    <>
      {status && (
        <Snackbar
          severity={status.state}
          message={status.message!}
          onClose={clearSnackbar}
        />
      )}
      <ContentHeader
        title={f(messages.agency)}
        buttonList={[
          <Button
            variant="contained"
            startIcon={<IconAdd />}
            onClick={() =>
              navigate(toManagerRoute(managerPath.agencyList.create))
            }>
            {f(commonMessages.create)}
          </Button>,
        ]}
      />
      <ListTable
        theadList={theadList}
        dataList={agencyList}
        rowIdKey={AgencyKeys.Id}
        onRowClick={handleRowClick}
      />
      <Pagination count={count} page={page} onChange={handlePageChange} />
    </>
  )
}
