import { PAGE_LIMIT } from '@common/constants'
import { RomKeys } from '@src/user/models/rom'
import { _range } from '@src/utils/function'

export const mockRomList = {
  count: 10,
  items: _range(0, PAGE_LIMIT).map((num) => ({
    [RomKeys.Id]: `rom_${num}`,
    [RomKeys.VersionName]: `Version Name ${num}`,
    [RomKeys.Description]: 'Description',
  })),
}
