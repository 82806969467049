import { PAGE_LIMIT } from '@common/constants'
import { DeviceKeys } from '@user/models/device'
import { _range } from '@src/utils/function'

export const mockDeviceList = {
  count: 1000,
  items: _range(0, PAGE_LIMIT).map((num) => ({
    [DeviceKeys.UserId]: num.toString(),
    [DeviceKeys.GroupId]: 'hx_no_1',
    [DeviceKeys.SN]: `1a2b3c4d${num}`,
    [DeviceKeys.DeviceType]: 'focus 3',
    [DeviceKeys.EnrollTime]: '2022-04-08T10:29:59.138Z',
    [DeviceKeys.ConnectReady]: num % 3 === 0,
    [DeviceKeys.Status]: 'from device',
    [DeviceKeys.BatteryHMD]: (num % 3) * 10,
    [DeviceKeys.BatteryCL]: (num % 7) * 10,
    [DeviceKeys.BatteryCR]: (num % 5) * 10,
    [DeviceKeys.LastTask]: 'Last Task',
    [DeviceKeys.LastTaskTime]: '2022-04-08T10:29:59.138Z',
  })),
}
