import React from 'react'
import { PropsWithChildren } from 'react'
import dayjs from 'dayjs'
import { IntlProvider } from 'react-intl'
import zhTW from '../../translations/zh-tw.json'
import en from '../../translations/en.json'

enum Language {
  En = 'en',
  Tw = 'zh-tw',
}

const DEFAULT_LANG = Language.En
const supportLang = Object.values(Language) as string[]

const LanguageFormatter: Record<Language, Record<string, string>> = {
  [Language.En]: en,
  [Language.Tw]: zhTW,
}

const navLang = navigator.language.toLocaleLowerCase()
export const LANG = supportLang.includes(navLang) ? navLang : DEFAULT_LANG

require(`dayjs/locale/${LANG}.js`)
dayjs.locale(LANG)

const formatLang = (lang: string) => {
  if (supportLang.includes(lang)) return LanguageFormatter[lang as Language]

  return LanguageFormatter[DEFAULT_LANG]
}

export const LOCALE_MESSAGE = formatLang(LANG)
export default function LangProvider({ children }: PropsWithChildren<unknown>) {
  return (
    <IntlProvider key={LANG} locale={LANG} messages={LOCALE_MESSAGE}>
      {children}
    </IntlProvider>
  )
}
