import React from 'react'
import { useIntl } from 'react-intl'
import { Control } from 'react-hook-form'
import { ConfigDetail, UserDashboardKeys } from '@manager/models/userDashboard'
import {
  ControlledSelect,
  ControlledTextArea,
  ControlledTextInput,
} from '@src/components/hookForm'
import DataTable from '@src/components/DataTable'
import commonMessages from '@common/messages'
import {
  DESCRIPTIONd_LIMIT,
  INVALID_FORMAT,
  REQUIRED,
} from '@src/utils/validation'

export default function ConfigEditForm({
  control,
  config,
}: {
  control: Control<ConfigDetail>
  config?: ConfigDetail
}) {
  const { formatMessage: f } = useIntl()

  return (
    <DataTable
      title={f(commonMessages.basicInfo)}
      dataSets={[
        {
          label: f(commonMessages.cfgCode),
          content: config ? (
            config[UserDashboardKeys.Code]
          ) : (
            <ControlledTextInput
              name={UserDashboardKeys.Code}
              control={control}
              required={REQUIRED}
            />
          ),
        },
        {
          label: f(commonMessages.status),
          content: (
            <ControlledSelect
              name={UserDashboardKeys.Active}
              control={control}
              options={[
                { label: f(commonMessages.active), value: true },
                { label: f(commonMessages.inactive), value: false },
              ]}
            />
          ),
        },
        {
          label: f(commonMessages.description),
          content: (
            <ControlledTextArea
              name={UserDashboardKeys.Description}
              control={control}
              rules={{
                maxLength: {
                  value: DESCRIPTIONd_LIMIT,
                  message: INVALID_FORMAT,
                },
              }}
            />
          ),
        },
      ]}
    />
  )
}
