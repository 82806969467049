import { AgencyActionType } from '@src/manager/reducers/agency/agencyActions'
import { AnyAction } from 'redux'
import { combineEpics } from 'redux-observable'
import { AccountActionType } from '../reducers/account/accountActions'
import { DashboardActionType } from '../reducers/dashboard/dashboardActions'
import { MemberActionType } from '../reducers/member/memberActions'
import accountEpics from './account/accountEpics'
import dashboardEpics from './dashboard/dashboardEpics'
import memberEpics from './member/memberEpics'
import agencyEpics from './agency/agencyEpics'

export type RootAction =
  | AccountActionType
  | AgencyActionType
  | DashboardActionType
  | MemberActionType

export const rootEpic = combineEpics<
  AnyAction,
  {
    type: RootAction
    payload?: any
  },
  void,
  any
>(...accountEpics, ...agencyEpics, ...dashboardEpics, ...memberEpics)
