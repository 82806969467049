import { AdminDetail } from '@src/admin/models/admin'

export enum AdminActionType {
  GET_LIST = '@Admin/GET_LIST',
  GET_LIST_SUCCESS = '@Admin/GET_LIST_SUCCESS',
  GET_LIST_ERROR = '@Admin/GET_LIST_ERROR',

  START_POLLING_LIST = '@Admin/START_POLLING_LIST',
  STOP_POLLING_LIST = '@Admin/STOP_POLLING_LIST',

  CREATE_ADMIN = '@Admin/CREATE_ADMIN',
  CREATE_ADMIN_SUCCESS = '@Admin/CREATE_ADMIN_SUCCESS',
  CREATE_ADMIN_ERROR = '@Admin/CREATE_ADMIN_ERROR',

  GET_ADMIN = '@Admin/GET_ADMIN',
  GET_ADMIN_SUCCESS = '@Admin/GET_ADMIN_SUCCESS',
  GET_ADMIN_ERROR = '@Admin/GET_ADMIN_ERROR',

  START_POLLING_ADMIN = '@Admin/START_POLLING_ADMIN',
  STOP_POLLING_ADMIN = '@Admin/STOP_POLLING_ADMIN',

  UPDATE_ADMIN = '@Admin/UPDATE_ADMIN',
  UPDATE_ADMIN_SUCCESS = '@Admin/UPDATE_ADMIN_SUCCESS',
  UPDATE_ADMIN_ERROR = '@Admin/UPDATE_ADMIN_ERROR',

  DELETE_ADMIN = '@Admin/DELETE_ADMIN',
  DELETE_ADMIN_SUCCESS = '@Admin/DELETE_ADMIN_SUCCESS',
  DELETE_ADMIN_ERROR = '@Admin/DELETE_ADMIN_ERROR',

  RESET_ADMIN = '@Admin/RESET_ADMIN',
}

export interface ListParams {
  Count: number
  Items: AdminDetail[]
}

export const getListAction = (payload: Object) => ({
  type: AdminActionType.GET_LIST,
  payload,
})

export const getListSuccessAction = (payload: Object) => ({
  type: AdminActionType.GET_LIST_SUCCESS,
  payload,
})

export const startPollingListAction = (payload: Object) => ({
  type: AdminActionType.START_POLLING_LIST,
  payload,
})

export const stopPollingListAction = () => ({
  type: AdminActionType.STOP_POLLING_LIST,
})

export const createAdminAction = (payload: Partial<AdminDetail>) => ({
  type: AdminActionType.CREATE_ADMIN,
  payload,
})

export const createAdminSuccessAction = () => ({
  type: AdminActionType.CREATE_ADMIN_SUCCESS,
})

export const createAdminFailedAction = () => ({
  type: AdminActionType.CREATE_ADMIN_ERROR,
})

export const getAdminAction = (payload: string) => ({
  type: AdminActionType.GET_ADMIN,
  payload,
})

export const getAdminSuccessAction = (payload: Partial<AdminDetail>) => ({
  type: AdminActionType.GET_ADMIN_SUCCESS,
  payload,
})

export const startPollingAdminAction = (payload: string) => ({
  type: AdminActionType.START_POLLING_ADMIN,
  payload,
})

export const stopPollingAdminAction = () => ({
  type: AdminActionType.STOP_POLLING_ADMIN,
})

export const updateAdminAction = (payload: {
  id: string
  data: Partial<AdminDetail>
}) => ({
  type: AdminActionType.UPDATE_ADMIN,
  payload,
})

export const updateAdminSuccessAction = () => ({
  type: AdminActionType.UPDATE_ADMIN_SUCCESS,
})

export const updateAdminFailedAction = () => ({
  type: AdminActionType.UPDATE_ADMIN_ERROR,
})

export const deleteAdminAction = (payload: string) => ({
  type: AdminActionType.DELETE_ADMIN,
  payload,
})

export const deleteAdminSuccessAction = () => ({
  type: AdminActionType.DELETE_ADMIN_SUCCESS,
})

export const deleteAdminErrorAction = () => ({
  type: AdminActionType.DELETE_ADMIN_ERROR,
})

export const resetAdminErrorAction = () => ({
  type: AdminActionType.RESET_ADMIN,
})
