import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box, Menu, MenuItem, Typography } from '@mui/material'
import { logoutAction } from '@user/reducers/account/accountActions'
import { accountSelectors } from '@user/reducers/account/accountSelectors'
import { AccountKeys } from '@user/models/account'
import { ReactComponent as Account } from '@src/assets/icons/iconAccount.svg'
import messages from '@common/messages'

export default function ProfileMenu() {
  const dispatch = useDispatch()
  const { profile } = useSelector(accountSelectors)
  const { formatMessage: f } = useIntl()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = useCallback(
    (event: React.MouseEvent<any>) => setAnchorEl(event.currentTarget),
    [setAnchorEl]
  )
  const handleClose = useCallback(() => setAnchorEl(null), [setAnchorEl])
  const handleLogout = () => {
    dispatch(logoutAction())
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        sx={{ '& svg': { cursor: 'pointer' } }}>
        <Typography variant="subtitle1" sx={{ marginRight: 2 }}>
          {profile?.[AccountKeys.Name]}
        </Typography>
        <Account onClick={handleClick} />
      </Box>
      {anchorEl && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={{ marginY: 1 }}>
          {[profile?.[AccountKeys.Email], profile?.[AccountKeys.Name]].map(
            (item) => (
              <MenuItem key={item} sx={{ minWidth: 200 }}>
                {item}
              </MenuItem>
            )
          )}
          <MenuItem onClick={handleLogout} sx={{ minWidth: 200 }}>
            {f(messages.loginOut)}
          </MenuItem>
        </Menu>
      )}
    </>
  )
}
