import { createSelector } from 'reselect'
import { Dashboard, DashboardKeys } from '@src/user/models/dashboard'
import { millisecondToMinute } from '@src/utils/function'
import { StoreState } from '..'

export const dashboardSelectors = createSelector(
  [(state: StoreState) => state.dashboard.currentDashboard],
  (userDashboard?: Dashboard) => {
    const currentDashboard = userDashboard && {
      ...userDashboard,
      [DashboardKeys.RegisteredDeviceCount]:
        userDashboard[DashboardKeys.ActivatedDevices].length,
      [DashboardKeys.TokenLifeTime]: millisecondToMinute(
        userDashboard[DashboardKeys.TokenLifeTime]
      ),
    }

    return {
      currentDashboard,
    }
  }
)
