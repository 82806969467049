import { PAGE_LIMIT } from '@common/constants'
import { GroupKeys } from '@src/user/models/group'
import { _range } from '@src/utils/function'

export const mockGroupList = {
  count: 10,
  items: _range(0, PAGE_LIMIT).map((num) => ({
    [GroupKeys.Id]: num.toString(),
    [GroupKeys.UserId]: 'hx_no_1',
    [GroupKeys.GroupName]: `HX ${num}`,
    [GroupKeys.Description]: 'Description',
    [GroupKeys.ConfigId]: `config_${num}`,
    [GroupKeys.MapId]: `map_${num}`,
    [GroupKeys.RomVersion]: `rom_${num}`,

    [GroupKeys.ConfigName]: 'ConfigName',
    [GroupKeys.MapName]: 'MapName',
    [GroupKeys.VersionName]: 'VersionName',
  })),
}
