import { AnyAction } from 'redux'
import { ofType } from 'redux-observable'
import {
  delay,
  empty,
  exhaustMap,
  from,
  map,
  mergeMap,
  Observable,
  of,
  repeatWhen,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs'
import { errorSubject, successSubject } from '@src/utils/responseSubject'
import { catchErrorHandler } from '@manager/utils/ajaxUtils'
import { POLLING_TIME } from '@src/utils/ajaxUtils'
import * as userDashBoardServices from './userDashBoardServices'
import {
  getUserDashboardSuccessAction,
  stopPollingUserDashboardAction,
  updateUserInfoSuccessAction,
  UserDashboardActionType,
} from '@src/manager/reducers/userDashboard/userDashboardActions'

const getDashBoard = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(UserDashboardActionType.GET_USER_DASHBOARD),
    switchMap(({ payload }) =>
      userDashBoardServices.getDashboardAjax(payload).pipe(
        map((res) => getUserDashboardSuccessAction(res.response.Message)),
        tap(() =>
          successSubject.next({
            type: UserDashboardActionType.GET_USER_DASHBOARD_SUCCESS,
          })
        ),
        catchErrorHandler((error) => {
          errorSubject.next({
            type: UserDashboardActionType.GET_USER_DASHBOARD_ERROR,
            error: error.response?.Message,
          })
          return empty()
        })
      )
    )
  )

const pollDashBoard = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(UserDashboardActionType.START_POLLING_USER_DASHBOARD),
    exhaustMap((action) => {
      const payload = action.payload
      return of(action).pipe(
        mergeMap(() =>
          from(userDashBoardServices.getDashboardAjax(payload)).pipe(
            mergeMap((res) =>
              of(getUserDashboardSuccessAction(res.response.Message))
            ),
            repeatWhen((complete$) => complete$.pipe(delay(POLLING_TIME))),
            catchErrorHandler(
              () => of(stopPollingUserDashboardAction()),
              [stopPollingUserDashboardAction()]
            )
          )
        ),
        takeUntil(
          action$.pipe(
            ofType(UserDashboardActionType.STOP_POLLING_USER_DASHBOARD)
          )
        )
      )
    })
  )

const updateUserInfo = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(UserDashboardActionType.UPDATE_USER_INFO),
    exhaustMap(({ payload }) =>
      userDashBoardServices.updateUserInfoAjax(payload).pipe(
        map((res) => updateUserInfoSuccessAction()),
        tap(() =>
          successSubject.next({
            type: UserDashboardActionType.UPDATE_USER_INFO_SUCCESS,
          })
        ),
        catchErrorHandler((error) => {
          errorSubject.next({
            type: UserDashboardActionType.UPDATE_USER_INFO_ERROR,
            error: error.response?.data,
          })
          return empty()
        })
      )
    )
  )

const userDashboardEpics = [getDashBoard, pollDashBoard, updateUserInfo]

export default userDashboardEpics
