import React from 'react'
import { AppBar, Box, Typography } from '@mui/material'
import { ReactComponent as Logo } from '@src/assets/logo-vive-business.svg'

export default function Header({
  children,
  identity,
}: {
  children?: React.ReactNode
  identity: string
}) {
  return (
    <AppBar position="fixed" color="secondary">
      <Box
        sx={({ custom }) => ({ p: 2, height: custom.height.header })}
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        <Box width={220}>
          <Logo />
        </Box>
        <Typography component="div" variant="h4" color="white">
          {identity}
        </Typography>
        {children}
      </Box>
    </AppBar>
  )
}
