import React, { useEffect } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import { Button } from '@mui/material'
import ContentHeader from '@components/ContentHeader'
import DataTable from '@components/DataTable'
import {
  ControlledNumberInput,
  ControlledSelect,
  ControlledTextArea,
  ControlledTextInput,
} from '@components/hookForm'
import {
  CHARACTER_LIMIT,
  DESCRIPTIONd_LIMIT,
  INVALID_FORMAT,
  REQUIRED,
} from '@src/utils/validation'
import commonMessages from '@common/messages'
import messages from '@manager/utils/messages'
import { ReactComponent as IconSave } from '@src/assets/icons/iconSave.svg'
import {
  managerPath,
  RouteSection,
  toManagerRoute,
} from '@src/common/appConfig'
import { BREADCRUMBS, toRequestUserInfo } from './utils'
import {
  UserDashboardKeys,
  UserInfoRequest,
} from '@src/manager/models/userDashboard'
import { userDashboardSelectors } from '@src/manager/reducers/userDashboard/userDashboardSelectors'
import {
  getUserDashboardAction,
  updateUserInfoAction,
  UserDashboardActionType,
} from '@src/manager/reducers/userDashboard/userDashboardActions'
import Snackbar, { SnackbarState } from '@src/components/Snackbar'
import { useGlobalSnackbar, useLoading } from '@src/hooks'
import { ErrorResponse } from '@src/utils/type'

export default function UserInfoEdit() {
  const { formatMessage: f } = useIntl()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { currentUserInfo } = useSelector(userDashboardSelectors)
  const { id } = useParams()
  const { control, handleSubmit, setValue, getValues } =
    useForm<UserInfoRequest>({
      defaultValues: { id },
    })
  const { status, setSnackbar, clearSnackbar } = useGlobalSnackbar({
    page: RouteSection.UserDashboard,
  })
  const { openSpinner } = useLoading({
    endActions: {
      success: [
        UserDashboardActionType.GET_USER_DASHBOARD_SUCCESS,
        UserDashboardActionType.UPDATE_USER_INFO_SUCCESS,
      ],
      error: [
        UserDashboardActionType.GET_USER_DASHBOARD_ERROR,
        UserDashboardActionType.UPDATE_USER_INFO_ERROR,
      ],
    },
    cbk: {
      success: ({ type }: { type: UserDashboardActionType }) => {
        if (type === UserDashboardActionType.GET_USER_DASHBOARD_SUCCESS) {
          setValue('data', currentUserInfo!, {
            shouldValidate: true,
            shouldDirty: true,
          })
          console.log('getValues', getValues())
        }
        if (type === UserDashboardActionType.UPDATE_USER_INFO_SUCCESS) {
          setSnackbar({
            state: SnackbarState.Success,
            message: f(commonMessages.updateSuccess),
            notKeepData: false,
          })
          document.documentElement.scrollTop = 0
          navigate(
            generatePath(toManagerRoute(managerPath.userDashboard.dashboard), {
              id,
            })
          )
        }
      },
      error: ({ error }: { error: ErrorResponse }) => {
        setSnackbar({
          state: SnackbarState.Error,
          message: error.message,
          notKeepData: true,
        })
        document.documentElement.scrollTop = 0
      },
    },
  })

  const onSubmit = (values: UserInfoRequest) => {
    openSpinner()
    dispatch(updateUserInfoAction(toRequestUserInfo(values)))
  }

  useEffect(() => {
    openSpinner()
    dispatch(getUserDashboardAction(id!))
  }, [dispatch, id, openSpinner])

  useEffect(() => {
    if (currentUserInfo)
      setValue('data', currentUserInfo, {
        shouldValidate: true,
        shouldDirty: true,
      })
  }, [currentUserInfo, setValue])

  return (
    <>
      {status && (
        <Snackbar
          severity={status.state}
          message={status.message!}
          onClose={clearSnackbar}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <ContentHeader
          title={f(messages.userInfoEdit)}
          breadcrumbs={[
            ...BREADCRUMBS,
            {
              title: messages.userDashboard,
              route: generatePath(
                toManagerRoute(managerPath.userDashboard.dashboard),
                { id }
              ),
            },
            {
              title: messages.userInfoEdit,
            },
          ]}
          buttonList={[
            <Button variant="contained" type="submit" startIcon={<IconSave />}>
              {f(commonMessages.save)}
            </Button>,
          ]}
        />
        {currentUserInfo && (
          <DataTable
            dataSets={[
              {
                label: f(commonMessages.name),
                content: (
                  <ControlledTextInput
                    name={`data.${UserDashboardKeys.Name}`}
                    control={control}
                    required={REQUIRED}
                    rules={{
                      maxLength: {
                        value: CHARACTER_LIMIT,
                        message: INVALID_FORMAT,
                      },
                    }}
                  />
                ),
              },
              {
                label: f(commonMessages.status),
                content: (
                  <ControlledSelect
                    name={`data.${UserDashboardKeys.Active}`}
                    control={control}
                    options={[
                      { label: f(commonMessages.active), value: true },
                      { label: f(commonMessages.inactive), value: false },
                    ]}
                  />
                ),
              },
              {
                label: f(commonMessages.tokenLifeTime),
                content: (
                  <ControlledNumberInput
                    name={`data.${UserDashboardKeys.TokenLifeTime}`}
                    control={control}
                    required={REQUIRED}
                    unit={f(commonMessages.minutes)}
                    rules={{
                      min: {
                        value: 0,
                        message: INVALID_FORMAT,
                      },
                    }}
                  />
                ),
              },
              {
                label: f(commonMessages.maxDeviceCount),
                content: (
                  <ControlledNumberInput
                    name={`data.${UserDashboardKeys.MaxDeviceCount}`}
                    control={control}
                    required={REQUIRED}
                    rules={{
                      min: {
                        value: 0,
                        message: INVALID_FORMAT,
                      },
                    }}
                  />
                ),
              },
              {
                label: f(commonMessages.registeredDeviceCount),
                content: f(commonMessages.itemAmount, {
                  num: currentUserInfo[UserDashboardKeys.RegisteredDeviceCount],
                }),
              },
              {
                label: f(commonMessages.description),
                content: (
                  <ControlledTextArea
                    name={`data.${UserDashboardKeys.Description}`}
                    control={control}
                    rules={{
                      maxLength: {
                        value: DESCRIPTIONd_LIMIT,
                        message: INVALID_FORMAT,
                      },
                    }}
                  />
                ),
              },
            ]}
          />
        )}
      </form>
    </>
  )
}
