import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Button } from '@mui/material'
import { useGlobalSnackbar, useLoading, usePagination } from '@src/hooks'
import { adminPath, toAdminRoute } from '@common/appConfig'
import ContentHeader from '@components/ContentHeader'
import Pagination from '@components/Pagination'
import ListTable from '@components/ListTable'
import { StatusCapsule } from '@components/Capsule'
import commonMessages from '@common/messages'
import messages from '@admin/utils/messages'
import { ReactComponent as IconAdd } from '@src/assets/icons/iconAdd.svg'
import { BREADCRUMBS } from './utils'
import { AdminKeys } from '@admin/models/admin'
import { StoreState } from '@admin/reducers'
import {
  AdminActionType,
  getListAction,
  stopPollingListAction,
} from '@admin/reducers/admin/adminActions'
import Snackbar, { SnackbarState } from '@src/components/Snackbar'
import { ErrorResponse } from '@src/utils/type'

export default function AdminList() {
  const dispatch = useDispatch()
  const { adminList, adminTotal } = useSelector(
    (state: StoreState) => state.admin
  )
  const { formatMessage: f } = useIntl()
  const navigate = useNavigate()
  const { status, setSnackbar, clearSnackbar } = useGlobalSnackbar({
    page: 'admin',
  })
  const {
    pagination: { count, page },
    handlePageChange,
    query,
  } = usePagination({
    count: adminTotal,
    beforePageChange: () => dispatch(stopPollingListAction()),
  })
  const { openSpinner } = useLoading({
    endActions: {
      success: [AdminActionType.GET_LIST_SUCCESS],
      error: [AdminActionType.GET_LIST_ERROR],
    },
    cbk: {
      error: ({ error }: { error: ErrorResponse }) => {
        setSnackbar({
          state: SnackbarState.Error,
          message: error.message,
          notKeepData: true,
        })
        document.documentElement.scrollTop = 0
      },
    },
  })

  const theadList = [
    {
      id: AdminKeys.Name,
      label: f(commonMessages.name),
    },
    {
      id: AdminKeys.Email,
      label: f(commonMessages.email),
    },
    {
      id: AdminKeys.Active,
      label: f(commonMessages.status),
      formatter: (isActivated: boolean) => (
        <StatusCapsule isActivated={isActivated} />
      ),
    },
  ]

  const handleRowClick = (id: string) =>
    navigate(generatePath(toAdminRoute(adminPath.admin.detail), { id }))

  useEffect(() => {
    openSpinner()
    dispatch(getListAction({ ...query }))
  }, [openSpinner, dispatch, query])

  useEffect(
    () => () => {
      clearSnackbar()
    },
    [clearSnackbar]
  )

  return (
    <>
      {status && (
        <Snackbar
          severity={status.state}
          message={status.message!}
          onClose={clearSnackbar}
        />
      )}
      <ContentHeader
        title={f(messages.adminList)}
        breadcrumbs={BREADCRUMBS}
        buttonList={[
          <Button
            variant="contained"
            startIcon={<IconAdd />}
            onClick={() => navigate(toAdminRoute(adminPath.admin.create))}>
            {f(commonMessages.create)}
          </Button>,
        ]}
      />
      <ListTable
        theadList={theadList}
        dataList={adminList}
        rowIdKey={AdminKeys.Id}
        onRowClick={handleRowClick}
      />
      <Pagination count={count} page={page} onChange={handlePageChange} />
    </>
  )
}
