import React from 'react'
import { useIntl } from 'react-intl'
import ContentHeader from '@components/ContentHeader'
import DataTable from '@components/DataTable'
import commonMessages from '@common/messages'
import messages from './messages'
import { BREADCRUMBS } from './utils'
import { mockDeviceList } from '@src/mackData/user/device'
import { DeviceKeys } from '@src/user/models/device'
import { toDateTime } from '@src/utils/function'
import ConnectReady from './components/ConnectReady'
import Battery from './components/Battery'
import { toUserRoute, userPath } from '@src/common/appConfig'
import { generatePath, useParams } from 'react-router-dom'

export default function DeviceDetail() {
  const { formatMessage: f } = useIntl()
  const { id } = useParams()
  const currentDevices = mockDeviceList.items[0]

  return (
    <>
      <ContentHeader
        title={f(messages.deviceDetail)}
        breadcrumbs={BREADCRUMBS.concat([
          {
            title: messages.deviceList,
            route: generatePath(toUserRoute(userPath.device.list), {
              id,
            }),
          },
          {
            title: messages.deviceDetail,
          },
        ])}
      />
      <DataTable
        title={f(commonMessages.basicInfo)}
        dataSets={[
          {
            label: f(commonMessages.deviceSN),
            content: currentDevices[DeviceKeys.SN],
          },
          {
            label: f(messages.userId),
            content: currentDevices[DeviceKeys.UserId],
          },
          {
            label: f(messages.groupId),
            content: currentDevices[DeviceKeys.GroupId],
          },
          {
            label: f(commonMessages.deviceType),
            content: currentDevices[DeviceKeys.DeviceType],
          },
          {
            label: f(messages.enrollTime),
            content: toDateTime(currentDevices[DeviceKeys.EnrollTime]),
          },
          {
            label: f(messages.connectReady),
            content: (
              <ConnectReady isReady={currentDevices[DeviceKeys.ConnectReady]} />
            ),
          },
          {
            label: f(messages.batteryHMD),
            content: (
              <Battery battery={currentDevices[DeviceKeys.BatteryHMD]} />
            ),
          },
          {
            label: f(messages.batteryCL),
            content: <Battery battery={currentDevices[DeviceKeys.BatteryCL]} />,
          },
          {
            label: f(messages.batteryCR),
            content: <Battery battery={currentDevices[DeviceKeys.BatteryCR]} />,
          },
          {
            label: f(commonMessages.status),
            content: currentDevices[DeviceKeys.Status],
          },
          {
            label: f(messages.lastTask),
            content: currentDevices[DeviceKeys.LastTask],
          },
          {
            label: f(messages.lastTaskTime),
            content: toDateTime(currentDevices[DeviceKeys.LastTaskTime]),
          },
        ]}
      />
    </>
  )
}
