import { adminPath, toAdminRoute } from '@common/appConfig'
import { Breadcrumb } from '@components/ContentHeader'
import messages from '@admin/utils/messages'

export const BREADCRUMBS: Breadcrumb[] = [
  { title: messages.managerManagement },
  { title: messages.managerList },
]

export const DETAIL_BREADCRUMBS: Breadcrumb[] = [
  { title: messages.managerManagement },
  { title: messages.managerList, route: toAdminRoute(adminPath.manager.list) },
]
