import { JSON_CONTENT } from '@src/utils/ajaxUtils'
import authAjax from '@manager/utils/ajaxUtils'
import {
  CfgPayload,
  DefaultCfgPayload,
  DenyCfgPayload,
} from '@src/manager/models/userDashboard'
import { objToQueryStr } from '@src/utils/function'

const DEFAULT_CFG_API_PATH = '/user/defaultcfg'
const toDefaultConfigPath = (id: string) => `${DEFAULT_CFG_API_PATH}/${id}`

const DENY_CFG_API_PATH = '/user/denycfg'
const toDenyConfigPath = (id: string) => `${DENY_CFG_API_PATH}/${id}`

export const getDefaultConfigAjax = (payload: DefaultCfgPayload) =>
  authAjax.get(
    `${DEFAULT_CFG_API_PATH}/detail${objToQueryStr(payload)}`,
    JSON_CONTENT
  )

export const createDefaultConfigAjax = ({ id, data }: CfgPayload) =>
  authAjax.post(toDefaultConfigPath(id), data, JSON_CONTENT)

export const updateDefaultConfigAjax = ({ id, data }: CfgPayload) =>
  authAjax.put(toDefaultConfigPath(id), data, JSON_CONTENT)

export const deleteDefaultConfigAjax = (payload: DefaultCfgPayload) =>
  authAjax.delete(
    `${DEFAULT_CFG_API_PATH}/delete${objToQueryStr(payload)}`,
    JSON_CONTENT
  )

export const getDenyConfigAjax = (payload: DenyCfgPayload) =>
  authAjax.get(
    `${DENY_CFG_API_PATH}/detail${objToQueryStr(payload)}`,
    JSON_CONTENT
  )

export const createDenyConfigAjax = ({ id, data }: CfgPayload) =>
  authAjax.post(toDenyConfigPath(id), data, JSON_CONTENT)

export const updateDenyConfigAjax = ({ id, data }: CfgPayload) =>
  authAjax.put(toDenyConfigPath(id), data, JSON_CONTENT)

export const deleteDenyConfigAjax = (payload: DenyCfgPayload) =>
  authAjax.delete(
    `${DENY_CFG_API_PATH}/delete${objToQueryStr(payload)}`,
    JSON_CONTENT
  )
