import { AnyAction } from 'redux'
import { combineEpics } from 'redux-observable'
import { AccountActionType } from '../reducers/account/accountActions'
import { AdminActionType } from '../reducers/admin/adminActions'
import { ManagerActionType } from '../reducers/manager/managerActions'
import accountEpics from './account/accountEpics'
import adminEpics from './admin/adminEpics'
import managerEpics from './manager/managerEpics'

export type RootAction = AdminActionType | AccountActionType | ManagerActionType

export const rootEpic = combineEpics<
  AnyAction,
  {
    type: RootAction
    payload?: any
  },
  void,
  any
>(...accountEpics, ...adminEpics, ...managerEpics)
