import React, { useEffect } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import { Button } from '@mui/material'
import { StoreState } from '@src/user/reducers'
import {
  getMemberAction,
  MemberActionType,
  resetMemberAction,
  updateMemberAction,
} from '@src/user/reducers/member/memberActions'
import { MemberDetail } from '@user/models/dashboard'
import { ErrorResponse } from '@src/utils/type'
import { useGlobalSnackbar, useLoading } from '@src/hooks'
import ContentHeader from '@src/components/ContentHeader'
import { ReactComponent as IconSave } from '@src/assets/icons/iconSave.svg'
import Snackbar, { SnackbarState } from '@src/components/Snackbar'
import MemberForm from './components/MemberForm'
import { RouteSection, toUserRoute, userPath } from '@src/common/appConfig'
import commonMessages from '@common/messages'
import userMessages from '@user/utils/messages'
import messages from './messages'

export default function MemberEdit() {
  const { formatMessage: f } = useIntl()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { currentMember } = useSelector((state: StoreState) => state.member)
  const { id, email } = useParams()
  const { control, handleSubmit, reset } = useForm<MemberDetail>({
    defaultValues: currentMember,
  })
  const { status, setSnackbar, clearSnackbar } = useGlobalSnackbar({
    page: RouteSection.Dashboard,
  })
  const { openSpinner } = useLoading({
    endActions: {
      success: [
        MemberActionType.GET_MEMBER_SUCCESS,
        MemberActionType.UPDATE_MEMBER_SUCCESS,
      ],
      error: [
        MemberActionType.GET_MEMBER_ERROR,
        MemberActionType.UPDATE_MEMBER_ERROR,
      ],
    },
    cbk: {
      success: ({ type }: { type: MemberActionType }) => {
        if (type === MemberActionType.UPDATE_MEMBER_SUCCESS) {
          setSnackbar({
            state: SnackbarState.Success,
            message: f(commonMessages.updateSuccess),
            notKeepData: false,
          })
          document.documentElement.scrollTop = 0
          navigate(
            generatePath(toUserRoute(userPath.dashboard.dashboard), {
              id,
            })
          )
        }
      },
      error: ({
        type,
        error,
      }: {
        type: MemberActionType
        error: ErrorResponse
      }) => {
        const msg =
          type === MemberActionType.UPDATE_MEMBER_ERROR
            ? f(commonMessages.updateFailed)
            : error?.message ?? f(commonMessages.unknownError)
        setSnackbar({
          state: SnackbarState.Error,
          message: msg,
          notKeepData: true,
        })
        document.documentElement.scrollTop = 0
      },
    },
  })

  const onSubmit = (data: MemberDetail) => {
    openSpinner()
    dispatch(updateMemberAction({ id: id!, email: email!, data }))
  }

  useEffect(() => {
    openSpinner()
    dispatch(getMemberAction({ id: id!, email: email! }))
  }, [dispatch, openSpinner, id, email])

  useEffect(() => {
    if (!!currentMember) {
      reset(currentMember)
    }
  }, [currentMember, reset])

  useEffect(
    () => () => {
      dispatch(resetMemberAction())
    },
    [dispatch]
  )

  return (
    <>
      {status && (
        <Snackbar
          severity={SnackbarState.Error}
          message={status.message!}
          onClose={clearSnackbar}
        />
      )}
      {currentMember && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <ContentHeader
            title={f(messages.memberEdit)}
            breadcrumbs={[
              {
                title: userMessages.dashboard,
                route: generatePath(toUserRoute(userPath.dashboard.dashboard), {
                  id,
                }),
              },
              {
                title: messages.memberList,
              },
              {
                title: messages.memberEdit,
              },
            ]}
            buttonList={[
              <Button
                variant="contained"
                type="submit"
                startIcon={<IconSave />}>
                {f(commonMessages.save)}
              </Button>,
            ]}
          />
          <MemberForm control={control} />
        </form>
      )}
    </>
  )
}
