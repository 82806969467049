import { ActionType } from '@src/utils/type'
import { Dashboard } from '@src/user/models/dashboard'
import { DashboardActionType } from './dashboardActions'

export interface DashboardState {
  currentDashboard?: Dashboard
}

const initState: DashboardState = {}

export const DashboardPreloadState = initState

const updateDashboardHandler = (
  state: DashboardState = initState,
  action: ActionType<Dashboard | undefined>
): DashboardState => {
  return {
    ...state,
    currentDashboard: action.payload,
  }
}

const handler: Record<
  string,
  (state: DashboardState, action: ActionType<any>) => DashboardState
> = {
  [DashboardActionType.GET_SUCCESS]: updateDashboardHandler,
  [DashboardActionType.RESET_DASHBOARD]: updateDashboardHandler,
}

export default function dashboardReducer(
  state: DashboardState = initState,
  action: ActionType<any>
) {
  if (handler.hasOwnProperty(action.type)) {
    return handler[action.type](state, action)
  }
  return state
}
