import { Profile } from '@src/admin/models/account'
import { MessageDescriptor } from 'react-intl'

export enum AccountActionType {
  LOGIN = '@Account/LOGIN',

  LOGOUT = '@Account/LOGOUT',
  LOGOUT_SUCCESS = '@Account/LOGOUT_SUCCESS',
  LOGOUT_ERROR = '@Account/LOGOUT_ERROR',

  UNAUTHORIZED = '@Account/UNAUTHORIZED',

  GET_PROFILE = '@Account/GET_PROFILE',
  GET_PROFILE_SUCCESS = '@Account/GET_PROFILE_SUCCESS',
  GET_PROFILE_ERROR = '@Account/GET_PROFILE_ERROR',
}

export const loginAction = (payload: string) => ({
  type: AccountActionType.LOGIN,
  payload,
})

export const logoutAction = () => ({
  type: AccountActionType.LOGOUT,
})

export const logoutSuccessAction = () => ({
  type: AccountActionType.LOGOUT_SUCCESS,
})

export const logoutErrorAction = () => ({
  type: AccountActionType.LOGOUT_ERROR,
})

export const unauthorizedAction = (payload: MessageDescriptor) => ({
  type: AccountActionType.UNAUTHORIZED,
  payload,
})

export const getProfileAction = () => ({
  type: AccountActionType.GET_PROFILE,
})

export const getProfileSuccessAction = (payload: Profile) => ({
  type: AccountActionType.GET_PROFILE_SUCCESS,
  payload,
})

export const getProfileErrorAction = (error?: any) => ({
  type: AccountActionType.GET_PROFILE_ERROR,
  error,
})
