import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteManagerAction,
  getManagerAction,
  ManagerActionType,
} from '@src/admin/reducers/manager/managerActions'
import { StoreState } from '@src/admin/reducers'
import { Button } from '@mui/material'
import { useGlobalSnackbar, useLoading } from '@src/hooks'
import { adminPath, toAdminRoute } from '@common/appConfig'
import ContentHeader from '@components/ContentHeader'
import { StatusCapsule } from '@components/Capsule'
import DataTable from '@components/DataTable'
import Snackbar, { SnackbarState } from '@src/components/Snackbar'
import commonMessages from '@common/messages'
import messages from '@admin/utils/messages'
import { ReactComponent as IconEdit } from '@src/assets/icons/iconEdit.svg'
import { ReactComponent as IconDelete } from '@src/assets/icons/iconDelete.svg'
import { DETAIL_BREADCRUMBS } from './utils'
import { AdminKeys } from '@admin/models/admin'
import { ErrorResponse } from '@src/utils/type'

export default function ManagerDetail() {
  const dispatch = useDispatch()
  const { currentManager } = useSelector((state: StoreState) => state.manager)
  const { formatMessage: f } = useIntl()
  const navigate = useNavigate()
  const { id } = useParams()
  const { status, setSnackbar, clearSnackbar } = useGlobalSnackbar({
    page: 'manager',
  })
  const { openSpinner } = useLoading({
    endActions: {
      success: [
        ManagerActionType.GET_MANAGER_SUCCESS,
        ManagerActionType.DELETE_MANAGER_SUCCESS,
      ],
      error: [
        ManagerActionType.GET_LIST_ERROR,
        ManagerActionType.DELETE_MANAGER_ERROR,
      ],
    },
    cbk: {
      success: ({ type }: { type: ManagerActionType }) => {
        if (type === ManagerActionType.DELETE_MANAGER_SUCCESS) {
          setSnackbar({
            state: SnackbarState.Success,
            message: f(commonMessages.createSuccess),
            notKeepData: false,
          })
          navigate(toAdminRoute(adminPath.admin.list))
        }
      },
      error: ({ error }: { error: ErrorResponse }) => {
        setSnackbar({
          state: SnackbarState.Error,
          message: error.message,
          notKeepData: true,
        })
        document.documentElement.scrollTop = 0
      },
    },
  })

  useEffect(() => {
    dispatch(getManagerAction(id!))
  }, [dispatch, openSpinner, id])

  const handleDelete = () => {
    openSpinner()
    dispatch(deleteManagerAction(id!))
  }

  return currentManager ? (
    <>
      <ContentHeader
        title={f(messages.managerDetail)}
        breadcrumbs={DETAIL_BREADCRUMBS.concat({
          title: messages.managerDetail,
        })}
        buttonList={[
          <Button
            variant="contained"
            startIcon={<IconEdit />}
            onClick={() =>
              navigate(
                generatePath(toAdminRoute(adminPath.manager.edit), { id })
              )
            }>
            {f(commonMessages.edit)}
          </Button>,
          <Button
            variant="contained"
            color="error"
            startIcon={<IconDelete />}
            onClick={handleDelete}>
            {f(commonMessages.delete)}
          </Button>,
        ]}
      />
      {status && (
        <Snackbar
          severity={status.state}
          message={status.message!}
          onClose={clearSnackbar}
        />
      )}
      <DataTable
        title={f(commonMessages.basicInfo)}
        dataSets={[
          {
            label: f(commonMessages.name),
            content: currentManager[AdminKeys.Name],
          },
          {
            label: f(commonMessages.email),
            content: currentManager[AdminKeys.Email],
          },
          {
            label: f(commonMessages.status),
            content: (
              <StatusCapsule isActivated={currentManager[AdminKeys.Active]} />
            ),
          },
        ]}
      />
    </>
  ) : null
}
