import { combineReducers } from 'redux'
import accountReducer, {
  AccountPreloadState,
  AccountState,
} from './account/accountReducer'
import dashboardReducer, {
  DashboardPreloadState,
  DashboardState,
} from './dashboard/dashboardReducers'
import DeviceReducer, {
  DevicePreloadState,
  DeviceState,
} from './device/deviceReducer'
import memberReducer, {
  MemberPreloadState,
  MemberState,
} from './member/memberReducers'
import agencyReducer, {
  AgencyPreloadState,
  AgencyState,
} from './agency/agencyReducers'

export interface StoreState {
  account: AccountState
  agency: AgencyState
  dashboard: DashboardState
  member: MemberState
  device: DeviceState
}

export const storePreloadState: StoreState = {
  account: AccountPreloadState,
  agency: AgencyPreloadState,
  dashboard: DashboardPreloadState,
  member: MemberPreloadState,
  device: DevicePreloadState,
}

export const rootReducer = combineReducers<StoreState>({
  account: accountReducer,
  agency: agencyReducer,
  dashboard: dashboardReducer,
  member: memberReducer,
  device: DeviceReducer,
})
