import React from 'react'
import { Box, Typography } from '@mui/material'
import QRCode from 'qrcode.react'
import { genQRcodeSize } from '../utils'

const QR_CODE_ID = 'enroll-qr-code'
const FILE_NAME = 'QRCode.png'

export default function EnrollQRCode({
  deviceName,
  value,
  enrollKey,
}: {
  deviceName: string
  value: string
  enrollKey: string
}) {
  const width = genQRcodeSize()
  const downloadQRCode = () => {
    const canvas = document
      .getElementById(QR_CODE_ID)
      ?.getElementsByTagName('canvas')[0]

    if (!canvas) return ''

    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream')
    const downloadLink = document.createElement('a')
    downloadLink.href = pngUrl
    downloadLink.download = FILE_NAME
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        width={width}
        margin="auto">
        <Typography variant="h5" marginRight={2}>
          {deviceName}
        </Typography>
        <Typography variant="h5">{enrollKey}</Typography>
      </Box>
      <Box
        id={QR_CODE_ID}
        display="flex"
        justifyContent="center"
        onClick={downloadQRCode}
        sx={{ cursor: 'pointer' }}>
        <QRCode value={value} size={width} />
      </Box>
    </>
  )
}
