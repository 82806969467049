import React from 'react'
import { useIntl } from 'react-intl'
import {
  ActivatedDeviceDetail,
  UserDashboardKeys,
} from '@manager/models/userDashboard'
import ListTable from '@src/components/ListTable'
import GlobalDialog from '@src/components/GlobalDialog'
import { ReactComponent as IconDelete } from '@src/assets/icons/iconDelete.svg'
import commonMessages from '@common/messages'
import { toDateTime } from '@src/utils/function'
import { Box, IconButton } from '@mui/material'
import SubTitle from './SubTitle'

export default function ActivatedDeviceTable({
  deviceList,
  onDelete,
}: {
  deviceList: ActivatedDeviceDetail[]
  onDelete: (id: string) => void
}) {
  const { formatMessage: f } = useIntl()

  const handleDelete = (device: string) => {
    onDelete(device)
  }

  const beforeDelete = (device: string) => {
    GlobalDialog.open({
      content: f(commonMessages.confirmDeleteDevice),
      onAgree: () => handleDelete(device),
    })
  }

  return (
    <>
      <SubTitle title={f(commonMessages.activatedDevice)} />
      <ListTable
        noMarginTop
        dataList={deviceList}
        theadList={[
          {
            id: UserDashboardKeys.SN,
            label: f(commonMessages.deviceSN),
          },
          {
            id: UserDashboardKeys.Date,
            label: f(commonMessages.createDate),
            formatter: (date: string) => toDateTime(date),
          },
          {
            id: UserDashboardKeys.DeviceType,
            label: f(commonMessages.deviceType),
          },
          {
            id: UserDashboardKeys.SN,
            label: '',
            formatter: (id: string) => (
              <Box display="flex" flexDirection="row-reverse">
                <IconButton
                  sx={({ palette }) => ({
                    marginLeft: 1,
                    bgcolor: palette.error.main,
                    '&>svg': { width: 15, height: 15 },
                  })}
                  onClick={() => beforeDelete(id)}>
                  <IconDelete />
                </IconButton>
              </Box>
            ),
          },
        ]}
      />
    </>
  )
}
