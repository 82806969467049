import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'
import { SIDEBAR_TABS } from '@admin/utils/constants'
import { StoreState } from '@src/admin/reducers'
import { Content, Header, Sidebar } from '@components/Layout'
import { adminPath, toAdminRoute } from '@src/common/appConfig'
import ProfileMenu from './components/ProfileMenu'
import AuthDialog from './components/AuthDialog'

export default function Layout() {
  const { isLogin } = useSelector(
    (state: StoreState) => ({
      isLogin: !!state.account.token,
    }),
    shallowEqual
  )

  return isLogin ? (
    <>
      <Header identity="Admin">
        <ProfileMenu />
      </Header>
      <Sidebar tabs={SIDEBAR_TABS} />
      <AuthDialog />
      <Content>
        <Outlet />
      </Content>
    </>
  ) : (
    <Navigate to={toAdminRoute(adminPath.login.login)} replace />
  )
}
