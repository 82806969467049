import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import {
  DashboardActionType,
  getDashboardAction,
} from '@user/reducers/dashboard/dashboardActions'
import {
  deleteMemberAction,
  MemberActionType,
} from '@user/reducers/member/memberActions'
import { dashboardSelectors } from '@user/reducers/dashboard/dashboardSelectors'
import { ErrorResponse } from '@src/utils/type'
import { DashboardKeys } from '@user/models/dashboard'
import { useGlobalSnackbar, useLoading } from '@src/hooks'
import Snackbar, { SnackbarState } from '@src/components/Snackbar'
import ContentHeader from '@components/ContentHeader'
import UserInfo from './components/UserInfo'
import ConfigTable from './components/ConfigTable'
import ActivatedDeviceTable from './components/ActivatedDeviceTable'
import MemberTable from './components/MemberTable'
import { userPath, toUserRoute, RouteSection } from '@common/appConfig'
import commonMessages from '@common/messages'
import messages from './messages'
import { BREADCRUMBS } from './utils'

export default function Dashboard() {
  const { formatMessage: f } = useIntl()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const { currentDashboard } = useSelector(dashboardSelectors)
  const { status, setSnackbar, clearSnackbar } = useGlobalSnackbar({
    page: RouteSection.Dashboard,
  })
  const { openSpinner } = useLoading({
    endActions: {
      success: [
        DashboardActionType.GET_SUCCESS,
        MemberActionType.DELETE_MEMBER_SUCCESS,
      ],
      error: [
        DashboardActionType.GET_ERROR,
        MemberActionType.DELETE_MEMBER_ERROR,
      ],
    },
    cbk: {
      success: ({ type }: { type: DashboardActionType | MemberActionType }) => {
        if (type === MemberActionType.DELETE_MEMBER_SUCCESS) {
          setSnackbar({
            state: SnackbarState.Success,
            message: f(commonMessages.deleteSuccess),
            notKeepData: true,
          })
          document.documentElement.scrollTop = 0
          handleGetDashboard()
        }
      },
      error: ({
        type,
        error,
      }: {
        type: DashboardActionType | MemberActionType
        error: ErrorResponse
      }) => {
        setSnackbar({
          state: SnackbarState.Error,
          message:
            type === MemberActionType.DELETE_MEMBER_ERROR
              ? f(commonMessages.deleteFailed)
              : error?.message ?? f(commonMessages.unknownError),
          notKeepData: true,
        })
        document.documentElement.scrollTop = 0
      },
    },
  })

  const handleGetDashboard = useCallback(() => {
    openSpinner()
    dispatch(getDashboardAction(id!))
  }, [dispatch, openSpinner, id])

  const handleCreateMember = () => {
    navigate(
      generatePath(toUserRoute(userPath.member.create), {
        id,
      })
    )
  }
  const handleDeleteMember = (email: string) => {
    openSpinner()
    dispatch(deleteMemberAction({ id: id!, email }))
  }
  const handleEditMember = (email: string) => {
    navigate(
      generatePath(toUserRoute(userPath.member.edit), {
        id,
        email,
      })
    )
  }

  useEffect(() => {
    handleGetDashboard()
  }, [handleGetDashboard])

  useEffect(
    () => () => {
      clearSnackbar()
    },
    [clearSnackbar]
  )

  return (
    <>
      {status && (
        <Snackbar
          severity={status.state}
          message={status.message!}
          onClose={clearSnackbar}
        />
      )}
      {currentDashboard && (
        <>
          <ContentHeader
            title={f(messages.dashboard)}
            breadcrumbs={BREADCRUMBS.concat({ title: messages.dashboard })}
          />
          <UserInfo userInfo={currentDashboard} />
          <MemberTable
            memberList={currentDashboard[DashboardKeys.Member]}
            onCreate={handleCreateMember}
            onEdit={handleEditMember}
            onDelete={handleDeleteMember}
          />
          <ActivatedDeviceTable
            deviceList={currentDashboard[DashboardKeys.ActivatedDevices]}
          />
          <ConfigTable
            title={f(messages.defaultCFG)}
            configList={currentDashboard[DashboardKeys.DefaultCFG]}
          />
          <ConfigTable
            title={f(messages.denyCFG)}
            configList={currentDashboard[DashboardKeys.DenyCFG]}
          />
        </>
      )}
    </>
  )
}
