import React from 'react'
import { Box } from '@mui/material'
import { ReactComponent as IconConnect } from '@src/assets/icons/iconConnect.svg'

export default function ConnectReady({ isReady }: { isReady: boolean }) {
  return isReady ? (
    <Box
      sx={({ palette }) => ({
        width: 30,
        height: 30,
        padding: '5px',
        borderRadius: '50%',
        bgcolor: palette.success.main,
        '&>svg': { width: 20, height: 20 },
      })}>
      <IconConnect />
    </Box>
  ) : null
}
