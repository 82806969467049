import React from 'react'
import { useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import { Button } from '@mui/material'
import { ControlledSelect, SelectOption } from '@components/hookForm'
import SearchFilter from '@components/SearchFilter'
import { DeviceKeys } from '@src/user/models/device'
import { REQUIRED } from '@src/utils/validation'
import { ReactComponent as IconSearch } from '@src/assets/icons/iconSearch.svg'
import { ReactComponent as IconReset } from '@src/assets/icons/iconReset.svg'
import commonMessages from '@common/messages'
import messages from '../messages'

export default function SearchBlock({
  groupOptions,
  onSubmit,
  onReset,
  defaultValue,
}: {
  groupOptions?: SelectOption[]
  onSubmit: (data: object) => void
  onReset: () => void
  defaultValue: object
}) {
  const { formatMessage: f } = useIntl()
  const { handleSubmit, control } = useForm<{ [key: string]: any }>({
    defaultValues: defaultValue,
  })

  if (!groupOptions) return null
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SearchFilter
        fields={[
          {
            label: f(messages.groupId),
            field: (
              <ControlledSelect
                name={DeviceKeys.GroupId}
                control={control}
                options={groupOptions}
                required={REQUIRED}
                fullWidth
              />
            ),
          },
        ]}>
        <Button
          variant="contained"
          type="submit"
          startIcon={<IconSearch />}
          sx={{ marginRight: 2 }}>
          {f(commonMessages.search)}
        </Button>
        <Button variant="contained" startIcon={<IconReset />} onClick={onReset}>
          {f(commonMessages.reset)}
        </Button>
      </SearchFilter>
    </form>
  )
}
