import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Paper, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { ACCOUNT_TERMS, PRIVACY_POLICY } from '@src/common/constants'
import LogoImg from '@src/assets/logo-htc-vive.svg'
import messages from '../../common/messages'

interface Props {
  title?: string
  children: React.ReactNode
}

const Logo = styled('div')({
  height: '1.4875rem',
  background: `url(${LogoImg}) left no-repeat`,
  backgroundSize: 'contain',
})
const LogoLine = styled('div')(({ theme }) => ({
  height: '1px',
  width: '100%',
  background: theme.palette.grey[300],
  transform: 'translateY(0.75rem)',
}))

export default function LoginBox({ children, title }: Props) {
  const { formatMessage: f } = useIntl()

  return (
    <Box mx="auto" width={400}>
      <Paper>
        <Box padding={2}>
          <Logo />
          <Box my={2}>
            <Typography
              variant="body1"
              component="h1"
              fontWeight="fontWeightBold">
              VIVE Business Management System
            </Typography>
          </Box>
          {title && (
            <Box my={1} textAlign="center">
              <Typography
                variant="h5"
                component="h1"
                fontWeight="fontWeightBold">
                {title}
              </Typography>
            </Box>
          )}
          <Box my={1} display="flex">
            <Typography variant="h6" component="div" minWidth="5rem">
              {f(messages.login)}
            </Typography>
            <LogoLine />
          </Box>
          {children}
          <Box
            paddingTop={5}
            margin="auto"
            sx={({ palette }) => ({ a: { color: palette.primary.main } })}>
            {f(messages.termsAndCondition, {
              terms: (
                <a href={ACCOUNT_TERMS} target="_blank" rel="noreferrer">
                  {f(messages.accountTerms)}
                </a>
              ),
              policy: (
                <a href={PRIVACY_POLICY} target="_blank" rel="noreferrer">
                  {f(messages.privacyPolicy)}
                </a>
              ),
            })}
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}
