import React from 'react'
import { Box, Grid, GridSize, Paper } from '@mui/material'

interface FieldRow {
  label: string
  field: JSX.Element
}

export default function SearchFilter({
  fields,
  children,
  labelWidth = 3,
  fieldContainerWidth = 6,
  fieldWidth = 9,
}: {
  fields: FieldRow[]
  children?: React.ReactNode
  labelWidth?: GridSize
  fieldContainerWidth?: GridSize
  fieldWidth?: GridSize
}) {
  return (
    <Box marginY={2} width="100%">
      <Paper
        sx={{
          padding: 2,
        }}>
        <Grid container spacing={3}>
          {fields.map(({ label, field }) => (
            <Grid
              key={label}
              item
              container
              spacing={1}
              xs={fieldContainerWidth}
              alignItems="center"
              justifyContent="flex-start">
              <Grid item xs={labelWidth}>
                {label}
              </Grid>
              <Grid item xs={fieldWidth}>
                {field}
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Box mt={2} display="flex" justifyContent="flex-end">
          {children}
        </Box>
      </Paper>
    </Box>
  )
}
