import React from 'react'
import { useIntl } from 'react-intl'
import { ActivatedDeviceDetail, DashboardKeys } from '@user/models/dashboard'
import ListTable from '@src/components/ListTable'
import commonMessages from '@common/messages'
import { toDateTime } from '@src/utils/function'
import SubTitle from './SubTitle'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { toUserRoute, userPath } from '@src/common/appConfig'

export default function ActivatedDeviceTable({
  deviceList,
}: {
  deviceList: ActivatedDeviceDetail[]
}) {
  const { formatMessage: f } = useIntl()
  const navigate = useNavigate()
  const { id } = useParams()

  const handleRowClick = (device: string) =>
    navigate(generatePath(toUserRoute(userPath.device.detail), { id, device }))

  return (
    <>
      <SubTitle title={f(commonMessages.activatedDevice)} />
      <ListTable
        noMarginTop
        dataList={deviceList}
        rowIdKey={DashboardKeys.SN}
        onRowClick={handleRowClick}
        theadList={[
          {
            id: DashboardKeys.SN,
            label: f(commonMessages.deviceSN),
          },
          {
            id: DashboardKeys.Date,
            label: f(commonMessages.createDate),
            formatter: (date: string) => toDateTime(date),
          },
          {
            id: DashboardKeys.DeviceType,
            label: f(commonMessages.deviceType),
          },
        ]}
      />
    </>
  )
}
