import React from 'react'
import { Pagination as MuiPagination } from '@mui/material'
import { PAGE_LIMIT } from '@src/common/appConfig'

interface Props {
  count: number
  page: number
  onChange: (e: React.ChangeEvent<unknown>, page: number) => void
}

export default function Pagination({ count, page, onChange }: Props) {
  return (
    <MuiPagination
      count={Math.ceil(count / PAGE_LIMIT)}
      page={page}
      variant="outlined"
      shape="rounded"
      color="primary"
      onChange={onChange}
      sx={{
        marginTop: 2,
      }}
    />
  )
}
