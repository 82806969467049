import { AnyAction } from 'redux'
import { combineEpics } from 'redux-observable'
import { AccountActionType } from '../reducers/account/accountActions'
import { DeviceActionType } from '../reducers/device/deviceActions'
import { MemberActionType } from '../reducers/member/memberActions'
import { UserConfigActionType } from '../reducers/userConfig/userConfigActions'
import { UserDashboardActionType } from '../reducers/userDashboard/userDashboardActions'
import { AgencyActionType } from '../reducers/agency/agencyActions'
import accountEpics from './account/accountEpics'
import deviceEpics from './device/deviceEpics'
import memberEpics from './member/memberEpics'
import userConfigEpics from './userConfig/userConfigEpics'
import userDashboardEpics from './userDashBoard/userDashBoardEpics'
import agencyEpics from './agency/agencyEpics'

export type RootAction =
  | AccountActionType
  | AgencyActionType
  | UserDashboardActionType
  | UserConfigActionType
  | DeviceActionType
  | MemberActionType

export const rootEpic = combineEpics<
  AnyAction,
  {
    type: RootAction
    payload?: any
  },
  void,
  any
>(
  ...accountEpics,
  ...agencyEpics,
  ...userDashboardEpics,
  ...userConfigEpics,
  ...deviceEpics,
  ...memberEpics
)
