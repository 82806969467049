import React from 'react'
import { generatePath, Link, useNavigate, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { usePagination } from '@src/hooks'
import { toUserRoute, userPath } from '@common/appConfig'
import ContentHeader from '@components/ContentHeader'
import Pagination from '@components/Pagination'
import ListTable from '@components/ListTable'
import { DeviceKeys } from '@src/user/models/device'
import { GroupKeys } from '@src/user/models/group'
import commonMessages from '@common/messages'
import messages from './messages'
import { BREADCRUMBS } from './utils'
import { mockGroupList } from '@src/mackData/user/group'
import { ReactComponent as IconDevice } from '@src/assets/icons/iconHeadset.svg'
import { ReactComponent as IconDelete } from '@src/assets/icons/iconDelete.svg'
import { ReactComponent as IconAdd } from '@src/assets/icons/iconAdd.svg'
import { Box, Button, IconButton } from '@mui/material'
import { objToQueryStr } from '@src/utils/function'
import GlobalDialog from '@src/components/GlobalDialog'

export default function GroupList() {
  const { formatMessage: f } = useIntl()
  const navigate = useNavigate()
  const { id } = useParams()
  const groupList = mockGroupList.items
  const {
    pagination: { count, page },
    handlePageChange,
  } = usePagination({
    count: mockGroupList.count,
  })

  const handleToDeviceList =
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
    (id: string) => {
      event.stopPropagation()
      navigate(
        generatePath(toUserRoute(userPath.device.list), { id }) +
          objToQueryStr({ [DeviceKeys.GroupId]: id })
      )
    }

  const handleRowClick = (group: string) =>
    navigate(generatePath(toUserRoute(userPath.group.edit), { id, group }))

  const handleDelete = (group: string) => {
    console.log({ id, group })
  }

  const beforeDelete =
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
    (group: string) => {
      event.stopPropagation()
      GlobalDialog.open({
        content: f(messages.confirmDeleteGroup),
        onAgree: () => handleDelete(group),
      })
    }
  const theadList = [
    {
      id: GroupKeys.GroupName,
      label: f(messages.groupName),
    },
    {
      id: GroupKeys.ConfigId,
      innerId: GroupKeys.ConfigName,
      label: f(messages.configId),
      formatter: (config: string) => (label: string) =>
        (
          <Link
            onClick={(e) => e.stopPropagation()}
            to={generatePath(toUserRoute(userPath.config.detail), {
              id,
              config,
            })}>
            {label}
          </Link>
        ),
    },
    {
      id: GroupKeys.MapId,
      innerId: GroupKeys.MapName,
      label: f(messages.mapId),
      formatter: (map: string) => (label: string) =>
        (
          <Link
            onClick={(e) => e.stopPropagation()}
            to={generatePath(toUserRoute(userPath.map.detail), {
              id,
              map,
            })}>
            {label}
          </Link>
        ),
    },
    {
      id: GroupKeys.RomVersion,
      label: f(messages.romVersion),
    },
    {
      id: GroupKeys.Description,
      label: f(commonMessages.description),
    },
    {
      id: GroupKeys.Id,
      label: '',
      formatter: (id: string) => (
        <Box display="flex" flexDirection="row-reverse">
          <IconButton
            sx={({ palette }) => ({
              marginLeft: 1,
              bgcolor: palette.error.main,
              '&>svg': { width: 15, height: 15 },
            })}
            onClick={(e) => beforeDelete(e)(id)}>
            <IconDelete />
          </IconButton>
          <IconButton
            sx={({ palette }) => ({
              marginLeft: 1,
              bgcolor: palette.primary.main,
              '&>svg': { width: 15, height: 15 },
            })}
            onClick={(e) => handleToDeviceList(e)(id)}>
            <IconDevice />
          </IconButton>
        </Box>
      ),
    },
  ]

  return (
    <>
      <ContentHeader
        title={f(messages.groupList)}
        breadcrumbs={BREADCRUMBS.concat({
          title: messages.groupList,
        })}
        buttonList={[
          <Button
            variant="contained"
            startIcon={<IconAdd />}
            onClick={() =>
              navigate(generatePath(toUserRoute(userPath.group.create), { id }))
            }>
            {f(commonMessages.create)}
          </Button>,
        ]}
      />
      <ListTable
        theadList={theadList}
        dataList={groupList}
        rowIdKey={GroupKeys.Id}
        onRowClick={handleRowClick}
      />
      <Pagination count={count} page={page} onChange={handlePageChange} />
    </>
  )
}
