import React from 'react'
import { Box } from '@mui/material'

export default function FieldRow({
  label,
  children,
  alignItems = 'center',
}: {
  label: string | JSX.Element
  classnames?: string
  alignItems?: string
  children: React.ReactNode
}) {
  return (
    <Box display="flex" flexWrap="nowrap">
      <Box
        sx={{
          width: 120,
          paddingTop: 2,
          whiteSpace: 'pre',
          alignItems: 'center',
          fontWeight: 'fontWeightBold',
        }}>
        {label}
      </Box>
      <Box
        sx={{
          paddingY: 1,
          paddingLeft: 4,
          whiteSpace: 'pre',
          alignItems: 'center',
          flexGrow: 1,
          flexBasis: 1,
        }}>
        {children}
      </Box>
    </Box>
  )
}
