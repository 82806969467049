import { Dashboard } from '@src/user/models/dashboard'

export enum DashboardActionType {
  GET = '@dashboard/GET',
  GET_SUCCESS = '@dashboard/GET_SUCCESS',
  GET_ERROR = '@dashboard/GET_ERROR',

  RESET_DASHBOARD = '@dashboard/RESET_DASHBOARD',
}

export const getDashboardAction = (payload: string) => ({
  type: DashboardActionType.GET,
  payload,
})

export const getDashboardSuccessAction = (payload: Dashboard) => ({
  type: DashboardActionType.GET_SUCCESS,
  payload,
})

export const resetDashboardAction = (payload: string) => ({
  type: DashboardActionType.RESET_DASHBOARD,
  payload,
})
