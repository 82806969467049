import React from 'react'
import { Controller, UseControllerProps } from 'react-hook-form'

interface Props<T> extends UseControllerProps<T> {
  is: any
  children: JSX.Element[] | JSX.Element
  reset?: () => void
}

export default function Condition<T>({
  control,
  name,
  is,
  children,
  reset,
}: Props<T>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value } }) => {
        if (value === is) {
          return <>{children}</>
        }

        if (reset) {
          reset()
        }
        return <></>
      }}
    />
  )
}
