import { useEffect, useCallback } from 'react'
import { Subscription } from 'rxjs'
import { successSubject, errorSubject } from '@src/utils/responseSubject'
import GlobalSpinner from '@src/components/GlobalSpinner'

interface Props {
  endActions: {
    success?: string[]
    error?: string[]
  }
  cbk?: {
    success?: Function
    error?: Function
  }
}

export default function useLoading({ endActions, cbk }: Props) {
  const openSpinner = useCallback(() => GlobalSpinner.open(), [])
  const closeSpinner = useCallback(() => GlobalSpinner.close(), [])

  useEffect(() => {
    const { success, error } = endActions || {}
    let [successSub, errorSub]: (undefined | Subscription)[] = []
    if (success) {
      successSub = successSubject.subscribe(success, (payload) => {
        closeSpinner()
        cbk?.success?.(payload)
      })
    }
    if (error) {
      errorSub = errorSubject.subscribe(error, (payload) => {
        closeSpinner()
        cbk?.error?.(payload)
      })
    }

    return () => {
      successSub?.unsubscribe()
      errorSub?.unsubscribe()
    }
  }, [endActions, closeSpinner, cbk])

  return {
    openSpinner,
  }
}
