import React, { useCallback, useEffect } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { UserDashboardKeys } from '@src/manager/models/userDashboard'
import {
  managerPath,
  RouteSection,
  toManagerRoute,
} from '@src/common/appConfig'
import { userDashboardSelectors } from '@manager/reducers/userDashboard/userDashboardSelectors'
import {
  getUserDashboardAction,
  UserDashboardActionType,
} from '@manager/reducers/userDashboard/userDashboardActions'
import {
  deleteActivatedDeviceAction,
  DeviceActionType,
} from '@src/manager/reducers/device/deviceActions'
import {
  deleteDefaultConfigAction,
  deleteDenyConfigAction,
  UserConfigActionType,
} from '@src/manager/reducers/userConfig/userConfigActions'
import { ErrorResponse } from '@src/utils/type'
import { useGlobalSnackbar, useLoading } from '@src/hooks'
import ContentHeader from '@src/components/ContentHeader'
import Snackbar, { SnackbarState } from '@src/components/Snackbar'
import ConfigTable from './components/ConfigTable'
import MemberTable from './components/MemberTable'
import UserInfo from './components/UserInfo'
import ActivatedDeviceTable from './components/ActivatedDeviceTable'
import commonMessages from '@common/messages'
import messages from '@manager/utils/messages'
import { BREADCRUMBS } from './utils'
import {
  deleteMemberAction,
  MemberActionType,
} from '@src/manager/reducers/member/memberActions'

export default function UserDashboard() {
  const { formatMessage: f } = useIntl()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const { currentUserDashboard } = useSelector(userDashboardSelectors)
  const { status, setSnackbar, clearSnackbar } = useGlobalSnackbar({
    page: RouteSection.UserDashboard,
  })
  const { openSpinner } = useLoading({
    endActions: {
      success: [
        UserDashboardActionType.GET_USER_DASHBOARD_SUCCESS,
        DeviceActionType.DELETE_ACTIVATED_DEVICES_SUCCESS,
        MemberActionType.DELETE_MEMBER_SUCCESS,
        UserConfigActionType.DELETE_DEFAULT_CONFIG_SUCCESS,
        UserConfigActionType.DELETE_DENY_CONFIG_SUCCESS,
      ],
      error: [
        UserDashboardActionType.GET_USER_DASHBOARD_ERROR,
        DeviceActionType.DELETE_ACTIVATED_DEVICES_ERROR,
        MemberActionType.DELETE_MEMBER_ERROR,
        UserConfigActionType.DELETE_DEFAULT_CONFIG_ERROR,
        UserConfigActionType.DELETE_DENY_CONFIG_ERROR,
      ],
    },
    cbk: {
      success: ({
        type,
      }: {
        type:
          | UserDashboardActionType
          | DeviceActionType
          | MemberActionType
          | UserConfigActionType
      }) => {
        if (type === UserDashboardActionType.GET_USER_DASHBOARD_SUCCESS) {
          return
        }
        setSnackbar({
          state: SnackbarState.Success,
          message: f(commonMessages.deleteSuccess),
          notKeepData: true,
        })
        document.documentElement.scrollTop = 0
        handleGetUserDashboard()
      },
      error: ({ error }: { error: ErrorResponse }) => {
        setSnackbar({
          state: SnackbarState.Error,
          message: error?.message ?? f(commonMessages.unknownError),
          notKeepData: true,
        })
        document.documentElement.scrollTop = 0
      },
    },
  })

  const handleGetUserDashboard = useCallback(() => {
    openSpinner()
    dispatch(getUserDashboardAction(id!))
  }, [dispatch, openSpinner, id])

  const handleDeleteActivatedDevice = (device: string) => {
    openSpinner()
    dispatch(deleteActivatedDeviceAction({ id: id!, device }))
  }

  const handleCreateDefaultCFG = () => {
    navigate(
      generatePath(toManagerRoute(managerPath.userConfig.defaultCFGCreate), {
        id,
      })
    )
  }
  const handleEditDefaultCFG = (defaultcfg: string) => {
    navigate(
      generatePath(toManagerRoute(managerPath.userConfig.defaultCFGEdit), {
        id: id,
        defaultcfg,
      })
    )
  }
  const handleDeleteDefaultCFG = (defaultcfg: string) => {
    openSpinner()
    dispatch(deleteDefaultConfigAction({ id: id!, defaultcfg }))
  }
  const handleCreateDenyCFG = () => {
    navigate(
      generatePath(toManagerRoute(managerPath.userConfig.denyCFGCreate), {
        id,
      })
    )
  }
  const handleDeleteDenyCFG = (denycfg: string) => {
    openSpinner()
    dispatch(deleteDenyConfigAction({ id: id!, denycfg }))
  }
  const handleEditDenyCFG = (denycfg: string) => {
    navigate(
      generatePath(toManagerRoute(managerPath.userConfig.denyCFGEdit), {
        id,
        denycfg,
      })
    )
  }
  const handleCreateMember = () => {
    navigate(
      generatePath(toManagerRoute(managerPath.member.memberCreate), {
        id,
      })
    )
  }
  const handleDeleteMember = (email: string) => {
    openSpinner()
    dispatch(deleteMemberAction({ id: id!, email }))
  }
  const handleEditMember = (email: string) => {
    navigate(
      generatePath(toManagerRoute(managerPath.member.memberEdit), {
        id,
        email,
      })
    )
  }

  useEffect(() => {
    handleGetUserDashboard()
  }, [handleGetUserDashboard])

  useEffect(
    () => () => {
      clearSnackbar()
    },
    [clearSnackbar]
  )

  return (
    <>
      {status && (
        <Snackbar
          severity={status.state}
          message={status.message!}
          onClose={clearSnackbar}
        />
      )}
      {currentUserDashboard && (
        <>
          <ContentHeader
            title={f(messages.userDashboard)}
            breadcrumbs={BREADCRUMBS.concat({
              title: messages.userDashboard,
            })}
          />
          <UserInfo userInfo={currentUserDashboard} />
          <MemberTable
            memberList={currentUserDashboard[UserDashboardKeys.Member]}
            onCreate={handleCreateMember}
            onEdit={handleEditMember}
            onDelete={handleDeleteMember}
          />
          <ActivatedDeviceTable
            deviceList={
              currentUserDashboard[UserDashboardKeys.ActivatedDevices]
            }
            onDelete={handleDeleteActivatedDevice}
          />
          <ConfigTable
            title={f(commonMessages.defaultCFG)}
            configList={currentUserDashboard[UserDashboardKeys.DefaultCFG]}
            onCreate={handleCreateDefaultCFG}
            onEdit={handleEditDefaultCFG}
            onDelete={handleDeleteDefaultCFG}
          />
          <ConfigTable
            title={f(commonMessages.denyCFG)}
            configList={currentUserDashboard[UserDashboardKeys.DenyCFG]}
            onCreate={handleCreateDenyCFG}
            onEdit={handleEditDenyCFG}
            onDelete={handleDeleteDenyCFG}
          />
        </>
      )}
    </>
  )
}
