import { JSON_CONTENT } from '@src/utils/ajaxUtils'
import authAjax from '@manager/utils/ajaxUtils'
import { objToQueryStr } from '@src/utils/function'
import {
  MemberDetail,
  MemberPayload,
  UpdateMemberPayload,
} from '@manager/models/userDashboard'

const MEMBER_API_PATH = '/user/member'

export const getMemberAjax = (payload: MemberPayload) =>
  authAjax.get(
    `${MEMBER_API_PATH}/detail` + objToQueryStr(payload),
    JSON_CONTENT
  )

export const createMemberAjax = ({
  id,
  data,
}: {
  id: string
  data: MemberDetail
}) => authAjax.post(`${MEMBER_API_PATH}/${id}`, data, JSON_CONTENT)

export const updateMemberAjax = ({ id, email, data }: UpdateMemberPayload) =>
  authAjax.put(`${MEMBER_API_PATH}/${id}/${email}`, data, JSON_CONTENT)

export const deleteMemberAjax = (payload: MemberPayload) =>
  authAjax.delete(
    `${MEMBER_API_PATH}/delete` + objToQueryStr(payload),
    JSON_CONTENT
  )
