import { FormHelperText } from '@mui/material'
import React from 'react'
import { Controller, UseControllerProps } from 'react-hook-form'

interface FieldControllerProps<T> extends UseControllerProps<T> {
  children: JSX.Element
}

export default function FieldController<T>({
  children,
  rules,
  ...rest
}: FieldControllerProps<T>) {
  return (
    <Controller
      rules={rules}
      {...rest}
      render={({
        field: { value, ...field },
        fieldState: { invalid, error },
      }) => {
        return (
          <div>
            {React.cloneElement(children, {
              ...field,
              value: value === undefined ? '' : value,
              error: invalid,
              ...children.props,
            })}
            {error && (
              <FormHelperText error sx={{ paddingLeft: 2, paddingTop: 0.5 }}>
                {error.message}
              </FormHelperText>
            )}
          </div>
        )
      }}
    />
  )
}
