export enum ConfigKeys {
  Id = 'ID',
  UserId = 'UserID',
  ConfigName = 'ConfigName',
  CreateTime = 'CreateTime',
  Description = 'Description',
  DownloadLink = 'DownLink',
}

export interface Config {
  [ConfigKeys.Id]: string
  [ConfigKeys.UserId]: string
  [ConfigKeys.ConfigName]: string
  [ConfigKeys.CreateTime]: string
  [ConfigKeys.Description]: string
  [ConfigKeys.DownloadLink]: string
}
