import React, { useCallback } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { usePagination, useSearchParams } from '@src/hooks'
import { Button } from '@mui/material'
import { toUserRoute, userPath } from '@common/appConfig'
import ContentHeader from '@components/ContentHeader'
import Pagination from '@components/Pagination'
import ListTable from '@components/ListTable'
import { DeviceKeys } from '@src/user/models/device'
import { GroupKeys } from '@src/user/models/group'
import { toDateTime } from '@src/utils/function'
import Battery from './components/Battery'
import ConnectReady from './components/ConnectReady'
import { ReactComponent as IconEnroll } from '@src/assets/icons/iconEnroll.svg'
import commonMessages from '@common/messages'
import messages from './messages'
import { BREADCRUMBS } from './utils'
import SearchBlock from './components/SearchBlock'
import { mockDeviceList } from '@src/mackData/user/device'
import { mockGroupList } from '@src/mackData/user/group'

export default function DeviceList() {
  const { formatMessage: f } = useIntl()
  const navigate = useNavigate()
  const { id } = useParams()
  const groupOptions = mockGroupList.items.map((item) => ({
    label: item[GroupKeys.Id],
    value: item[GroupKeys.Id],
  }))
  const { searchParams, handleParams } = useSearchParams(DeviceKeys)
  const {
    pagination: { count, page },
    handlePageChange,
  } = usePagination({
    count: mockDeviceList.count,
  })

  const handleSearch = useCallback(
    (searchParams: object) => handleParams({ ...searchParams }),
    [handleParams]
  )

  const handleResetSearch = () =>
    navigate(generatePath(toUserRoute(userPath.device.list), { id }))

  const handleRowClick = (device: string) =>
    navigate(generatePath(toUserRoute(userPath.device.detail), { id, device }))

  const theadList = [
    {
      id: DeviceKeys.SN,
      label: f(commonMessages.deviceSN),
    },
    {
      id: DeviceKeys.DeviceType,
      label: f(commonMessages.deviceType),
    },
    {
      id: DeviceKeys.ConnectReady,
      label: f(messages.connectReady),
      formatter: (isReady: boolean) => <ConnectReady isReady={isReady} />,
    },
    {
      id: DeviceKeys.BatteryHMD,
      label: f(messages.batteryHMD),
      formatter: (battery: number) => <Battery battery={battery} />,
    },
    {
      id: DeviceKeys.BatteryCL,
      label: f(messages.batteryCL),
      formatter: (battery: number) => <Battery battery={battery} />,
    },
    {
      id: DeviceKeys.BatteryCR,
      label: f(messages.batteryCR),
      formatter: (battery: number) => <Battery battery={battery} />,
    },
    {
      id: DeviceKeys.Status,
      label: f(commonMessages.status),
    },
    {
      id: DeviceKeys.LastTask,
      label: f(messages.lastTask),
    },
    {
      id: DeviceKeys.LastTaskTime,
      label: f(messages.lastTaskTime),
      formatter: (date: string) => toDateTime(date),
    },
  ]

  return (
    <>
      <ContentHeader
        title={f(messages.deviceList)}
        breadcrumbs={BREADCRUMBS.concat({
          title: messages.deviceList,
        })}
        buttonList={[
          <Button
            variant="contained"
            startIcon={<IconEnroll />}
            onClick={() =>
              navigate(
                generatePath(toUserRoute(userPath.device.enroll), { id })
              )
            }>
            {f(messages.enroll)}
          </Button>,
        ]}
      />
      <SearchBlock
        groupOptions={groupOptions}
        defaultValue={searchParams}
        onSubmit={handleSearch}
        onReset={handleResetSearch}
      />
      <ListTable
        theadList={theadList}
        dataList={mockDeviceList.items}
        rowIdKey={DeviceKeys.SN}
        onRowClick={handleRowClick}
      />
      <Pagination count={count} page={page} onChange={handlePageChange} />
    </>
  )
}
