import { defineMessages } from 'react-intl'

const PREFIX = 'user.group.'

export default defineMessages({
  groupList: {
    id: PREFIX + 'group_list',
    defaultMessage: 'Group List',
  },
  groupEdit: {
    id: PREFIX + 'group_edit',
    defaultMessage: 'Group Edit',
  },
  groupCreate: {
    id: PREFIX + 'group_create',
    defaultMessage: 'Group Create',
  },
  toDeviceList: {
    id: PREFIX + 'toDeviceList',
    defaultMessage: 'Devices',
  },
  groupId: {
    id: PREFIX + 'group_id',
    defaultMessage: 'Group Id',
  },
  groupName: {
    id: PREFIX + 'group_name',
    defaultMessage: 'Group Name',
  },
  configId: {
    id: PREFIX + 'config_id',
    defaultMessage: 'Config',
  },
  mapId: {
    id: PREFIX + 'map_id',
    defaultMessage: 'Map',
  },
  romVersion: {
    id: PREFIX + 'rom_version',
    defaultMessage: 'Rom Version',
  },
  userId: {
    id: PREFIX + 'user_id',
    defaultMessage: 'User Id',
  },
  confirmDeleteGroup: {
    id: PREFIX + 'confirm_delete_group',
    defaultMessage: 'Would you want to delete this group?',
  },
})
