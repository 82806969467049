import { useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { objToQueryStr } from '@src/utils/function'

const strToPrimitive = (value: string) => {
  if (/^\d+$/.test(value)) return Number(value)
  if (value === 'true') return true
  if (value === 'false') return false
  if (/,/.test(value)) return value.split(',')
  return value
}

export default function useSearchParams(searchKey: object) {
  const navigate = useNavigate()
  const location = useLocation()

  const searchParams = useMemo(() => {
    const query = new URLSearchParams(location.search)
    const searchParams: { [key: string]: any } = Object.values(
      searchKey
    ).reduce((acc, cur) => {
      const newParams = query.get(cur)
      if (newParams) acc[cur] = strToPrimitive(newParams)
      return acc
    }, {} as { [key: string]: any })
    return searchParams
  }, [searchKey, location])

  const handleParams = (value: Object) => {
    const query = objToQueryStr(value)
    if (query === location.search) {
      navigate(location.pathname + query)
      return
    }
    navigate(location.pathname + query)
  }
  return { searchParams, handleParams, location }
}
