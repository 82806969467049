import React from 'react'
import { useIntl } from 'react-intl'
import { ConfigDetail, DashboardKeys } from '@user/models/dashboard'
import { StatusCapsule } from '@components/Capsule'
import ListTable from '@src/components/ListTable'
import SubTitle from './SubTitle'
import commonMessages from '@common/messages'
import messages from '../messages'

export default function ConfigTable({
  title,
  configList,
}: {
  title: string
  configList: ConfigDetail[]
}) {
  const { formatMessage: f } = useIntl()

  return (
    <>
      <SubTitle title={title} />
      <ListTable
        noMarginTop
        dataList={configList}
        theadList={[
          {
            id: DashboardKeys.Code,
            label: f(messages.cfgCode),
          },
          {
            id: DashboardKeys.Active,
            label: f(commonMessages.status),
            formatter: (isActivated: boolean) => (
              <StatusCapsule isActivated={isActivated} />
            ),
          },
          {
            id: DashboardKeys.Description,
            label: f(commonMessages.description),
          },
        ]}
      />
    </>
  )
}
