import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import { useGlobalSnackbar, useLoading } from '@src/hooks'
import { Button } from '@mui/material'
import ContentHeader from '@src/components/ContentHeader'
import { ConfigDetail } from '@src/manager/models/userDashboard'
import {
  managerPath,
  RouteSection,
  toManagerRoute,
} from '@src/common/appConfig'
import commonMessages from '@common/messages'
import messages from '@manager/utils/messages'
import { ReactComponent as IconSave } from '@src/assets/icons/iconSave.svg'
import ConfigEditForm from './components/ConfigEditForm'
import { BREADCRUMBS } from './utils'
import {
  createDefaultConfigAction,
  UserConfigActionType,
} from '@src/manager/reducers/userConfig/userConfigActions'
import Snackbar, { SnackbarState } from '@src/components/Snackbar'

export default function DefaultConfigCreate() {
  const { formatMessage: f } = useIntl()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const { control, handleSubmit } = useForm<ConfigDetail>({
    defaultValues: {},
  })

  const { status, setSnackbar, clearSnackbar } = useGlobalSnackbar({
    page: RouteSection.UserDashboard,
  })
  const { openSpinner } = useLoading({
    endActions: {
      success: [UserConfigActionType.CREATE_DEFAULT_CONFIG_SUCCESS],
      error: [UserConfigActionType.CREATE_DEFAULT_CONFIG_ERROR],
    },
    cbk: {
      success: () => {
        setSnackbar({
          state: SnackbarState.Success,
          message: f(commonMessages.createSuccess),
          notKeepData: false,
        })
        document.documentElement.scrollTop = 0
        navigate(
          generatePath(toManagerRoute(managerPath.userDashboard.dashboard), {
            id,
          })
        )
      },
      error: () => {
        setSnackbar({
          state: SnackbarState.Error,
          message: f(commonMessages.createFailed),
          notKeepData: true,
        })
        document.documentElement.scrollTop = 0
      },
    },
  })

  const onSubmit = (data: ConfigDetail) => {
    openSpinner()
    dispatch(createDefaultConfigAction({ id: id!, data }))
  }

  return (
    <>
      {status && (
        <Snackbar
          severity={SnackbarState.Error}
          message={status.message!}
          onClose={clearSnackbar}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <ContentHeader
          title={f(messages.defaultCFGCreate)}
          breadcrumbs={[
            ...BREADCRUMBS,
            {
              title: messages.userDashboard,
              route: generatePath(
                toManagerRoute(managerPath.userDashboard.dashboard),
                { id }
              ),
            },
            {
              title: messages.defaultCFGCreate,
            },
          ]}
          buttonList={[
            <Button variant="contained" type="submit" startIcon={<IconSave />}>
              {f(commonMessages.save)}
            </Button>,
          ]}
        />
        <ConfigEditForm control={control} />
      </form>
    </>
  )
}
