import React from 'react'
import { Grid } from '@mui/material'

export interface DataSet {
  label: string | JSX.Element
  content?: string | JSX.Element
}

export default function DataTableRow({ label, content }: DataSet) {
  return (
    <Grid
      item
      container
      sx={{
        borderBottom: '1px solid',
        borderColor: 'grey.200',
        overflowWrap: 'anywhere',
        '&:last-child': {
          border: 'none',
        },
      }}>
      <Grid
        item
        xs={4}
        alignItems="center"
        sx={{
          bgcolor: 'grey.50',
          paddingY: 1,
          paddingLeft: 4,
          whiteSpace: 'pre',
          alignItems: 'center',
          fontWeight: 'fontWeightBold',
          borderRight: '1px solid',
          borderColor: 'grey.200',
        }}>
        {label}
      </Grid>
      <Grid
        item
        xs={8}
        sx={{
          paddingY: 1,
          paddingLeft: 4,
          whiteSpace: 'pre',
          alignItems: 'center',
          flexGrow: 1,
          flexBasis: 1,
        }}>
        {content}
      </Grid>
    </Grid>
  )
}
