import { ManagerDetail } from '@src/admin/models/manager'

export enum ManagerActionType {
  GET_LIST = '@Manager/GET_LIST',
  GET_LIST_SUCCESS = '@Manager/GET_LIST_SUCCESS',
  GET_LIST_ERROR = '@Manager/GET_LIST_ERROR',

  START_POLLING_LIST = '@Manager/START_POLLING_LIST',
  STOP_POLLING_LIST = '@Manager/STOP_POLLING_LIST',

  CREATE_MANAGER = '@Manager/CREATE_MANAGER',
  CREATE_MANAGER_SUCCESS = '@Manager/CREATE_MANAGER_SUCCESS',
  CREATE_MANAGER_ERROR = '@Manager/CREATE_MANAGER_ERROR',

  GET_MANAGER = '@Manager/GET_MANAGER',
  GET_MANAGER_SUCCESS = '@Manager/GET_MANAGER_SUCCESS',
  GET_MANAGER_ERROR = '@Manager/GET_MANAGER_ERROR',

  START_POLLING_MANAGER = '@Manager/START_POLLING_MANAGER',
  STOP_POLLING_MANAGER = '@Manager/STOP_POLLING_MANAGER',

  UPDATE_MANAGER = '@Manager/UPDATE_MANAGER',
  UPDATE_MANAGER_SUCCESS = '@Manager/UPDATE_MANAGER_SUCCESS',
  UPDATE_MANAGER_ERROR = '@Manager/UPDATE_MANAGER_ERROR',

  DELETE_MANAGER = '@Manager/DELETE_MANAGER',
  DELETE_MANAGER_SUCCESS = '@Manager/DELETE_MANAGER_SUCCESS',
  DELETE_MANAGER_ERROR = '@Manager/DELETE_MANAGER_ERROR',

  RESET_MANAGER = '@Manager/RESET_MANAGER',
}

export interface ListParams {
  Count: number
  Items: ManagerDetail[]
}

export const getListAction = (payload: Object) => ({
  type: ManagerActionType.GET_LIST,
  payload,
})

export const getListSuccessAction = (payload: Object) => ({
  type: ManagerActionType.GET_LIST_SUCCESS,
  payload,
})

export const startPollingListAction = (payload: Object) => ({
  type: ManagerActionType.START_POLLING_LIST,
  payload,
})

export const stopPollingListAction = () => ({
  type: ManagerActionType.STOP_POLLING_LIST,
})

export const createManagerAction = (payload: Partial<ManagerDetail>) => ({
  type: ManagerActionType.CREATE_MANAGER,
  payload,
})

export const createManagerSuccessAction = () => ({
  type: ManagerActionType.CREATE_MANAGER_SUCCESS,
})

export const createManagerFailedAction = () => ({
  type: ManagerActionType.CREATE_MANAGER_ERROR,
})

export const getManagerAction = (payload: string) => ({
  type: ManagerActionType.GET_MANAGER,
  payload,
})

export const getManagerSuccessAction = (payload: Partial<ManagerDetail>) => ({
  type: ManagerActionType.GET_MANAGER_SUCCESS,
  payload,
})

export const startPollingManagerAction = (payload: string) => ({
  type: ManagerActionType.START_POLLING_MANAGER,
  payload,
})

export const stopPollingManagerAction = () => ({
  type: ManagerActionType.STOP_POLLING_MANAGER,
})

export const updateManagerAction = (payload: {
  id: string
  data: Partial<ManagerDetail>
}) => ({
  type: ManagerActionType.UPDATE_MANAGER,
  payload,
})

export const updateManagerSuccessAction = () => ({
  type: ManagerActionType.UPDATE_MANAGER_SUCCESS,
})

export const updateManagerFailedAction = () => ({
  type: ManagerActionType.UPDATE_MANAGER_ERROR,
})

export const deleteManagerAction = (payload: string) => ({
  type: ManagerActionType.DELETE_MANAGER,
  payload,
})

export const deleteManagerSuccessAction = () => ({
  type: ManagerActionType.DELETE_MANAGER_SUCCESS,
})

export const deleteManagerErrorAction = () => ({
  type: ManagerActionType.DELETE_MANAGER_ERROR,
})

export const resetManagerErrorAction = () => ({
  type: ManagerActionType.RESET_MANAGER,
})
