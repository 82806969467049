import { defineMessages } from 'react-intl'

const PREFIX = 'user.device.'

export default defineMessages({
  deviceList: {
    id: PREFIX + 'device_list',
    defaultMessage: 'Device List',
  },
  deviceDetail: {
    id: PREFIX + 'device_detail',
    defaultMessage: 'Device Detail',
  },
  deviceEnroll: {
    id: PREFIX + 'device_enroll',
    defaultMessage: 'Device Enroll',
  },
  enroll: {
    id: PREFIX + 'enroll_device',
    defaultMessage: 'Enroll',
  },
  enrollTime: {
    id: PREFIX + 'enroll_time',
    defaultMessage: 'Enroll Time',
  },
  connectReady: {
    id: PREFIX + 'connect_ready',
    defaultMessage: 'Connect Ready',
  },
  lastTask: {
    id: PREFIX + 'last_task',
    defaultMessage: 'Last Task',
  },
  lastTaskTime: {
    id: PREFIX + 'last_task_time',
    defaultMessage: 'Last Task Time',
  },
  batteryHMD: {
    id: PREFIX + 'battery_hmd',
    defaultMessage: 'Battery HMD',
  },
  batteryCL: {
    id: PREFIX + 'battery_cl',
    defaultMessage: 'Battery CL',
  },
  batteryCR: {
    id: PREFIX + 'battery_cr',
    defaultMessage: 'Battery CR',
  },
  groupId: {
    id: PREFIX + 'group_id',
    defaultMessage: 'Group Id',
  },
  userId: {
    id: PREFIX + 'user_id',
    defaultMessage: 'User Id',
  },
  url: {
    id: PREFIX + 'url',
    defaultMessage: 'URL',
  },
  deviceName: {
    id: PREFIX + 'device_name',
    defaultMessage: 'Device Name',
  },
  wifiSetting: {
    id: PREFIX + 'wifi_setting',
    defaultMessage: 'Wifi Setting',
  },
  ssdId: {
    id: PREFIX + 'ssd_id',
    defaultMessage: 'SSD Id',
  },
  wifiType: {
    id: PREFIX + 'wifi_type',
    defaultMessage: 'Type',
  },
  password: {
    id: PREFIX + 'password',
    defaultMessage: 'Password',
  },
  eapMethod: {
    id: PREFIX + 'eap_method',
    defaultMessage: 'EAP Method',
  },
  phase2Method: {
    id: PREFIX + 'phase2_method',
    defaultMessage: 'Phase2 Method',
  },
  domain: {
    id: PREFIX + 'domain',
    defaultMessage: 'Domain',
  },
  cert: {
    id: PREFIX + 'cert',
    defaultMessage: 'Cert',
  },
  clientCert: {
    id: PREFIX + 'client_cert',
    defaultMessage: 'Client Cert',
  },
  identify: {
    id: PREFIX + 'identify',
    defaultMessage: 'Identify',
  },
  anonymousIdentify: {
    id: PREFIX + 'anonymous_identify',
    defaultMessage: 'Anonymous Identify',
  },
  autoIncrease: {
    id: PREFIX + 'auto_increase',
    defaultMessage: 'Auto Increase',
  },
  generateQRCode: {
    id: PREFIX + 'generate_qr_code',
    defaultMessage: 'Generate QR Code',
  },
})
