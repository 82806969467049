import { JSON_CONTENT } from '@src/utils/ajaxUtils'
import authAjax from '@admin/utils/ajaxUtils'

const API_PATH = '/admin'

export const getProfileAjax = () =>
  authAjax.get(`${API_PATH}/profile`, JSON_CONTENT)

export const logoutAjax = () =>
  authAjax.post(`${API_PATH}/logout`, undefined, JSON_CONTENT)
