export enum GroupKeys {
  Id = 'ID',
  UserId = 'UserID',
  GroupName = 'GroupName',
  Description = 'Description',
  ConfigId = 'ConfigID',
  MapId = 'MapID',
  RomVersion = 'RomVersion',

  ConfigName = 'ConfigName',
  MapName = 'MapName',
  VersionName = 'VersionName',
}

export interface Group {
  [GroupKeys.UserId]: string
  [GroupKeys.Id]: string
  [GroupKeys.GroupName]: string
  [GroupKeys.Description]: string
  [GroupKeys.ConfigId]: string
  [GroupKeys.MapId]: string
  [GroupKeys.RomVersion]: string

  [GroupKeys.ConfigName]?: string
  [GroupKeys.MapName]?: string
  [GroupKeys.VersionName]?: string
}

export interface GroupPayload {
  id: string
  data: Partial<Group>
}
