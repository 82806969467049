export enum AccountKeys {
  Name = 'FullName',
  Id = 'ID',
  Email = 'Email',
  Active = 'Active',
}

export interface Profile {
  [AccountKeys.Id]: string
  [AccountKeys.Email]: string
  [AccountKeys.Name]: string
  [AccountKeys.Active]: boolean
}
