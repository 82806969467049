import { combineReducers } from 'redux'
import accountReducer, {
  AccountPreloadState,
  AccountState,
} from './account/accountReducer'
import deviceReducer, {
  DevicePreloadState,
  DeviceState,
} from './device/deviceReducers'
import memberReducer, {
  MemberPreloadState,
  MemberState,
} from './member/memberReducers'
import userConfigReducer, {
  UserConfigPreloadState,
  UserConfigState,
} from './userConfig/userConfigReducers'
import userDashboardReducer, {
  UserDashboardPreloadState,
  UserDashboardState,
} from './userDashboard/userDashboardReducers'
import agencyReducer, {
  AgencyPreloadState,
  AgencyState,
} from './agency/agencyReducers'

export interface StoreState {
  account: AccountState
  agency: AgencyState
  userDashboard: UserDashboardState
  userConfig: UserConfigState
  device: DeviceState
  member: MemberState
}

export const storePreloadState: StoreState = {
  account: AccountPreloadState,
  agency: AgencyPreloadState,
  userDashboard: UserDashboardPreloadState,
  userConfig: UserConfigPreloadState,
  device: DevicePreloadState,
  member: MemberPreloadState,
}

export const rootReducer = combineReducers<StoreState>({
  account: accountReducer,
  agency: agencyReducer,
  userDashboard: userDashboardReducer,
  userConfig: userConfigReducer,
  device: deviceReducer,
  member: memberReducer,
})
