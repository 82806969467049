import {
  MemberPayload,
  MemberDetail,
  UpdateMemberPayload,
} from '@src/manager/models/userDashboard'

export enum MemberActionType {
  GET_MEMBER = '@member/GET_MEMBER',
  GET_MEMBER_SUCCESS = '@member/GET_MEMBER_SUCCESS',
  GET_MEMBER_ERROR = '@member/GET_MEMBER_ERROR',

  CREATE_MEMBER = '@member/CREATE_MEMBER',
  CREATE_MEMBER_SUCCESS = '@member/CREATE_MEMBER_SUCCESS',
  CREATE_MEMBER_ERROR = '@member/CREATE_MEMBER_ERROR',

  UPDATE_MEMBER = '@member/UPDATE_MEMBER',
  UPDATE_MEMBER_SUCCESS = '@member/UPDATE_MEMBER_SUCCESS',
  UPDATE_MEMBER_ERROR = '@member/UPDATE_MEMBER_ERROR',

  DELETE_MEMBER = '@member/DELETE_MEMBER',
  DELETE_MEMBER_SUCCESS = '@member/DELETE_MEMBER_SUCCESS',
  DELETE_MEMBER_ERROR = '@member/DELETE_MEMBER_ERROR',

  RESET_MEMBER = '@member/RESET_MEMBER',
}

export const getMemberAction = (payload: MemberPayload) => ({
  type: MemberActionType.GET_MEMBER,
  payload,
})

export const getMemberSuccessAction = (payload: MemberDetail) => ({
  type: MemberActionType.GET_MEMBER_SUCCESS,
  payload,
})

export const createMemberAction = (payload: {
  id: string
  data: MemberDetail
}) => ({
  type: MemberActionType.CREATE_MEMBER,
  payload,
})

export const createMemberSuccessAction = () => ({
  type: MemberActionType.CREATE_MEMBER_SUCCESS,
})

export const createMemberFailedAction = () => ({
  type: MemberActionType.CREATE_MEMBER_ERROR,
})

export const updateMemberAction = (payload: UpdateMemberPayload) => ({
  type: MemberActionType.UPDATE_MEMBER,
  payload,
})

export const updateMemberSuccessAction = () => ({
  type: MemberActionType.UPDATE_MEMBER_SUCCESS,
})

export const updateMemberFailedAction = () => ({
  type: MemberActionType.UPDATE_MEMBER_ERROR,
})

export const deleteMemberAction = (payload: MemberPayload) => ({
  type: MemberActionType.DELETE_MEMBER,
  payload,
})

export const deleteMemberSuccessAction = () => ({
  type: MemberActionType.DELETE_MEMBER_SUCCESS,
})

export const deleteMemberErrorAction = () => ({
  type: MemberActionType.DELETE_MEMBER_ERROR,
})

export const resetMemberAction = () => ({
  type: MemberActionType.RESET_MEMBER,
})
