import { JSON_CONTENT } from '@src/utils/ajaxUtils'
import authAjax from '@admin/utils/ajaxUtils'
import { objToQueryStr } from '@src/utils/function'
import { AdminDetail } from '@src/admin/models/admin'

const API_PATH = '/admin'

export const getListAjax = (params: object) =>
  authAjax.get(`${API_PATH}/jump${objToQueryStr(params)}`, JSON_CONTENT)

export const createAdminAjax = (data: object) =>
  authAjax.post(API_PATH, data, JSON_CONTENT)

export const getAdminAjax = (id: string) =>
  authAjax.get(`${API_PATH}/${id}`, JSON_CONTENT)

export const updateAdminAjax = ({
  id,
  data,
}: {
  id: string
  data: Partial<AdminDetail>
}) => authAjax.put(`${API_PATH}/${id}`, data, JSON_CONTENT)

export const deleteAdminAjax = (id: string) =>
  authAjax.delete(`${API_PATH}/${id}`, JSON_CONTENT)
