import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import { DeviceEnroll, DeviceKeys } from '@src/user/models/device'
import { Box, Button } from '@mui/material'
import DataTable from '@src/components/DataTable'
import {
  ControlledCheckbox,
  ControlledNumberInput,
  ControlledTextInput,
} from '@src/components/hookForm'
import WifiSetting from './WifiSettingTable'
import messages from '../messages'
import {
  CHARACTER_LIMIT,
  INVALID_FORMAT,
  REQUIRED,
} from '@src/utils/validation'
import { DEFAULT_WIFI_SETTING } from '../utils'
import { ReactComponent as IconScan } from '@src/assets/icons/iconScan.svg'

export default function EnrollForm({
  deviceEnroll,
  onSubmit,
}: {
  deviceEnroll?: DeviceEnroll
  onSubmit: (value: DeviceEnroll) => void
}) {
  const { formatMessage: f } = useIntl()
  const { control, handleSubmit, watch, getValues, reset } =
    useForm<DeviceEnroll>({ defaultValues: deviceEnroll })
  const watchAutoIncrease = watch(DeviceKeys.AutoIncrease)
  const watchDeviceNameEnable = watch(DeviceKeys.DeviceNameEnable)
  const watchWifiSettingEnable = watch(DeviceKeys.WifiSettingEnable)
  const watchWifiType = watch(`${DeviceKeys.WifiSetting}.${DeviceKeys.Type}`)

  useEffect(() => {
    const name = DeviceKeys.WifiSetting
    const data = getValues()
    reset({ ...data, [name]: { ...data[name], ...DEFAULT_WIFI_SETTING } })
  }, [watchWifiType, getValues, reset])

  useEffect(() => {
    if (!watchWifiSettingEnable) {
      const data = getValues()
      reset({ ...data, [DeviceKeys.WifiSetting]: undefined })
    }
  }, [watchWifiSettingEnable, getValues, reset])

  useEffect(() => {
    reset(deviceEnroll ?? {})
  }, [deviceEnroll, reset])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DataTable
        dataSets={[
          {
            label: (
              <>
                <ControlledCheckbox
                  name={DeviceKeys.DeviceNameEnable}
                  control={control}
                  component="span"
                />
                {f(messages.deviceName)}
              </>
            ),
            content: (
              <Box
                display="flex"
                sx={{
                  filter: !watchDeviceNameEnable ? 'opacity(25%)' : undefined,
                }}>
                <ControlledTextInput
                  name={DeviceKeys.DeviceName}
                  control={control}
                  required={REQUIRED}
                  fullWidth={false}
                  disabled={!watchDeviceNameEnable}
                  rules={{
                    maxLength: {
                      value: CHARACTER_LIMIT,
                      message: INVALID_FORMAT,
                    },
                  }}
                />
                <Box ml={2} display="flex">
                  <Box
                    sx={{
                      filter: !watchAutoIncrease ? 'opacity(25%)' : undefined,
                    }}>
                    <ControlledNumberInput
                      name={DeviceKeys.Serial}
                      control={control}
                      required={watchAutoIncrease ? REQUIRED : undefined}
                      fullWidth={false}
                      disabled={!watchDeviceNameEnable || !watchAutoIncrease}
                      short
                      rules={{
                        min: {
                          value: 0,
                          message: INVALID_FORMAT,
                        },
                      }}
                    />
                  </Box>
                  <ControlledCheckbox
                    name={DeviceKeys.AutoIncrease}
                    control={control}
                    label={f(messages.autoIncrease)}
                    disabled={!watchDeviceNameEnable}
                    component="span"
                  />
                </Box>
              </Box>
            ),
          },
          {
            label: (
              <>
                <ControlledCheckbox
                  name={DeviceKeys.WifiSettingEnable}
                  control={control}
                  component="span"
                />
                {f(messages.wifiSetting)}
              </>
            ),
            content: (
              <WifiSetting
                control={control}
                disabled={!watchWifiSettingEnable}
              />
            ),
          },
        ]}
      />
      <Box mt={1} display="flex" justifyContent="center">
        <Button variant="contained" type="submit" startIcon={<IconScan />}>
          {f(messages.generateQRCode)}
        </Button>
      </Box>
    </form>
  )
}
