export enum DashboardKeys {
  Id = 'ID',
  Email = 'Email',
  Name = 'FullName',
  Active = 'Active',
  Description = 'Description',
  MaxDeviceCount = 'MaxDeviceCount',
  TokenLifeTime = 'TokenLifeTime',
  DefaultCFG = 'DefaultCFG',
  DenyCFG = 'DenyCFG',
  ActivatedDevices = 'ActivatedDevices',
  Member = 'Member',

  Code = 'Code',

  Date = 'Date',
  SN = 'SN',
  DeviceType = 'DeviceType',

  RegisteredDeviceCount = 'registeredDeviceCount',
}

export interface UserInfo {
  [DashboardKeys.Id]: string
  [DashboardKeys.Name]: string
  [DashboardKeys.Active]: boolean
  [DashboardKeys.Description]: string
  [DashboardKeys.MaxDeviceCount]: number
  [DashboardKeys.TokenLifeTime]: number

  [DashboardKeys.RegisteredDeviceCount]?: number
}

export interface MemberDetail {
  [DashboardKeys.Name]: string
  [DashboardKeys.Email]: string
  [DashboardKeys.Active]: boolean
  [DashboardKeys.Description]: string
}

export interface ConfigDetail {
  [DashboardKeys.Id]: string
  [DashboardKeys.Code]: string
  [DashboardKeys.Active]: boolean
  [DashboardKeys.Description]: string
}

export interface ActivatedDeviceDetail {
  [DashboardKeys.Id]: string
  [DashboardKeys.Date]: string
  [DashboardKeys.SN]: string
  [DashboardKeys.DeviceType]?: string
}

export interface Dashboard extends UserInfo {
  [DashboardKeys.DefaultCFG]: ConfigDetail[]
  [DashboardKeys.DenyCFG]: ConfigDetail[]
  [DashboardKeys.ActivatedDevices]: ActivatedDeviceDetail[]
  [DashboardKeys.Member]: MemberDetail[]
}

export interface MemberPayload {
  id: string
  email: string
}

export interface UpdateMemberPayload {
  id: string
  email: string
  data: MemberDetail
}
