import { MessageDescriptor } from 'react-intl'
import { Profile } from '@manager/models/account'
import { setAuthHeader } from '@manager/utils/ajaxUtils'
import { StorageKey } from '@manager/utils/constants'
import { AuthDialog } from '@manager/containers/Layout'
import { ActionType } from '@src/utils/type'
import { AccountActionType } from './accountActions'
import GlobalSpinner from '@src/components/GlobalSpinner'
import { MSAL_MANAGER_LOGOUT } from '@src/common/constants'

export interface AccountState {
  token?: string
  profile?: Profile
}

const initState: AccountState = {}

const cacheToken = localStorage.getItem(StorageKey.Token) ?? undefined
const cacheProfile = () => {
  const profileStr = localStorage.getItem(StorageKey.Profile)
  return profileStr ? JSON.parse(profileStr) : undefined
}

export const AccountPreloadState: AccountState = {
  ...initState,
  token: cacheToken,
  profile: cacheProfile(),
}

const logoutHandler = () => {
  localStorage.removeItem(StorageKey.Token)
  localStorage.removeItem(StorageKey.Profile)
  GlobalSpinner.close()
  window.location.assign(MSAL_MANAGER_LOGOUT)

  return initState
}

const handler: Record<
  string,
  (state: AccountState, action: ActionType<any>) => AccountState
> = {
  [AccountActionType.LOGIN]: (
    state: AccountState = initState,
    action: ActionType<string>
  ) => {
    const token = action.payload
    localStorage.setItem(StorageKey.Token, token)
    setAuthHeader(token)

    return {
      ...state,
      token,
    }
  },
  [AccountActionType.GET_PROFILE_SUCCESS]: (
    state: AccountState = initState,
    action: ActionType<Profile>
  ) => {
    const profile = action.payload
    localStorage.setItem(StorageKey.Profile, JSON.stringify(profile))

    return {
      ...state,
      profile,
    }
  },
  [AccountActionType.LOGOUT_SUCCESS]: logoutHandler,
  [AccountActionType.LOGOUT_ERROR]: logoutHandler,
  [AccountActionType.UNAUTHORIZED]: (
    state: AccountState = initState,
    action: ActionType<MessageDescriptor>
  ) => {
    AuthDialog.open({
      content: action.payload,
    })
    return state
  },
}

export default function accountReducer(
  state: AccountState = initState,
  action: ActionType<any>
) {
  if (handler.hasOwnProperty(action.type)) {
    return handler[action.type](state, action)
  }
  return state
}
