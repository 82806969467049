import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { StoreState } from '@src/user/reducers'
import {
  getListAction,
  AgencyActionType,
} from '@src/user/reducers/agency/agencyActions'
import { AgencyKeys } from '@user/models/agency'
import { useGlobalSnackbar, useLoading, usePagination } from '@src/hooks'
import Snackbar, { SnackbarState } from '@src/components/Snackbar'
import ContentHeader from '@components/ContentHeader'
import Pagination from '@components/Pagination'
import ListTable from '@components/ListTable'
import { StatusCapsule } from '@components/Capsule'
import commonMessages from '@common/messages'
import messages from '@user/utils/messages'
import { RouteSection, toUserRoute, userPath } from '@common/appConfig'
import { ErrorResponse } from '@src/utils/type'

export default function AgencyList() {
  const { formatMessage: f } = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { agencyList, agencyTotal } = useSelector(
    (state: StoreState) => state.agency
  )
  const { status, setSnackbar, clearSnackbar } = useGlobalSnackbar({
    page: RouteSection.Agency,
  })
  const {
    query,
    pagination: { count, page },
    handlePageChange,
  } = usePagination({
    count: agencyTotal,
  })
  const { openSpinner } = useLoading({
    endActions: {
      success: [AgencyActionType.GET_LIST_SUCCESS],
      error: [AgencyActionType.GET_LIST_ERROR],
    },
    cbk: {
      error: ({ error }: { error: ErrorResponse }) => {
        setSnackbar({
          state: SnackbarState.Error,
          message: error.message ?? f(commonMessages.deleteFailed),
          notKeepData: true,
        })
        document.documentElement.scrollTop = 0
      },
    },
  })

  const handleRowClick = (id: string) =>
    navigate(generatePath(toUserRoute(userPath.dashboard.dashboard), { id }))

  const theadList = [
    {
      id: AgencyKeys.Name,
      label: f(commonMessages.name),
    },
    {
      id: AgencyKeys.MaxDeviceCount,
      label: f(commonMessages.maxDeviceCount),
    },
    {
      id: AgencyKeys.Active,
      label: f(commonMessages.status),
      formatter: (isActivated: boolean) => (
        <StatusCapsule isActivated={isActivated} />
      ),
    },
    {
      id: AgencyKeys.Description,
      label: f(commonMessages.description),
    },
  ]

  useEffect(() => {
    openSpinner()
    dispatch(getListAction({ ...query }))
  }, [dispatch, query, openSpinner])

  return (
    <>
      {status && (
        <Snackbar
          severity={status.state!}
          message={status.message!}
          onClose={clearSnackbar}
        />
      )}
      <ContentHeader title={f(messages.agency)} />
      <ListTable
        theadList={theadList}
        dataList={agencyList}
        rowIdKey={AgencyKeys.Id}
        onRowClick={handleRowClick}
      />
      <Pagination count={count} page={page} onChange={handlePageChange} />
    </>
  )
}
