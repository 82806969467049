import { createSelector } from 'reselect'
import { AccountKeys, Profile } from '@src/user/models/account'
import { StoreState } from '../'

export const accountSelectors = createSelector(
  [(state: StoreState) => state.account.profile],
  (storeProfile?: Profile) => {
    const profile = storeProfile && {
      ...storeProfile,
      [AccountKeys.Name]: storeProfile[AccountKeys.Email].split('@')[0],
    }

    return {
      profile,
    }
  }
)
