import { Breadcrumb } from '@components/ContentHeader'
import messages from '@manager/utils/messages'
import { managerPath, toManagerRoute } from '@src/common/appConfig'

export const BREADCRUMBS: Breadcrumb[] = [
  {
    title: messages.agency,
    route: toManagerRoute(managerPath.agencyList.list),
  },
]
