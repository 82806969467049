import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { ReactComponent as IconEdit } from '@src/assets/icons/iconEditGrey.svg'
import { ReactComponent as IconAdd } from '@src/assets/icons/iconAddGrey.svg'

interface Props {
  title: string
  onCreate?: () => void
  onEdit?: () => void
}

export default function SubTitle({ title, onEdit, onCreate }: Props) {
  return (
    <Grid
      container
      display="flex"
      justifyContent="space-between"
      sx={{ marginTop: 3, marginBottom: 2 }}>
      <Grid item>
        <Typography component="div" variant="h6">
          {title}
        </Typography>
      </Grid>
      <Grid item display="flex" sx={{ paddingRight: 2 }}>
        {onEdit && (
          <Box
            sx={{
              cursor: 'pointer',
              '&>svg': { width: 20, height: 20 },
            }}
            onClick={onEdit}>
            <IconEdit />
          </Box>
        )}
        {onCreate && (
          <Box
            sx={{
              cursor: 'pointer',
              '&>svg': { width: 20, height: 20 },
            }}
            onClick={onCreate}>
            <IconAdd />
          </Box>
        )}
      </Grid>
    </Grid>
  )
}
