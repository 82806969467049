import { ActionType } from '@src/utils/type'

export interface DeviceState {}

const initState: DeviceState = {}

export const DevicePreloadState = initState

const handler: Record<
  string,
  (state: DeviceState, action: ActionType<any>) => DeviceState
> = {}

export default function deviceReducer(
  state: DeviceState = initState,
  action: ActionType<any>
) {
  if (handler.hasOwnProperty(action.type)) {
    return handler[action.type](state, action)
  }
  return state
}
