import { AnyAction } from 'redux'
import { ofType } from 'redux-observable'
import { empty, exhaustMap, map, Observable, tap } from 'rxjs'
import { errorSubject, successSubject } from '@src/utils/responseSubject'
import { catchErrorHandler } from '@manager/utils/ajaxUtils'
import * as deviceServices from './deviceServices'
import {
  deleteActivatedDeviceSuccessAction,
  DeviceActionType,
} from '@src/manager/reducers/device/deviceActions'

const deleteDevice = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(DeviceActionType.DELETE_ACTIVATED_DEVICES),
    exhaustMap(({ payload }) =>
      deviceServices.deleteActivatedDevicesAjax(payload).pipe(
        map((res) => deleteActivatedDeviceSuccessAction()),
        tap(() =>
          successSubject.next({
            type: DeviceActionType.DELETE_ACTIVATED_DEVICES_SUCCESS,
          })
        ),
        catchErrorHandler((error) => {
          errorSubject.next({
            type: DeviceActionType.DELETE_ACTIVATED_DEVICES_ERROR,
            error: error.response?.data,
          })
          return empty()
        })
      )
    )
  )

const deviceEpics = [deleteDevice]

export default deviceEpics
