import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import { AjaxError } from 'rxjs/ajax'
import { Button } from '@mui/material'
import { StoreState } from '@src/manager/reducers'
import {
  getDenyConfigAction,
  updateDenyConfigAction,
  UserConfigActionType,
} from '@src/manager/reducers/userConfig/userConfigActions'
import { useGlobalSnackbar, useLoading } from '@src/hooks'
import Snackbar, { SnackbarState } from '@src/components/Snackbar'
import ContentHeader from '@src/components/ContentHeader'
import { ConfigDetail } from '@src/manager/models/userDashboard'
import {
  managerPath,
  RouteSection,
  toManagerRoute,
} from '@src/common/appConfig'
import commonMessages from '@common/messages'
import messages from '@manager/utils/messages'
import { ReactComponent as IconSave } from '@src/assets/icons/iconSave.svg'
import ConfigEditForm from './components/ConfigEditForm'
import { BREADCRUMBS } from './utils'

export default function DenyConfigEdit() {
  const { formatMessage: f } = useIntl()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { currentDenyConfig } = useSelector(
    (state: StoreState) => state.userConfig
  )
  const { id, denycfg } = useParams()
  const { control, handleSubmit, reset } = useForm<ConfigDetail>({
    defaultValues: currentDenyConfig,
  })

  const { status, setSnackbar, clearSnackbar } = useGlobalSnackbar({
    page: RouteSection.UserDashboard,
  })
  const { openSpinner } = useLoading({
    endActions: {
      success: [
        UserConfigActionType.GET_DENY_CONFIG_SUCCESS,
        UserConfigActionType.UPDATE_DENY_CONFIG_SUCCESS,
      ],
      error: [
        UserConfigActionType.GET_DENY_CONFIG_ERROR,
        UserConfigActionType.UPDATE_DENY_CONFIG_ERROR,
      ],
    },
    cbk: {
      success: ({ type }: { type: UserConfigActionType }) => {
        if (type === UserConfigActionType.UPDATE_DENY_CONFIG_SUCCESS) {
          setSnackbar({
            state: SnackbarState.Success,
            message: f(commonMessages.updateSuccess),
            notKeepData: false,
          })
          document.documentElement.scrollTop = 0
          navigate(
            generatePath(toManagerRoute(managerPath.userDashboard.dashboard), {
              id,
            })
          )
        }
      },
      error: ({
        type,
        error,
      }: {
        type: UserConfigActionType
        error: AjaxError
      }) => {
        const message =
          type === UserConfigActionType.UPDATE_DENY_CONFIG_ERROR
            ? f(commonMessages.updateFailed)
            : error.message ?? f(commonMessages.unknownError)
        setSnackbar({
          state: SnackbarState.Error,
          message,
          notKeepData: true,
        })
        document.documentElement.scrollTop = 0
      },
    },
  })

  const onSubmit = (data: ConfigDetail) => {
    openSpinner()
    dispatch(updateDenyConfigAction({ id: id!, data }))
  }

  useEffect(() => {
    openSpinner()
    dispatch(getDenyConfigAction({ id: id!, denycfg: denycfg! }))
  }, [openSpinner, id, denycfg, dispatch])

  useEffect(() => {
    if (!!currentDenyConfig) {
      reset(currentDenyConfig)
    }
  }, [currentDenyConfig, reset])

  return (
    <>
      {status && (
        <Snackbar
          severity={SnackbarState.Error}
          message={status.message!}
          onClose={clearSnackbar}
        />
      )}
      {currentDenyConfig && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <ContentHeader
            title={f(messages.denyCFGEdit)}
            breadcrumbs={[
              ...BREADCRUMBS,
              {
                title: messages.userDashboard,
                route: generatePath(
                  toManagerRoute(managerPath.userDashboard.dashboard),
                  { id }
                ),
              },
              {
                title: messages.denyCFGEdit,
              },
            ]}
            buttonList={[
              <Button
                variant="contained"
                type="submit"
                startIcon={<IconSave />}>
                {f(commonMessages.save)}
              </Button>,
            ]}
          />
          <ConfigEditForm config={currentDenyConfig} control={control} />
        </form>
      )}
    </>
  )
}
