import { StorageKey } from '@user/utils/constants'
import { ActionType } from '@src/utils/type'
import { DeviceEnroll } from '@src/user/models/device'
import { DeviceActionType } from './deviceActions'

export interface DeviceState {
  deviceEnroll?: DeviceEnroll
}

const initState: DeviceState = {}

const cacheEnroll = localStorage.getItem(StorageKey.DeviceEnroll) ?? undefined

export const DevicePreloadState: DeviceState = {
  ...initState,
  deviceEnroll: cacheEnroll && JSON.parse(cacheEnroll),
}

const handler: Record<
  string,
  (state: DeviceState, action: ActionType<any>) => DeviceState
> = {
  [DeviceActionType.SET_ENROLL_INFO]: (
    state: DeviceState = initState,
    action: ActionType<DeviceEnroll>
  ) => {
    const deviceEnroll = action.payload
    localStorage.setItem(StorageKey.DeviceEnroll, JSON.stringify(deviceEnroll))

    return {
      ...state,
      deviceEnroll,
    }
  },
}

export default function DeviceReducer(
  state: DeviceState = initState,
  action: ActionType<any>
) {
  if (handler.hasOwnProperty(action.type)) {
    return handler[action.type](state, action)
  }
  return state
}
