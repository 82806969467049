import React from 'react'
import { generatePath, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import { Button } from '@mui/material'
import ContentHeader from '@components/ContentHeader'
import DataTable from '@components/DataTable'
import {
  ControlledSelect,
  ControlledTextArea,
  ControlledTextInput,
} from '@components/hookForm'
import { Group, GroupKeys } from '@user/models/group'
import {
  CHARACTER_LIMIT,
  DESCRIPTIONd_LIMIT,
  INVALID_FORMAT,
  REQUIRED,
} from '@src/utils/validation'
import { ReactComponent as IconSave } from '@src/assets/icons/iconSave.svg'
import commonMessages from '@common/messages'
import messages from './messages'
import { BREADCRUMBS } from './utils'
import { toUserRoute, userPath } from '@src/common/appConfig'
import { mockConfigList } from '@src/mackData/user/config'
import { ConfigKeys } from '@src/user/models/config'
import { mockMapList } from '@src/mackData/user/map'
import { MapKeys } from '@src/user/models/map'
import { RomKeys } from '@src/user/models/rom'
import { mockRomList } from '@src/mackData/user/rom'

export default function GroupCreate() {
  const { formatMessage: f } = useIntl()
  const { id } = useParams()
  const configOptions = mockConfigList.items.map((item) => ({
    label: item[ConfigKeys.ConfigName],
    value: item[ConfigKeys.Id],
  }))
  const mapOptions = mockMapList.items.map((item) => ({
    label: item[MapKeys.MapName],
    value: item[MapKeys.Id],
  }))
  const romOptions = mockRomList.items.map((item) => ({
    label: item[RomKeys.VersionName],
    value: item[RomKeys.Id],
  }))
  const { control, handleSubmit } = useForm<Group>()

  const onSubmit = (data: Group) => {
    console.log('payload', { id, data })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ContentHeader
        title={f(messages.groupCreate)}
        breadcrumbs={BREADCRUMBS.concat([
          {
            title: messages.groupList,
            route: generatePath(toUserRoute(userPath.group.list), { id }),
          },
          {
            title: messages.groupCreate,
          },
        ])}
        buttonList={[
          <Button variant="contained" type="submit" startIcon={<IconSave />}>
            {f(commonMessages.save)}
          </Button>,
        ]}
      />
      <DataTable
        dataSets={[
          {
            label: f(messages.groupName),
            content: (
              <ControlledTextInput
                name={GroupKeys.GroupName}
                control={control}
                required={REQUIRED}
                rules={{
                  maxLength: {
                    value: CHARACTER_LIMIT,
                    message: INVALID_FORMAT,
                  },
                }}
              />
            ),
          },
          {
            label: f(messages.configId),
            content: (
              <ControlledSelect
                name={GroupKeys.ConfigId}
                control={control}
                options={configOptions}
                withNullOptions
              />
            ),
          },
          {
            label: f(messages.mapId),
            content: (
              <ControlledSelect
                name={GroupKeys.MapId}
                options={mapOptions}
                control={control}
                withNullOptions
              />
            ),
          },
          {
            label: f(messages.romVersion),
            content: (
              <ControlledSelect
                name={GroupKeys.RomVersion}
                options={romOptions}
                control={control}
                withNullOptions
              />
            ),
          },
          {
            label: f(commonMessages.description),
            content: (
              <ControlledTextArea
                name={GroupKeys.Description}
                control={control}
                rules={{
                  maxLength: {
                    value: DESCRIPTIONd_LIMIT,
                    message: INVALID_FORMAT,
                  },
                }}
              />
            ),
          },
        ]}
      />
    </form>
  )
}
