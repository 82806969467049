import React from 'react'
import { Box } from '@mui/system'
import { disableGrey, successGreen } from '@src/common/styles'
import { useIntl } from 'react-intl'
import messages from '@src/common/messages'

interface Props {
  text?: string
  bgColor: string
  color?: string
}

export function StatusCapsule({ isActivated }: { isActivated?: boolean }) {
  const { formatMessage: f } = useIntl()
  const capsuleProps: Props = isActivated
    ? {
        text: f(messages.active),
        bgColor: successGreen,
      }
    : {
        text: f(messages.inactive),
        bgColor: disableGrey,
      }
  return <Capsule {...capsuleProps} />
}

export default function Capsule({ text, bgColor, color }: Props) {
  return (
    <Box
      display="inline-block"
      paddingX={3}
      paddingY={0.25}
      bgcolor={bgColor}
      fontSize="0.9rem"
      color={color ?? 'common.white'}
      borderRadius={6}>
      {text}
    </Box>
  )
}
