import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { StoreState } from '@admin/reducers'
import {
  AdminActionType,
  getAdminAction,
  updateAdminAction,
} from '@src/admin/reducers/admin/adminActions'
import { useGlobalSnackbar, useLoading } from '@src/hooks'
import { Button } from '@mui/material'
import ContentHeader from '@components/ContentHeader'
import DataTable from '@components/DataTable'
import { ControlledSelect, ControlledTextInput } from '@components/hookForm'
import { AdminDetail, AdminKeys } from '@admin/models/admin'
import {
  CHARACTER_LIMIT,
  emailPattern,
  INVALID_FORMAT,
  REQUIRED,
} from '@src/utils/validation'
import commonMessages from '@common/messages'
import messages from '@admin/utils/messages'
import { ReactComponent as IconSave } from '@src/assets/icons/iconSave.svg'
import Snackbar, { SnackbarState } from '@src/components/Snackbar'
import { adminPath, toAdminRoute } from '@src/common/appConfig'
import { ErrorResponse } from '@src/utils/type'
import { DETAIL_BREADCRUMBS } from './utils'

export default function AdminEdit() {
  const { formatMessage: f } = useIntl()
  const dispatch = useDispatch()
  const { currentAdmin } = useSelector((state: StoreState) => state.admin)
  const navigate = useNavigate()
  const { id } = useParams()
  const { control, handleSubmit } = useForm<AdminDetail>({
    defaultValues: currentAdmin,
  })
  const { status, setSnackbar, clearSnackbar } = useGlobalSnackbar({
    page: 'admin',
  })
  const { openSpinner } = useLoading({
    endActions: {
      success: [
        AdminActionType.GET_ADMIN_SUCCESS,
        AdminActionType.UPDATE_ADMIN_SUCCESS,
      ],
      error: [
        AdminActionType.GET_ADMIN_ERROR,
        AdminActionType.UPDATE_ADMIN_ERROR,
      ],
    },
    cbk: {
      success: ({ type }: { type: AdminActionType }) => {
        if (type === AdminActionType.UPDATE_ADMIN_SUCCESS) {
          setSnackbar({
            state: SnackbarState.Success,
            message: f(commonMessages.updateSuccess),
            notKeepData: false,
          })
          navigate(generatePath(toAdminRoute(adminPath.admin.detail), { id }))
        }
      },
      error: ({ error }: { error: ErrorResponse }) => {
        setSnackbar({
          state: SnackbarState.Error,
          message: error.message,
          notKeepData: true,
        })
        document.documentElement.scrollTop = 0
      },
    },
  })

  useEffect(() => {
    openSpinner()
    dispatch(getAdminAction(id!))
  }, [dispatch, openSpinner, id])

  const onSubmit = (data: AdminDetail) => {
    openSpinner()
    dispatch(updateAdminAction({ id: id!, data }))
  }

  return currentAdmin ? (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ContentHeader
        title={f(messages.adminEdit)}
        breadcrumbs={DETAIL_BREADCRUMBS.concat({
          title: messages.adminEdit,
        })}
        buttonList={[
          <Button variant="contained" type="submit" startIcon={<IconSave />}>
            {f(commonMessages.save)}
          </Button>,
        ]}
      />
      {status && (
        <Snackbar
          severity={status.state}
          message={status.message!}
          onClose={clearSnackbar}
        />
      )}
      <DataTable
        title={f(commonMessages.basicInfo)}
        dataSets={[
          {
            label: f(commonMessages.name),
            content: (
              <ControlledTextInput
                name={AdminKeys.Name}
                control={control}
                required={REQUIRED}
                rules={{
                  maxLength: {
                    value: CHARACTER_LIMIT,
                    message: INVALID_FORMAT,
                  },
                }}
              />
            ),
          },
          {
            label: f(commonMessages.email),
            content: (
              <ControlledTextInput
                name={AdminKeys.Email}
                control={control}
                required={REQUIRED}
                rules={{
                  pattern: emailPattern,
                }}
              />
            ),
          },
          {
            label: f(commonMessages.status),
            content: (
              <ControlledSelect
                name={AdminKeys.Active}
                control={control}
                options={[
                  { label: f(commonMessages.active), value: true },
                  { label: f(commonMessages.inactive), value: false },
                ]}
              />
            ),
          },
        ]}
      />
    </form>
  ) : null
}
