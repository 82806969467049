import { AdminDetail } from '@src/admin/models/admin'
import { ActionType } from '@src/utils/type'
import { AdminActionType, ListParams } from './adminActions'

export interface AdminState {
  adminList: AdminDetail[]
  adminTotal: number
  currentAdmin?: AdminDetail
}

const initState: AdminState = {
  adminList: [],
  adminTotal: 0,
}

export const AdminPreloadState = initState

const updateCurrentAdminHandler = (
  state: AdminState = initState,
  action: ActionType<AdminDetail | undefined>
): AdminState => {
  return {
    ...state,
    currentAdmin: action.payload,
  }
}

const handler: Record<
  string,
  (state: AdminState, action: ActionType<any>) => AdminState
> = {
  [AdminActionType.GET_LIST_SUCCESS]: (
    state: AdminState = initState,
    action: ActionType<ListParams>
  ): AdminState => {
    return {
      ...state,
      adminList: action.payload.Items,
      adminTotal: action.payload.Count,
    }
  },
  [AdminActionType.GET_ADMIN_SUCCESS]: updateCurrentAdminHandler,
  [AdminActionType.CREATE_ADMIN_SUCCESS]: updateCurrentAdminHandler,
  [AdminActionType.UPDATE_ADMIN_SUCCESS]: updateCurrentAdminHandler,
  [AdminActionType.DELETE_ADMIN_SUCCESS]: updateCurrentAdminHandler,
  [AdminActionType.RESET_ADMIN]: updateCurrentAdminHandler,
}

export default function adminReducer(
  state: AdminState = initState,
  action: ActionType<any>
) {
  if (handler.hasOwnProperty(action.type)) {
    return handler[action.type](state, action)
  }
  return state
}
