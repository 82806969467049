import React, { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { Content, Header, Sidebar } from '@components/Layout'
import { managerPath, toManagerRoute } from '@src/common/appConfig'
import ProfileMenu from './components/ProfileMenu'
import AuthDialog from './components/AuthDialog'
import { SIDEBAR_TABS, AGENCY_REGEXP } from '@src/manager/utils/constants'
import { StoreState } from '@src/manager/reducers'
import { ContentWithoutSidebar } from '@src/components/Layout/Layout'

export default function Layout() {
  const { pathname } = useLocation()
  const { isLogin } = useSelector(
    (state: StoreState) => ({
      isLogin: !!state.account.token,
    }),
    shallowEqual
  )

  const shownSidebar = useMemo(() => {
    return pathname.match(AGENCY_REGEXP)?.[0] === managerPath.agencyList.root
  }, [pathname])

  return isLogin ? (
    <>
      <Header identity="Manager">
        <ProfileMenu />
      </Header>
      <AuthDialog />
      {shownSidebar ? (
        <>
          <ContentWithoutSidebar>
            <Outlet />
          </ContentWithoutSidebar>
        </>
      ) : (
        <>
          <Sidebar tabs={SIDEBAR_TABS} />
          <Content>
            <Outlet />
          </Content>
        </>
      )}
    </>
  ) : (
    <Navigate to={toManagerRoute(managerPath.login.login)} replace />
  )
}
