import React from 'react'
import { Provider } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import configureStore from './store/configureStore'
import { userPath } from '@src/common/appConfig'
import Layout from './containers/Layout'
import { Login } from './containers/Login'
import { Dashboard } from './containers/Dashboard'
import { MemberCreate, MemberEdit } from './containers/Member'
import { Agency } from './containers/Agency'
import { DeviceList, DeviceDetail, DeviceEnroll } from './containers/Device'
import { GroupCreate, GroupEdit, GroupList } from './containers/Group'

const store = configureStore()

export default function User() {
  return (
    <Provider store={store}>
      <Routes>
        <Route path={userPath.login.login} element={<Login />} />
        <Route path="/" element={<Layout />}>
          <Route path={userPath.agencyList.list} element={<Agency />} />
          <Route path={userPath.dashboard.dashboard} element={<Dashboard />} />
          <Route path={userPath.member.create} element={<MemberCreate />} />
          <Route path={userPath.member.edit} element={<MemberEdit />} />
          <Route path={userPath.device.list} element={<DeviceList />} />
          <Route path={userPath.device.enroll} element={<DeviceEnroll />} />
          <Route path={userPath.device.detail} element={<DeviceDetail />} />
          <Route path={userPath.group.list} element={<GroupList />} />
          <Route path={userPath.group.create} element={<GroupCreate />} />
          <Route path={userPath.group.edit} element={<GroupEdit />} />
        </Route>
      </Routes>
    </Provider>
  )
}
