import { AnyAction } from 'redux'
import { ofType } from 'redux-observable'
import {
  delay,
  empty,
  exhaustMap,
  from,
  iif,
  map,
  mergeMap,
  Observable,
  of,
  repeatWhen,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs'
import {
  AdminActionType,
  createAdminFailedAction,
  createAdminSuccessAction,
  deleteAdminSuccessAction,
  getAdminSuccessAction,
  getListSuccessAction,
  stopPollingAdminAction,
  stopPollingListAction,
  updateAdminFailedAction,
  updateAdminSuccessAction,
} from '@admin/reducers/admin/adminActions'
import { errorSubject, successSubject } from '@src/utils/responseSubject'
import * as adminServices from './adminServices'
import { catchErrorHandler } from '@src/admin/utils/ajaxUtils'
import { toCamelCase } from '@src/utils/function'
import { POLLING_TIME, SUCCESS_BRIEF_CODE } from '@src/utils/ajaxUtils'
import { AjaxResponse } from 'rxjs/ajax'

const getAdminList = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(AdminActionType.GET_LIST),
    switchMap(({ payload }) =>
      adminServices.getListAjax(payload).pipe(
        map((res) => getListSuccessAction(res.response.Message)),
        tap(() =>
          successSubject.next({ type: AdminActionType.GET_LIST_SUCCESS })
        ),
        catchErrorHandler((error) => {
          errorSubject.next({
            type: AdminActionType.GET_LIST_ERROR,
            error: error.response?.data,
          })
          return empty()
        })
      )
    )
  )

const pollAdminList = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(AdminActionType.START_POLLING_LIST),
    exhaustMap((action) => {
      const payload = action.payload
      return of(action).pipe(
        mergeMap(() =>
          from(adminServices.getListAjax(payload)).pipe(
            mergeMap((res) => of(getListSuccessAction(res.response.Message))),
            repeatWhen((complete$) => complete$.pipe(delay(POLLING_TIME))),
            catchErrorHandler(
              () => of(stopPollingListAction()),
              [stopPollingListAction()]
            )
          )
        ),
        takeUntil(action$.pipe(ofType(AdminActionType.STOP_POLLING_LIST)))
      )
    })
  )

const createAdmin = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(AdminActionType.CREATE_ADMIN),
    exhaustMap(({ payload }) =>
      adminServices.createAdminAjax(toCamelCase(payload)).pipe(
        mergeMap((result: AjaxResponse<any>) =>
          iif(
            () => result?.response?.briefCode === SUCCESS_BRIEF_CODE,
            of(createAdminSuccessAction()).pipe(
              tap(() =>
                successSubject.next({
                  type: AdminActionType.CREATE_ADMIN_SUCCESS,
                })
              )
            ),
            of(createAdminFailedAction()).pipe(
              tap(() =>
                errorSubject.next({
                  type: AdminActionType.CREATE_ADMIN_ERROR,
                  error: result?.response?.message,
                })
              )
            )
          )
        ),
        catchErrorHandler((error) => {
          errorSubject.next({
            type: AdminActionType.CREATE_ADMIN_ERROR,
            error: error.response?.data,
          })
          return empty()
        })
      )
    )
  )

const getAdmin = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(AdminActionType.GET_ADMIN),
    switchMap(({ payload }) =>
      adminServices.getAdminAjax(payload).pipe(
        map((res) => getAdminSuccessAction(res.response.Message)),
        tap(() =>
          successSubject.next({ type: AdminActionType.GET_ADMIN_SUCCESS })
        ),
        catchErrorHandler((error) => {
          errorSubject.next({
            type: AdminActionType.GET_ADMIN_ERROR,
            error: error.response?.data,
          })
          return empty()
        })
      )
    )
  )

const pollAdmin = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(AdminActionType.START_POLLING_ADMIN),
    exhaustMap((action) => {
      const payload = action.payload
      return of(action).pipe(
        mergeMap(() =>
          from(adminServices.getAdminAjax(payload)).pipe(
            mergeMap((res) => of(getAdminSuccessAction(res.response.Message))),
            repeatWhen((complete$) => complete$.pipe(delay(POLLING_TIME))),
            catchErrorHandler(
              () => of(stopPollingAdminAction()),
              [stopPollingAdminAction()]
            )
          )
        ),
        takeUntil(action$.pipe(ofType(AdminActionType.STOP_POLLING_ADMIN)))
      )
    })
  )

const updateAdmin = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(AdminActionType.UPDATE_ADMIN),
    exhaustMap(({ payload }) =>
      adminServices
        .updateAdminAjax({ ...payload, data: toCamelCase(payload.data) })
        .pipe(
          mergeMap((result: AjaxResponse<any>) =>
            iif(
              () => result?.response?.briefCode === SUCCESS_BRIEF_CODE,
              of(updateAdminSuccessAction()).pipe(
                tap(() =>
                  successSubject.next({
                    type: AdminActionType.UPDATE_ADMIN_SUCCESS,
                  })
                )
              ),
              of(updateAdminFailedAction()).pipe(
                tap(() =>
                  errorSubject.next({
                    type: AdminActionType.UPDATE_ADMIN_ERROR,
                    error: result?.response?.message,
                  })
                )
              )
            )
          ),
          catchErrorHandler((error) => {
            errorSubject.next({
              type: AdminActionType.UPDATE_ADMIN_ERROR,
              error: error.response?.data,
            })
            return empty()
          })
        )
    )
  )

const deleteAdmin = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(AdminActionType.DELETE_ADMIN),
    exhaustMap(({ payload }) =>
      adminServices.deleteAdminAjax(payload).pipe(
        map((res) => deleteAdminSuccessAction()),
        tap(() =>
          successSubject.next({ type: AdminActionType.DELETE_ADMIN_SUCCESS })
        ),
        catchErrorHandler((error) => {
          errorSubject.next({
            type: AdminActionType.DELETE_ADMIN_ERROR,
            error: error.response?.data,
          })
          return empty()
        })
      )
    )
  )

const adminEpics = [
  getAdminList,
  pollAdminList,
  createAdmin,
  getAdmin,
  pollAdmin,
  updateAdmin,
  deleteAdmin,
]

export default adminEpics
