import React from 'react'
import { useIntl } from 'react-intl'
import DataTable from '@src/components/DataTable'
import commonMessages from '@common/messages'
import {
  UserInfo as UserInfoModel,
  DashboardKeys,
} from '@user/models/dashboard'
import { StatusCapsule } from '@src/components/Capsule'
import SubTitle from './SubTitle'

export default function UserInfo({ userInfo }: { userInfo: UserInfoModel }) {
  const { formatMessage: f } = useIntl()

  return (
    <>
      <SubTitle title={f(commonMessages.basicInfo)} />
      <DataTable
        dataSets={[
          {
            label: f(commonMessages.name),
            content: userInfo[DashboardKeys.Name],
          },
          {
            label: f(commonMessages.status),
            content: (
              <StatusCapsule isActivated={userInfo[DashboardKeys.Active]} />
            ),
          },
          {
            label: f(commonMessages.maxDeviceCount),
            content: f(commonMessages.itemAmount, {
              num: userInfo[DashboardKeys.MaxDeviceCount],
            }),
          },
          {
            label: f(commonMessages.tokenLifeTime),
            content: f(commonMessages.timeMinutes, {
              num: userInfo[DashboardKeys.TokenLifeTime],
            }),
          },
          {
            label: f(commonMessages.registeredDeviceCount),
            content: f(commonMessages.itemAmount, {
              num: userInfo[DashboardKeys.RegisteredDeviceCount],
            }),
          },
          {
            label: f(commonMessages.description),
            content: userInfo[DashboardKeys.Description],
          },
        ]}
      />
    </>
  )
}
