import React from 'react'
import { MessageDescriptor, useIntl } from 'react-intl'
import { Breadcrumbs, Grid, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

export interface Breadcrumb {
  title: MessageDescriptor
  route?: string
}

interface Props {
  title: string
  breadcrumbs?: Breadcrumb[]
  buttonList?: JSX.Element[]
}

export default function ContentHeader({
  title,
  breadcrumbs,
  buttonList,
}: Props) {
  const { formatMessage: f } = useIntl()

  return (
    <>
      {breadcrumbs && (
        <Breadcrumbs
          separator=">"
          sx={{
            color: 'grey.700',
            marginBottom: 1,
          }}>
          {breadcrumbs.map(({ title, route }) =>
            route ? (
              <Link key={title.id} to={route}>
                {f(title)}
              </Link>
            ) : (
              <span key={title.id}>{f(title)}</span>
            )
          )}
        </Breadcrumbs>
      )}
      <Grid container display="flex" justifyContent="space-between">
        <Grid item>
          <Typography variant="h6">{title}</Typography>
        </Grid>
        <Grid item display="flex">
          {buttonList?.map((btn, idx) => (
            <Grid item key={`btn-${idx}`} pr={2}>
              {btn}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  )
}
