import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box } from '@mui/material'
import { StoreState } from '@src/user/reducers'
import {
  AccountActionType,
  beforeLoginAction,
  getProfileAction,
  loginAction,
  logoutAction,
} from '@user/reducers/account/accountActions'
import {
  GOOGLE_CODE_KEY,
  HTC_OAUTH,
  HTC_USER_OAUTH_PRAM,
} from '@src/common/constants'
import { useLoading } from '@src/hooks'
import { backgroundBlack } from '@common/styles'
import { ViveportLogin } from '@components/Button'
import LoginBox from '@components/LoginBox'
import GlobalDialog from '@components/GlobalDialog'
import { toUserRoute, userPath } from '@src/common/appConfig'
import { objToQueryStr } from '@src/utils/function'
import messages from '@common/messages'
import { AccountType } from '@src/user/models/account'

export default function Login() {
  const dispatch = useDispatch()
  const { formatMessage: f } = useIntl()
  const { isLogin } = useSelector(
    (state: StoreState) => ({
      isLogin: !!state.account.token,
    }),
    shallowEqual
  )
  const location = useLocation()
  const navigate = useNavigate()
  const { openSpinner } = useLoading({
    endActions: {
      success: [AccountActionType.GET_PROFILE_SUCCESS],
      error: [AccountActionType.GET_PROFILE_ERROR],
    },
    cbk: {
      success: () => {
        navigate(toUserRoute(userPath.agencyList.list))
      },
      error: () => {
        GlobalDialog.open({
          content: f(messages.expired),
          closeText: f(messages.confirm),
          onClose: () => {
            dispatch(logoutAction())
          },
        })
      },
    },
  })
  const code = new URLSearchParams(location.search).get(GOOGLE_CODE_KEY)

  useEffect(() => {
    if (!!code) {
      dispatch(loginAction(encodeURIComponent(code)))
    }
  }, [dispatch, code])

  useEffect(() => {
    if (isLogin) {
      openSpinner()
      dispatch(getProfileAction())
    }
  }, [dispatch, code, isLogin, openSpinner])

  const onViveportLogin = () => {
    dispatch(beforeLoginAction(AccountType.Viveport))
    const viveportOAth = HTC_OAUTH + objToQueryStr({ ...HTC_USER_OAUTH_PRAM })
    window.location.assign(viveportOAth)
  }

  return (
    <Box
      height="100vh"
      bgcolor={backgroundBlack}
      display="flex"
      alignItems="center">
      <LoginBox title="Agency">
        <Box textAlign="center">
          <ViveportLogin onClick={onViveportLogin} />
        </Box>
      </LoginBox>
    </Box>
  )
}
