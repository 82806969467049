import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'
import configureStore from './store/configureStore'
import { managerPath } from '@src/common/appConfig'
import Layout from './containers/Layout'
import { Login } from './containers/Login'
import { AgencyCreate, AgencyList } from './containers/Agency'
import { UserDashboard, UserInfoEdit } from './containers/UserDashboard'
import {
  DefaultConfigCreate,
  DefaultConfigEdit,
  DenyConfigCreate,
  DenyConfigEdit,
} from './containers/UserConfig'
import { MemberCreate, MemberEdit } from './containers/Member'

const store = configureStore()

export default function Manager() {
  return (
    <Provider store={store}>
      <Routes>
        <Route path={managerPath.login.login} element={<Login />} />
        <Route path="/" element={<Layout />}>
          <Route path={managerPath.agencyList.list} element={<AgencyList />} />
          <Route
            path={managerPath.agencyList.create}
            element={<AgencyCreate />}
          />
          <Route
            path={managerPath.userDashboard.dashboard}
            element={<UserDashboard />}
          />
          <Route
            path={managerPath.userDashboard.userEdit}
            element={<UserInfoEdit />}
          />
          <Route
            path={managerPath.userConfig.defaultCFGCreate}
            element={<DefaultConfigCreate />}
          />
          <Route
            path={managerPath.userConfig.defaultCFGEdit}
            element={<DefaultConfigEdit />}
          />
          <Route
            path={managerPath.userConfig.denyCFGCreate}
            element={<DenyConfigCreate />}
          />
          <Route
            path={managerPath.userConfig.denyCFGEdit}
            element={<DenyConfigEdit />}
          />
          <Route
            path={managerPath.member.memberCreate}
            element={<MemberCreate />}
          />
          <Route
            path={managerPath.member.memberEdit}
            element={<MemberEdit />}
          />
        </Route>
      </Routes>
    </Provider>
  )
}
