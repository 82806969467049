import React, { useEffect, useState } from 'react'
import { Subject } from 'rxjs'
import { Backdrop, CircularProgress } from '@mui/material'

const spinnerSubject = new Subject<boolean>()

const GlobalSpinner = () => {
  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    const sub = spinnerSubject.subscribe((isOpen: boolean) => setOpen(isOpen))
    return () => sub.unsubscribe()
  }, [])

  return (
    <Backdrop open={open} sx={{ zIndex: (theme) => theme.zIndex.appBar + 1 }}>
      <CircularProgress color="primary" />
    </Backdrop>
  )
}

GlobalSpinner.open = () => spinnerSubject.next(true)
GlobalSpinner.close = () => spinnerSubject.next(false)

export default GlobalSpinner
