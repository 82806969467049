import { SidebarTab } from '@components/Layout/components/Sidebar'
import { adminPath } from '@common/appConfig'
import { ReactComponent as IconManager } from '@src/assets/icons/iconOrg.svg'
import { ReactComponent as IconContent } from '@src/assets/icons/iconContent.svg'
import messages from './messages'

export enum StorageKey {
  Token = 'AdminAuthorization',
  Profile = 'AdminProfile',
}

export const SIDEBAR_TABS: SidebarTab[] = [
  {
    icon: IconContent,
    title: messages.adminManagement,
    basePath: adminPath.admin.root,
    route: adminPath.admin.list,
  },
  {
    icon: IconManager,
    title: messages.managerManagement,
    basePath: adminPath.manager.root,
    route: adminPath.manager.list,
  },
]
