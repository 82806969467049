const { NODE_ENV, REACT_APP_IS_MULTIPLE_ENTRY } = process.env

export const PAGE_LIMIT = 50
export const isDev = NODE_ENV === 'development'
export const IS_MULTIPLE_ENTRY = REACT_APP_IS_MULTIPLE_ENTRY ?? false

const adminRoutePrefix = IS_MULTIPLE_ENTRY ? '/admin' : ''
const managerRoutePrefix = IS_MULTIPLE_ENTRY ? '/manager' : ''
const userRoutePrefix = IS_MULTIPLE_ENTRY ? '/user' : ''

export enum RouteSection {
  Login = 'login',
  Admin = 'admin',
  Manager = 'manager',
  Agency = 'agency-list',
  UserDashboard = 'user-dashboard',
  UserInfo = 'user-info',
  Device = 'device',
  UserConfig = 'config',
  Member = 'member',
  Dashboard = 'dashboard',
  Group = 'group',
  Config = 'config',
  Map = 'map',
}

export const vbcPath = {
  login: {
    root: '/',
    success: 'success',
    failed: 'failed',
  },
}

export const adminPath = {
  login: {
    root: `${adminRoutePrefix}/${RouteSection.Login}`,
    login: RouteSection.Login,
  },
  admin: {
    root: `${adminRoutePrefix}/${RouteSection.Admin}`,
    list: `${RouteSection.Admin}/list`,
    detail: `${RouteSection.Admin}/:id/detail`,
    edit: `${RouteSection.Admin}/:id/edit`,
    create: `${RouteSection.Admin}/create`,
  },
  manager: {
    root: ` ${adminRoutePrefix}/${RouteSection.Manager}`,
    list: `${RouteSection.Manager}/list`,
    detail: `${RouteSection.Manager}/:id/detail`,
    edit: `${RouteSection.Manager}/:id/edit`,
    create: `${RouteSection.Manager}/create`,
  },
}
export const toAdminRoute = (path: string) => `${adminRoutePrefix}/${path}`

export const managerPath = {
  login: {
    root: `${managerRoutePrefix}/${RouteSection.Login}`,
    login: RouteSection.Login,
  },
  agencyList: {
    root: `${managerRoutePrefix}/${RouteSection.Agency}`,
    list: `${RouteSection.Agency}/list`,
    create: `${RouteSection.Agency}/create`,
  },
  userDashboard: {
    root: `${managerRoutePrefix}/${RouteSection.UserDashboard}`,
    dashboard: `${RouteSection.UserDashboard}/:id`,
    userEdit: `${RouteSection.UserDashboard}/:id/${RouteSection.UserInfo}/edit`,
  },
  userConfig: {
    root: `${managerRoutePrefix}/${RouteSection.UserConfig}`,
    defaultCFGCreate: `:id/${RouteSection.UserConfig}/default/create`,
    defaultCFGEdit: `:id/${RouteSection.UserConfig}/default/:defaultcfg/edit`,
    denyCFGCreate: `:id/${RouteSection.UserConfig}/deny/create`,
    denyCFGEdit: `:id/${RouteSection.UserConfig}/deny/:denycfg/edit`,
  },
  contents: {
    root: '/content',
  },
  member: {
    root: `${managerRoutePrefix}/${RouteSection.Member}`,
    memberCreate: `:id/${RouteSection.Member}/default/create`,
    memberEdit: `:id/${RouteSection.Member}/default/:email/edit`,
  },
}
export const toManagerRoute = (path: string) => `${managerRoutePrefix}/${path}`

export const userPath = {
  login: {
    root: `${userRoutePrefix}/${RouteSection.Login}`,
    login: RouteSection.Login,
  },
  agencyList: {
    root: `${userRoutePrefix}/${RouteSection.Agency}`,
    list: `${RouteSection.Agency}/list`,
  },
  dashboard: {
    root: `${userRoutePrefix}/${RouteSection.Dashboard}`,
    dashboard: `${RouteSection.Dashboard}/:id`,
  },
  member: {
    root: `${userRoutePrefix}/${RouteSection.Member}`,
    create: `:id/${RouteSection.Member}/create`,
    edit: `:id/${RouteSection.Member}/edit/:email`,
  },
  group: {
    root: `${userRoutePrefix}/${RouteSection.Group}`,
    list: `:id/${RouteSection.Group}/list`,
    create: `:id/${RouteSection.Group}/create`,
    edit: `:id/${RouteSection.Group}/edit/:group`,
  },
  device: {
    root: `${userRoutePrefix}/${RouteSection.Device}`,
    list: `:id/${RouteSection.Device}/list`,
    enroll: `:id/${RouteSection.Device}/enroll`,
    detail: `:id/${RouteSection.Device}/detail/:device`,
  },
  config: {
    root: `${userRoutePrefix}/${RouteSection.Config}`,
    list: `:id/${RouteSection.Config}/list`,
    detail: `:id/${RouteSection.Config}/detail/:config`,
  },
  map: {
    root: `${userRoutePrefix}/${RouteSection.Map}`,
    list: `:id/${RouteSection.Map}/list`,
    detail: `:id/${RouteSection.Map}/detail/:map`,
  },
}
export const toUserRoute = (path: string) => `${userRoutePrefix}/${path}`
