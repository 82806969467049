import React from 'react'
import { Controller, UseControllerProps } from 'react-hook-form'
import { FormHelperText, InputAdornment, TextField } from '@mui/material'

interface Props<T> extends UseControllerProps<T> {
  required?: boolean | string
  disabled?: boolean
  fullWidth?: boolean
  short?: boolean
  unit?: string
  acceptNegative?: boolean
}

const acceptCondition = (str: string, acceptNegative?: boolean) =>
  acceptNegative ? /^-?[0-9]*$/.test(str) : /^[0-9]*$/.test(str)

export default function ControlledNumberInput<T>({
  unit,
  fullWidth = false,
  short = false,
  required = false,
  disabled = false,
  acceptNegative = false,
  rules,
  ...rest
}: Props<T>) {
  return (
    <Controller
      rules={{ ...rules, required }}
      {...rest}
      render={({
        field: { value, onChange, ...field },
        fieldState: { invalid, error },
      }) => {
        return (
          <div>
            <TextField
              {...field}
              onChange={(e) => {
                const value = e.target.value
                if (!acceptCondition(value, acceptNegative)) {
                  e.preventDefault()
                  return
                }
                if (acceptNegative && value === '-') {
                  onChange('-')
                  return
                }
                if (value === '') {
                  onChange(undefined)
                  return
                }
                onChange(Number(value))
              }}
              value={value === undefined ? '' : value}
              error={invalid}
              sx={short ? { width: 70 } : undefined}
              fullWidth={fullWidth}
              disabled={disabled}
              type="number"
              InputProps={{
                endAdornment: unit && (
                  <InputAdornment position="end">{unit}</InputAdornment>
                ),
              }}
            />
            {error && (
              <FormHelperText error sx={{ paddingLeft: 2, paddingTop: 0.5 }}>
                {error.message}
              </FormHelperText>
            )}
          </div>
        )
      }}
    />
  )
}
