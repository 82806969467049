import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { PAGE_LIMIT } from '../common/constants'

const PAGE_KEYS = 'page'
const INITIAL_PAGE = 1

export default function usePagination({
  count,
  beforePageChange,
}: {
  count: number
  beforePageChange?: () => void
}) {
  const navigate = useNavigate()
  const pageItemNum = PAGE_LIMIT
  const location = useLocation()

  const page = useMemo<number>(() => {
    const page = new URLSearchParams(location.search).get(PAGE_KEYS)
    return page ? Number(page) : INITIAL_PAGE
  }, [location])

  const handlePageChange = useCallback(
    (_: React.ChangeEvent<unknown> | null, page: number) => {
      const newPage = page.toString()
      const params = new URLSearchParams(location.search)

      if (beforePageChange) {
        beforePageChange()
      }

      params.set(PAGE_KEYS, newPage)

      navigate({
        pathname: location.pathname,
        search: params.toString(),
      })
    },
    [location, navigate, beforePageChange]
  )

  const pagination = useMemo(
    () => ({
      count,
      start: (page - 1) * pageItemNum + 1,
      perPage: pageItemNum,
      page,
    }),
    [page, count, pageItemNum]
  )

  const query = useMemo(
    () => ({
      page: pagination.page,
      limit: pagination.perPage,
    }),
    [pagination.page, pagination.perPage]
  )

  return {
    page,
    pagination,
    handlePageChange,
    query,
  }
}
