import React, { ElementType } from 'react'
import { Controller, UseControllerProps } from 'react-hook-form'
import { Box, Checkbox, Typography } from '@mui/material'
import { disableGrey, textPrimary } from '@src/common/styles'

interface Props<T> extends UseControllerProps<T> {
  required?: boolean | string
  disabled?: boolean
  label?: string
  component?: ElementType<any>
}

export default function ControlledCheckbox<T>({
  required = false,
  disabled = false,
  label,
  component,
  rules,
  ...rest
}: Props<T>) {
  return (
    <Controller
      rules={{ ...rules, required }}
      {...rest}
      render={({ field: { value, ...field } }) => {
        return (
          <Box component={component} sx={{ verticalAlign: 'middle' }}>
            <Checkbox
              {...field}
              checked={value ? true : false}
              value={value === undefined ? '' : value}
              disabled={disabled}
            />
            {label && (
              <Typography
                pr={2}
                component="span"
                color={disabled ? disableGrey : textPrimary}>
                {label}
              </Typography>
            )}
          </Box>
        )
      }}
    />
  )
}
