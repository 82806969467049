import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from '@src/admin/reducers'
import {
  AdminActionType,
  deleteAdminAction,
  getAdminAction,
} from '@src/admin/reducers/admin/adminActions'
import { Button } from '@mui/material'
import { adminPath, toAdminRoute } from '@common/appConfig'
import ContentHeader from '@components/ContentHeader'
import { StatusCapsule } from '@components/Capsule'
import DataTable from '@components/DataTable'
import commonMessages from '@common/messages'
import messages from '@admin/utils/messages'
import { ReactComponent as IconEdit } from '@src/assets/icons/iconEdit.svg'
import { ReactComponent as IconDelete } from '@src/assets/icons/iconDelete.svg'
import { DETAIL_BREADCRUMBS } from './utils'
import { AdminKeys } from '@admin/models/admin'
import { useGlobalSnackbar, useLoading } from '@src/hooks'
import Snackbar, { SnackbarState } from '@src/components/Snackbar'
import { ErrorResponse } from '@src/utils/type'

export default function AdminDetail() {
  const { formatMessage: f } = useIntl()
  const dispatch = useDispatch()
  const { currentAdmin } = useSelector((state: StoreState) => state.admin)
  const navigate = useNavigate()
  const { id } = useParams()
  const { status, setSnackbar, clearSnackbar } = useGlobalSnackbar({
    page: 'admin',
  })
  const { openSpinner } = useLoading({
    endActions: {
      success: [
        AdminActionType.GET_ADMIN_SUCCESS,
        AdminActionType.DELETE_ADMIN_SUCCESS,
      ],
      error: [
        AdminActionType.GET_ADMIN_ERROR,
        AdminActionType.DELETE_ADMIN_ERROR,
      ],
    },
    cbk: {
      success: ({ type }: { type: AdminActionType }) => {
        if (type === AdminActionType.DELETE_ADMIN_SUCCESS) {
          setSnackbar({
            state: SnackbarState.Success,
            message: f(commonMessages.createSuccess),
            notKeepData: true,
          })
          navigate(toAdminRoute(adminPath.admin.list))
        }
      },
      error: ({ error }: { error: ErrorResponse }) => {
        setSnackbar({
          state: SnackbarState.Error,
          message: error.message,
          notKeepData: true,
        })
        document.documentElement.scrollTop = 0
      },
    },
  })

  useEffect(() => {
    openSpinner()
    dispatch(getAdminAction(id!))
  }, [dispatch, openSpinner, id])

  const handleDelete = () => {
    openSpinner()
    dispatch(deleteAdminAction(id!))
  }

  return currentAdmin ? (
    <>
      <ContentHeader
        title={f(messages.adminDetail)}
        breadcrumbs={DETAIL_BREADCRUMBS.concat({
          title: messages.adminDetail,
        })}
        buttonList={[
          <Button
            variant="contained"
            startIcon={<IconEdit />}
            onClick={() =>
              navigate(generatePath(toAdminRoute(adminPath.admin.edit), { id }))
            }>
            {f(commonMessages.edit)}
          </Button>,
          <Button
            variant="contained"
            color="error"
            startIcon={<IconDelete />}
            onClick={handleDelete}>
            {f(commonMessages.delete)}
          </Button>,
        ]}
      />
      {status && (
        <Snackbar
          severity={status.state}
          message={status.message!}
          onClose={clearSnackbar}
        />
      )}
      <DataTable
        title={f(commonMessages.basicInfo)}
        dataSets={[
          {
            label: f(commonMessages.name),
            content: currentAdmin[AdminKeys.Name],
          },
          {
            label: f(commonMessages.email),
            content: currentAdmin[AdminKeys.Email],
          },
          {
            label: f(commonMessages.status),
            content: (
              <StatusCapsule isActivated={currentAdmin[AdminKeys.Active]} />
            ),
          },
        ]}
      />
    </>
  ) : null
}
