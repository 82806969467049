import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Button } from '@mui/material'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import {
  createMemberAction,
  MemberActionType,
} from '@src/user/reducers/member/memberActions'
import { MemberDetail } from '@user/models/dashboard'
import { useGlobalSnackbar, useLoading } from '@src/hooks'
import ContentHeader from '@components/ContentHeader'
import Snackbar, { SnackbarState } from '@src/components/Snackbar'
import MemberForm from './components/MemberForm'
import { ReactComponent as IconSave } from '@src/assets/icons/iconSave.svg'
import { RouteSection, toUserRoute, userPath } from '@common/appConfig'
import commonMessages from '@common/messages'
import userMessages from '@user/utils/messages'
import messages from './messages'

export default function MemberCreate() {
  const { formatMessage: f } = useIntl()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const { control, handleSubmit } = useForm<MemberDetail>({
    defaultValues: {},
  })
  const { status, setSnackbar, clearSnackbar } = useGlobalSnackbar({
    page: RouteSection.Dashboard,
  })
  const { openSpinner } = useLoading({
    endActions: {
      success: [MemberActionType.CREATE_MEMBER_SUCCESS],
      error: [MemberActionType.CREATE_MEMBER_ERROR],
    },
    cbk: {
      success: () => {
        setSnackbar({
          state: SnackbarState.Success,
          message: f(commonMessages.createSuccess),
          notKeepData: false,
        })
        document.documentElement.scrollTop = 0
        navigate(
          generatePath(toUserRoute(userPath.dashboard.dashboard), {
            id,
          })
        )
      },
      error: () => {
        setSnackbar({
          state: SnackbarState.Error,
          message: f(commonMessages.createFailed),
          notKeepData: true,
        })
        document.documentElement.scrollTop = 0
      },
    },
  })

  const onSubmit = (data: MemberDetail) => {
    openSpinner()
    dispatch(createMemberAction({ id: id!, data }))
  }

  return (
    <>
      {status && (
        <Snackbar
          severity={SnackbarState.Error}
          message={status.message!}
          onClose={clearSnackbar}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <ContentHeader
          title={f(messages.memberCreate)}
          breadcrumbs={[
            {
              title: userMessages.dashboard,
              route: generatePath(toUserRoute(userPath.dashboard.dashboard), {
                id,
              }),
            },
            {
              title: messages.memberList,
            },
            {
              title: messages.memberCreate,
            },
          ]}
          buttonList={[
            <Button variant="contained" type="submit" startIcon={<IconSave />}>
              {f(commonMessages.save)}
            </Button>,
          ]}
        />
        <MemberForm control={control} />
      </form>
    </>
  )
}
