import { DeleteActivatedDevicesPayload } from '@src/manager/models/userDashboard'

export enum DeviceActionType {
  DELETE_ACTIVATED_DEVICES = '@device/DELETE_ACTIVATED_DEVICES',
  DELETE_ACTIVATED_DEVICES_SUCCESS = '@device/DELETE_ACTIVATED_DEVICES_SUCCESS',
  DELETE_ACTIVATED_DEVICES_ERROR = '@device/DELETE_ACTIVATED_DEVICES_ERROR',
}
export const deleteActivatedDeviceAction = (
  payload: DeleteActivatedDevicesPayload
) => ({
  type: DeviceActionType.DELETE_ACTIVATED_DEVICES,
  payload,
})

export const deleteActivatedDeviceSuccessAction = () => ({
  type: DeviceActionType.DELETE_ACTIVATED_DEVICES_SUCCESS,
})

export const deleteActivatedDeviceErrorAction = () => ({
  type: DeviceActionType.DELETE_ACTIVATED_DEVICES_ERROR,
})
