import React from 'react'
import { Box } from '@mui/material'

const CHARGE_MAX = 100
const LOW_BATTERY = 0.25 * CHARGE_MAX

export default function Battery({ battery }: { battery: number }) {
  return (
    <Box
      sx={({ palette }) => ({
        color: battery < LOW_BATTERY ? palette.error.main : undefined,
      })}>
      {battery}/{CHARGE_MAX}
    </Box>
  )
}
