import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { MessageDescriptor, useIntl } from 'react-intl'
import { Subject } from 'rxjs'
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import messages from '@common/messages'
import { logoutAction } from '@src/admin/reducers/account/accountActions'
import { adminPath, toAdminRoute } from '@src/common/appConfig'

export interface Props {
  open?: boolean
  content: MessageDescriptor
}

const dialogSubject = new Subject<Props>()

const initDialog: Props = {
  open: false,
  content: messages.unknownError,
}

const AuthDialog = function () {
  const { formatMessage: f } = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [{ open, content }, setDialog] = useState<Props>(initDialog)

  useEffect(() => {
    const subscription = dialogSubject.subscribe((data: Props) =>
      setDialog(data)
    )
    return () => subscription.unsubscribe()
  }, [])

  const handleLogout = () => {
    dispatch(logoutAction())
    navigate(toAdminRoute(adminPath.login.login))
  }

  return open ? (
    <Dialog open={true}>
      <DialogContent>{f(content)}</DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button variant="contained" onClick={handleLogout}>
          {f(messages.confirm)}
        </Button>
      </DialogActions>
    </Dialog>
  ) : null
}

AuthDialog.open = function (props: Props) {
  dialogSubject.next({
    ...props,
    open: true,
  })
}

export default AuthDialog
