import React from 'react'
import ViveportImg from '@assets/icons/iconViveport.svg'
import OAthButton from './OAthButton'

export default function ViveportLogin({ onClick }: { onClick: () => void }) {
  return (
    <OAthButton
      onClick={onClick}
      icon={{ src: ViveportImg, alt: 'VIVEPORT' }}
    />
  )
}
