import React from 'react'
import { Box } from '@mui/material'
import { useLocation } from 'react-router-dom'
import LoginBox from '@components/LoginBox'
import { ViveportLogin } from '@components/Button'
import { backgroundBlack } from '@common/styles'
import { HTC_OAUTH, HTC_VBC_OAUTH_PRAM, STATE } from '@src/common/constants'
import { objToQueryStr } from '@src/utils/function'

export default function Login() {
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const auth = query.get('auth') ?? ''

  const onViveportLogin = () => {
    const viveportOAth =
      HTC_OAUTH + objToQueryStr({ ...HTC_VBC_OAUTH_PRAM, [STATE]: auth })
    window.location.assign(viveportOAth)
  }

  return (
    <Box
      height="100vh"
      bgcolor={backgroundBlack}
      display="flex"
      alignItems="center">
      <LoginBox>
        <Box textAlign="center">
          <ViveportLogin onClick={onViveportLogin} />
        </Box>
      </LoginBox>
    </Box>
  )
}
