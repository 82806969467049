import { useCallback, useState, useMemo, useEffect } from 'react'
import { useIntl } from 'react-intl'
import messages from '@src/common/messages'
import { SnackbarState } from '@src/components/Snackbar'

interface Message {
  state: SnackbarState
  message?: string
  notKeepData?: boolean
}

const alertStatus: {
  [key: string]: Message
} = {}

export default function useGlobalAlertMessage({ page }: { page: string }) {
  const [status, setStatus] = useState<Message | undefined>(alertStatus[page])
  const { formatMessage: f } = useIntl()

  useEffect(() => {
    setStatus(alertStatus[page])
  }, [page])

  const defaultMessage = useMemo(
    () => ({
      [SnackbarState.Success]: f(messages.createSuccess),
      [SnackbarState.Error]: f(messages.createFailed),
      [SnackbarState.Info]: f(messages.createSuccess),
      [SnackbarState.Warning]: f(messages.createFailed),
    }),
    [f]
  )

  const clearSnackbar = useCallback(() => {
    delete alertStatus[page]
    setStatus(undefined)
  }, [setStatus, page])

  const setSnackbar = useCallback(
    ({ notKeepData, ...data }: Message) => {
      data.message = data.message || defaultMessage[data.state]
      if (!notKeepData) {
        alertStatus[page] = data
      }
      setStatus(data)
    },
    [setStatus, page, defaultMessage]
  )

  return {
    status,
    setSnackbar,
    clearSnackbar,
  }
}
