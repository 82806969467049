import { defineMessages } from 'react-intl'

const PREFIX = 'user.member.'

export default defineMessages({
  memberList: {
    id: PREFIX + 'member_list',
    defaultMessage: 'Member List',
  },
  memberCreate: {
    id: PREFIX + 'member_create',
    defaultMessage: 'Member Create',
  },
  memberEdit: {
    id: PREFIX + 'member_edit',
    defaultMessage: 'Member Edit',
  },
})
