import { Breadcrumb } from '@components/ContentHeader'
import messages from '@manager/utils/messages'
import { managerPath, toManagerRoute } from '@src/common/appConfig'
import {
  UserDashboardKeys,
  UserInfoRequest,
} from '@src/manager/models/userDashboard'
import { minuteToMillisecond } from '@src/utils/function'

export const BREADCRUMBS: Breadcrumb[] = [
  {
    title: messages.agency,
    route: toManagerRoute(managerPath.agencyList.list),
  },
]

export const toRequestUserInfo: (data: UserInfoRequest) => UserInfoRequest = ({
  id,
  data: { [UserDashboardKeys.TokenLifeTime]: tokenLifeTime, ...res },
}) => {
  return {
    id,
    data: {
      ...res,
      [UserDashboardKeys.TokenLifeTime]: minuteToMillisecond(tokenLifeTime),
    },
  }
}
