import { DeviceEnroll } from '@src/user/models/device'

export enum DeviceActionType {
  SET_ENROLL_INFO = '@Device/SET_ENROLL_INFO',
}

export const setDeviceEnrollAction = (payload: DeviceEnroll) => ({
  type: DeviceActionType.SET_ENROLL_INFO,
  payload,
})
