import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'
import configureStore from '@admin/store/configureStore'
import { adminPath } from '@common/appConfig'
import Layout from './container/Layout'
import {
  AdminDetail,
  AdminEdit,
  AdminList,
  AdminCreate,
} from './container/AdminManagement'
import { Login } from './container/Login'
import {
  ManagerCreate,
  ManagerDetail,
  ManagerEdit,
  ManagerList,
} from './container/ManagerManagement'

const store = configureStore()

export default function Admin() {
  return (
    <Provider store={store}>
      <Routes>
        <Route path={adminPath.login.login} element={<Login />} />
        <Route path="/*" element={<Layout />}>
          <Route path={adminPath.admin.list} element={<AdminList />} />
          <Route path={adminPath.admin.detail} element={<AdminDetail />} />
          <Route path={adminPath.admin.edit} element={<AdminEdit />} />
          <Route path={adminPath.admin.create} element={<AdminCreate />} />
          <Route path={adminPath.manager.list} element={<ManagerList />} />
          <Route path={adminPath.manager.detail} element={<ManagerDetail />} />
          <Route path={adminPath.manager.edit} element={<ManagerEdit />} />
          <Route path={adminPath.manager.create} element={<ManagerCreate />} />
        </Route>
      </Routes>
    </Provider>
  )
}
