import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { StoreState } from '@src/admin/reducers'
import { Box } from '@mui/material'
import { backgroundBlack } from '@common/styles'
import { MicrosoftLogin } from '@components/Button'
import LoginBox from '@components/LoginBox'
import { adminPath, toAdminRoute } from '@common/appConfig'
import {
  AccountActionType,
  getProfileAction,
  loginAction,
  logoutAction,
} from '@src/admin/reducers/account/accountActions'
import { MSAL_ADMIN_OAUTH } from '@src/common/constants'
import { toMsalCode } from '@src/utils/function'
import { useLoading } from '@src/hooks'
import GlobalDialog from '@src/components/GlobalDialog'
import messages from '@common/messages'

export default function Login() {
  const { formatMessage: f } = useIntl()
  const dispatch = useDispatch()
  const { isLogin } = useSelector(
    (state: StoreState) => ({
      isLogin: !!state.account.token,
    }),
    shallowEqual
  )
  const navigate = useNavigate()
  const { openSpinner } = useLoading({
    endActions: {
      success: [AccountActionType.GET_PROFILE_SUCCESS],
      error: [AccountActionType.GET_PROFILE_ERROR],
    },
    cbk: {
      success: () => {
        navigate(toAdminRoute(adminPath.admin.list))
      },
      error: () => {
        GlobalDialog.open({
          content: f(messages.expired),
          closeText: f(messages.confirm),
          onClose: () => {
            dispatch(logoutAction())
          },
        })
      },
    },
  })
  const code = toMsalCode(window.location.hash)

  useEffect(() => {
    if (!!code) {
      dispatch(loginAction(code))
    }
  }, [dispatch, code])

  useEffect(() => {
    if (isLogin) {
      openSpinner()
      dispatch(getProfileAction())
    }
  }, [dispatch, code, isLogin, openSpinner])

  return (
    <Box
      height="100vh"
      bgcolor={backgroundBlack}
      display="flex"
      alignItems="center">
      <LoginBox title="Admin">
        <Box textAlign="center">
          <MicrosoftLogin msalOAth={MSAL_ADMIN_OAUTH} />
        </Box>
      </LoginBox>
    </Box>
  )
}
