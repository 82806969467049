import { AnyAction } from 'redux'
import { ofType } from 'redux-observable'
import {
  empty,
  exhaustMap,
  iif,
  map,
  mergeMap,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs'
import { AjaxResponse } from 'rxjs/ajax'
import { errorSubject, successSubject } from '@src/utils/responseSubject'
import { catchErrorHandler } from '@manager/utils/ajaxUtils'
import * as userConfigServices from './userConfigServices'
import {
  createDefaultConfigFailedAction,
  createDefaultConfigSuccessAction,
  createDenyConfigFailedAction,
  createDenyConfigSuccessAction,
  deleteDefaultConfigSuccessAction,
  deleteDenyConfigSuccessAction,
  getDefaultConfigSuccessAction,
  getDenyConfigSuccessAction,
  updateDefaultConfigFailedAction,
  updateDefaultConfigSuccessAction,
  updateDenyConfigFailedAction,
  updateDenyConfigSuccessAction,
  UserConfigActionType,
} from '@src/manager/reducers/userConfig/userConfigActions'
import { SUCCESS_BRIEF_CODE } from '@src/utils/ajaxUtils'

const getDefaultConfig = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(UserConfigActionType.GET_DEFAULT_CONFIG),
    switchMap(({ payload }) =>
      userConfigServices.getDefaultConfigAjax(payload).pipe(
        map((res) => getDefaultConfigSuccessAction(res.response.Message)),
        tap(() =>
          successSubject.next({
            type: UserConfigActionType.GET_DEFAULT_CONFIG_SUCCESS,
          })
        ),
        catchErrorHandler((error) => {
          errorSubject.next({
            type: UserConfigActionType.GET_DEFAULT_CONFIG_ERROR,
            error: error.response?.message,
          })
          return empty()
        })
      )
    )
  )

const createDefaultConfig = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(UserConfigActionType.CREATE_DEFAULT_CONFIG),
    exhaustMap(({ payload }) =>
      userConfigServices.createDefaultConfigAjax(payload).pipe(
        mergeMap((result: AjaxResponse<any>) =>
          iif(
            () => result?.response?.BriefCode === SUCCESS_BRIEF_CODE,
            of(createDefaultConfigSuccessAction()).pipe(
              tap(() =>
                successSubject.next({
                  type: UserConfigActionType.CREATE_DEFAULT_CONFIG_SUCCESS,
                })
              )
            ),
            of(createDefaultConfigFailedAction()).pipe(
              tap(() =>
                errorSubject.next({
                  type: UserConfigActionType.CREATE_DEFAULT_CONFIG_ERROR,
                  error: result?.response?.message,
                })
              )
            )
          )
        ),
        catchErrorHandler((error) => {
          errorSubject.next({
            type: UserConfigActionType.CREATE_DEFAULT_CONFIG_ERROR,
            error: error.response?.data,
          })
          return empty()
        })
      )
    )
  )

const updateDefaultConfig = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(UserConfigActionType.UPDATE_DEFAULT_CONFIG),
    exhaustMap(({ payload }) =>
      userConfigServices.updateDefaultConfigAjax(payload).pipe(
        mergeMap((result: AjaxResponse<any>) =>
          iif(
            () => result?.response?.BriefCode === SUCCESS_BRIEF_CODE,
            of(updateDefaultConfigSuccessAction()).pipe(
              tap(() =>
                successSubject.next({
                  type: UserConfigActionType.UPDATE_DEFAULT_CONFIG_SUCCESS,
                })
              )
            ),
            of(updateDefaultConfigFailedAction()).pipe(
              tap(() =>
                errorSubject.next({
                  type: UserConfigActionType.CREATE_DEFAULT_CONFIG_ERROR,
                  error: result?.response?.message,
                })
              )
            )
          )
        ),
        catchErrorHandler((error) => {
          errorSubject.next({
            type: UserConfigActionType.UPDATE_DEFAULT_CONFIG_ERROR,
            error: error.response?.data,
          })
          return empty()
        })
      )
    )
  )

const deleteDefaultConfig = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(UserConfigActionType.DELETE_DEFAULT_CONFIG),
    exhaustMap(({ payload }) =>
      userConfigServices.deleteDefaultConfigAjax(payload).pipe(
        map((res) => deleteDefaultConfigSuccessAction()),
        tap(() =>
          successSubject.next({
            type: UserConfigActionType.DELETE_DEFAULT_CONFIG_SUCCESS,
          })
        ),
        catchErrorHandler((error) => {
          errorSubject.next({
            type: UserConfigActionType.DELETE_DEFAULT_CONFIG_ERROR,
            error: error.response?.data,
          })
          return empty()
        })
      )
    )
  )

const getDenyConfig = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(UserConfigActionType.GET_DENY_CONFIG),
    switchMap(({ payload }) =>
      userConfigServices.getDenyConfigAjax(payload).pipe(
        map((res) => getDenyConfigSuccessAction(res.response.Message)),
        tap(() =>
          successSubject.next({
            type: UserConfigActionType.GET_DENY_CONFIG_SUCCESS,
          })
        ),
        catchErrorHandler((error) => {
          errorSubject.next({
            type: UserConfigActionType.GET_DENY_CONFIG_ERROR,
            error: error.response?.message,
          })
          return empty()
        })
      )
    )
  )

const createDenyConfig = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(UserConfigActionType.CREATE_DENY_CONFIG),
    exhaustMap(({ payload }) =>
      userConfigServices.createDenyConfigAjax(payload).pipe(
        mergeMap((result: AjaxResponse<any>) =>
          iif(
            () => result?.response?.BriefCode === SUCCESS_BRIEF_CODE,
            of(createDenyConfigSuccessAction()).pipe(
              tap(() =>
                successSubject.next({
                  type: UserConfigActionType.CREATE_DENY_CONFIG_SUCCESS,
                })
              )
            ),
            of(createDenyConfigFailedAction()).pipe(
              tap(() =>
                errorSubject.next({
                  type: UserConfigActionType.CREATE_DENY_CONFIG_ERROR,
                  error: result?.response?.message,
                })
              )
            )
          )
        ),
        catchErrorHandler((error) => {
          errorSubject.next({
            type: UserConfigActionType.CREATE_DENY_CONFIG_ERROR,
            error: error.response?.data,
          })
          return empty()
        })
      )
    )
  )

const updateDenyConfig = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(UserConfigActionType.UPDATE_DENY_CONFIG),
    exhaustMap(({ payload }) =>
      userConfigServices.updateDenyConfigAjax(payload).pipe(
        mergeMap((result: AjaxResponse<any>) =>
          iif(
            () => result?.response?.BriefCode === SUCCESS_BRIEF_CODE,
            of(updateDenyConfigSuccessAction()).pipe(
              tap(() =>
                successSubject.next({
                  type: UserConfigActionType.UPDATE_DENY_CONFIG_SUCCESS,
                })
              )
            ),
            of(updateDenyConfigFailedAction()).pipe(
              tap(() =>
                errorSubject.next({
                  type: UserConfigActionType.CREATE_DENY_CONFIG_ERROR,
                  error: result?.response?.message,
                })
              )
            )
          )
        ),
        catchErrorHandler((error) => {
          errorSubject.next({
            type: UserConfigActionType.UPDATE_DENY_CONFIG_ERROR,
            error: error.response?.data,
          })
          return empty()
        })
      )
    )
  )

const deleteDenyConfig = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(UserConfigActionType.DELETE_DENY_CONFIG),
    exhaustMap(({ payload }) =>
      userConfigServices.deleteDenyConfigAjax(payload).pipe(
        map((res) => deleteDenyConfigSuccessAction()),
        tap(() =>
          successSubject.next({
            type: UserConfigActionType.DELETE_DENY_CONFIG_SUCCESS,
          })
        ),
        catchErrorHandler((error) => {
          errorSubject.next({
            type: UserConfigActionType.DELETE_DENY_CONFIG_ERROR,
            error: error.response?.data,
          })
          return empty()
        })
      )
    )
  )

const userConfigEpics = [
  getDefaultConfig,
  createDefaultConfig,
  updateDefaultConfig,
  deleteDefaultConfig,

  getDenyConfig,
  createDenyConfig,
  updateDenyConfig,
  deleteDenyConfig,
]

export default userConfigEpics
