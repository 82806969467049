import { SidebarTab } from '@components/Layout/components/Sidebar'
import { userPath } from '@common/appConfig'
import { ReactComponent as IconAgency } from '@src/assets/icons/iconViewList.svg'
import { ReactComponent as IconDashboard } from '@src/assets/icons/iconDashboard.svg'
import messages from './messages'

export enum StorageKey {
  Token = 'UserAuthorization',
  Account = 'UserAccount',
  Profile = 'UserProfile',
  DeviceEnroll = 'EnrollInfo',
}

export const SIDEBAR_TABS: SidebarTab[] = [
  {
    icon: IconAgency,
    title: messages.agency,
    basePath: userPath.agencyList.root,
    route: userPath.agencyList.list,
  },
  {
    icon: IconDashboard,
    title: messages.dashboard,
    basePath: userPath.dashboard.root,
    route: userPath.dashboard.dashboard,
  },
]

export const AGENCY_REGEXP = /\/\w+\/\w+-\w+/gi
