import {
  UserDashboardDetail,
  UserInfoRequest,
} from '@src/manager/models/userDashboard'

export enum UserDashboardActionType {
  GET_USER_DASHBOARD = '@userDashboard/GET_USER_DASHBOARD',
  GET_USER_DASHBOARD_SUCCESS = '@userDashboard/GET_USER_DASHBOARD_SUCCESS',
  GET_USER_DASHBOARD_ERROR = '@userDashboard/GET_USER_DASHBOARD_ERROR',

  START_POLLING_USER_DASHBOARD = '@userDashboard/START_POLLING_USER_DASHBOARD',
  STOP_POLLING_USER_DASHBOARD = '@userDashboard/STOP_POLLING_USER_DASHBOARD',

  UPDATE_USER_INFO = '@userDashboard/UPDATE_USER_INFO',
  UPDATE_USER_INFO_SUCCESS = '@userDashboard/UPDATE_USER_INFO_SUCCESS',
  UPDATE_USER_INFO_ERROR = '@userDashboard/UPDATE_USER_INFO_ERROR',

  RESET_USER_DASHBOARD = '@userDashboard/RESET_USER_DASHBOARD',
}

export const getUserDashboardAction = (payload: string) => ({
  type: UserDashboardActionType.GET_USER_DASHBOARD,
  payload,
})

export const getUserDashboardSuccessAction = (
  payload: UserDashboardDetail
) => ({
  type: UserDashboardActionType.GET_USER_DASHBOARD_SUCCESS,
  payload,
})

export const startPollingUserDashboardAction = (payload: string) => ({
  type: UserDashboardActionType.START_POLLING_USER_DASHBOARD,
  payload,
})

export const stopPollingUserDashboardAction = () => ({
  type: UserDashboardActionType.STOP_POLLING_USER_DASHBOARD,
})

export const updateUserInfoAction = (payload: UserInfoRequest) => ({
  type: UserDashboardActionType.UPDATE_USER_INFO,
  payload,
})

export const updateUserInfoSuccessAction = () => ({
  type: UserDashboardActionType.UPDATE_USER_INFO_SUCCESS,
})

export const updateUserInfoFailedAction = () => ({
  type: UserDashboardActionType.UPDATE_USER_INFO_ERROR,
})

export const resetUserDashboardErrorAction = () => ({
  type: UserDashboardActionType.RESET_USER_DASHBOARD,
})
