import { Subject } from 'rxjs'
import { ofType } from 'redux-observable'
import logger from './logger'
import { ErrorResponse } from './type'

interface SuccessType {
  type: string
  message?: any
}

interface ErrorType {
  type: string
  error?: ErrorResponse
}

const success = new Subject<SuccessType>()
const error = new Subject<ErrorType>()

export const successSubject = {
  next: (data: SuccessType) => {
    success.next(data)
    logger.log('%c Success subject: ', 'color: #04C1F9', data)
  },
  subscribe: (actions: string[], next: (value: SuccessType) => void) => {
    return success
      .asObservable()
      .pipe(ofType('', ...actions))
      .subscribe(next)
  },
}
export const errorSubject = {
  next: (data: ErrorType) => {
    error.next(data)
    logger.info('%c Error subject: ', 'color: #F4800A', data)
  },
  subscribe: (actions: string[], next: (value: ErrorType) => void) =>
    error
      .asObservable()
      .pipe(ofType('', ...actions))
      .subscribe(next),
}
