export enum RomKeys {
  Id = 'ID',
  VersionName = 'VersionName',
  Description = 'Description',
}

export interface Rom {
  [RomKeys.Id]: string
  [RomKeys.VersionName]: string
  [RomKeys.Description]: string
}
