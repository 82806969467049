export enum AgencyKeys {
  Id = 'ID',
  Name = 'FullName',
  Active = 'Active',
  Description = 'Description',
  MaxDeviceCount = 'MaxDeviceCount',
  TokenLifeTime = 'TokenLifeTime',

  Member = 'member',
}

export interface Agency {
  [AgencyKeys.Id]: string
  [AgencyKeys.Name]: string
  [AgencyKeys.Active]: boolean
  [AgencyKeys.TokenLifeTime]: number
}
