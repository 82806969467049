import { MemberDetail } from '@user/models/dashboard'
import { ActionType } from '@src/utils/type'
import { MemberActionType } from './memberActions'

export interface MemberState {
  currentMember?: MemberDetail
}

const initState: MemberState = {}

export const MemberPreloadState = initState

const updateMemberHandler = (
  state: MemberState = initState,
  action: ActionType<MemberDetail | undefined>
): MemberState => {
  return {
    ...state,
    currentMember: action.payload,
  }
}

const handler: Record<
  string,
  (state: MemberState, action: ActionType<any>) => MemberState
> = {
  [MemberActionType.GET_MEMBER_SUCCESS]: updateMemberHandler,
  [MemberActionType.RESET_MEMBER]: updateMemberHandler,
}

export default function memberReducer(
  state: MemberState = initState,
  action: ActionType<any>
) {
  if (handler.hasOwnProperty(action.type)) {
    return handler[action.type](state, action)
  }
  return state
}
