import {
  UserDashboardDetail,
  UserDashboardKeys,
} from '@src/manager/models/userDashboard'
import { millisecondToMinute } from '@src/utils/function'
import { createSelector } from 'reselect'
import { StoreState } from '..'

export const userDashboardSelectors = createSelector(
  [(state: StoreState) => state.userDashboard.currentUserDashboard],
  (userDashboard?: UserDashboardDetail) => {
    const currentUserDashboard = userDashboard && {
      ...userDashboard,
      [UserDashboardKeys.RegisteredDeviceCount]:
        userDashboard[UserDashboardKeys.ActivatedDevices].length,
      [UserDashboardKeys.TokenLifeTime]: millisecondToMinute(
        userDashboard[UserDashboardKeys.TokenLifeTime]
      ),
    }
    const currentUserInfo = userDashboard && {
      [UserDashboardKeys.Name]: userDashboard[UserDashboardKeys.Name],
      [UserDashboardKeys.Active]: userDashboard[UserDashboardKeys.Active],
      [UserDashboardKeys.Description]:
        userDashboard[UserDashboardKeys.Description],
      [UserDashboardKeys.MaxDeviceCount]:
        userDashboard[UserDashboardKeys.MaxDeviceCount],
      [UserDashboardKeys.RegisteredDeviceCount]:
        userDashboard[UserDashboardKeys.ActivatedDevices].length,
      [UserDashboardKeys.TokenLifeTime]: millisecondToMinute(
        userDashboard[UserDashboardKeys.TokenLifeTime]
      ),
    }
    return {
      currentUserDashboard,
      currentUserInfo,
    }
  }
)
