import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Subject } from 'rxjs'
import {
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material'
import messages from '@common/messages'

export interface Props {
  open?: boolean
  content: string | JSX.Element
  agreeText?: string
  closeText?: string
  onAgree?: () => void
  onClose?: () => void
  fullScreen?: boolean
  maxWidth?: false | Breakpoint
}

const dialogSubject = new Subject<Props>()

const initDialog: Props = {
  open: false,
  content: '',
}

const GlobalDialog = function () {
  const { formatMessage } = useIntl()
  const [
    {
      open,
      content,
      agreeText,
      closeText,
      onAgree,
      onClose,
      fullScreen,
      maxWidth,
    },
    setDialog,
  ] = useState<Props>(initDialog)

  useEffect(() => {
    const subscription = dialogSubject.subscribe((data: Props) =>
      setDialog(data)
    )
    return () => subscription.unsubscribe()
  }, [])

  const handleDisagree = () => {
    if (onClose) onClose()
    setDialog(initDialog)
  }
  const handleAgree = () => {
    if (onAgree) onAgree()
    setDialog(initDialog)
  }

  return open ? (
    <Dialog open={true} fullScreen={fullScreen} maxWidth={maxWidth}>
      <DialogContent>{content}</DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button variant="contained" onClick={handleDisagree}>
          {closeText || formatMessage(messages.disagree)}
        </Button>
        {(onAgree || agreeText) && (
          <Button variant="outlined" onClick={handleAgree}>
            {agreeText || formatMessage(messages.agree)}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  ) : null
}

GlobalDialog.open = function (props: Props) {
  dialogSubject.next({
    ...props,
    open: true,
  })
}

GlobalDialog.close = function () {
  dialogSubject.next(initDialog)
}

export default GlobalDialog
