import { Breadcrumb } from '@components/ContentHeader'
import { toUserRoute, userPath } from '@src/common/appConfig'
import { DeviceEnroll, DeviceKeys, WifiType } from '@src/user/models/device'
import messages from '@src/user/utils/messages'

export const BREADCRUMBS: Breadcrumb[] = [
  { title: messages.agency, route: toUserRoute(userPath.agencyList.list) },
]

export const DEFAULT_DEVICE_NAME = {
  [DeviceKeys.DeviceName]: undefined,
  [DeviceKeys.AutoIncrease]: undefined,
  [DeviceKeys.Serial]: undefined,
}

export const DEFAULT_DEVICE_SERIAL = {
  [DeviceKeys.AutoIncrease]: undefined,
  [DeviceKeys.Serial]: undefined,
}

export const AUTO_INCREASE_ENABLE = {
  [DeviceKeys.AutoIncrease]: false,
  [DeviceKeys.Serial]: undefined,
}

export const DEFAULT_WIFI_SETTING = {
  [DeviceKeys.EAPMethod]: undefined,
  [DeviceKeys.Phase2Method]: undefined,
  [DeviceKeys.Domain]: undefined,
  [DeviceKeys.Cert]: undefined,
  [DeviceKeys.ClientCert]: undefined,
  [DeviceKeys.Identify]: undefined,
  [DeviceKeys.AnonymousIdentify]: undefined,
}

export const genQRcodeSize: () => number = () => {
  const height = window.screen.height
  const width = window.screen.width
  return width > height ? height - 250 : width
}

export const wifiTypeHandler: Record<WifiType, string> = {
  [WifiType.AnonymousLogin]: 'Anonymous Login',
  [WifiType.WEP]: 'WEP',
  [WifiType.PSK]: 'PSK',
  [WifiType.SAE]: 'SAE',
  [WifiType.EAP]: 'EAP',
}

export const toEnrollData: (data: DeviceEnroll) => {
  deviceName?: string
  data: string
} = ({
  [DeviceKeys.Serial]: serial,
  [DeviceKeys.AutoIncrease]: autoIncrease,
  [DeviceKeys.DeviceNameEnable]: deviceNameEnable,
  [DeviceKeys.WifiSettingEnable]: wifiSettingEnable,
  ...res
}) => {
  let deviceName = res[DeviceKeys.DeviceName]

  if (deviceNameEnable) {
    if (autoIncrease) {
      deviceName = `${deviceName}${serial}`
      res[DeviceKeys.DeviceName] = deviceName
    } else {
      res = { ...res, ...DEFAULT_DEVICE_SERIAL }
    }
  } else {
    deviceName = undefined
    res = { ...res, ...DEFAULT_DEVICE_NAME }
  }

  if (!wifiSettingEnable) {
    res = { ...res, [DeviceKeys.WifiSetting]: undefined }
  }
  return { deviceName, data: JSON.stringify(res) }
}

export const covertToStorageEnroll: (data: DeviceEnroll) => DeviceEnroll = (
  data
) => {
  const {
    [DeviceKeys.Serial]: serial,
    [DeviceKeys.AutoIncrease]: autoIncrease,
    [DeviceKeys.DeviceNameEnable]: deviceNameEnable,
    [DeviceKeys.WifiSettingEnable]: wifiSettingEnable,
  } = data

  if (deviceNameEnable) {
    if (autoIncrease) {
      data[DeviceKeys.Serial] = serial! + 1
    } else {
      data = { ...data, ...AUTO_INCREASE_ENABLE }
    }
  } else {
    data = { ...data, ...DEFAULT_DEVICE_NAME }
  }

  if (!wifiSettingEnable) {
    data = { ...data, [DeviceKeys.WifiSetting]: undefined }
  }
  return data
}
