import { adminPath, toAdminRoute } from '@common/appConfig'
import { Breadcrumb } from '@components/ContentHeader'
import messages from '@admin/utils/messages'

export const BREADCRUMBS: Breadcrumb[] = [
  { title: messages.adminManagement },
  { title: messages.adminList },
]

export const DETAIL_BREADCRUMBS: Breadcrumb[] = [
  { title: messages.adminManagement },
  { title: messages.adminList, route: toAdminRoute(adminPath.admin.list) },
]
