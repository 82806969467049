import React from 'react'
import { useIntl } from 'react-intl'
import { Control } from 'react-hook-form'
import {
  Cert,
  ClientCert,
  DeviceEnroll,
  DeviceKeys,
  EAPMethod,
  Phase2Method,
  WifiType,
} from '@src/user/models/device'
import { FieldRow } from '@src/components/DataTable'
import messages from '../messages'
import {
  Condition,
  ControlledSelect,
  ControlledTextInput,
} from '@src/components/hookForm'
import {
  CHARACTER_LIMIT,
  INVALID_FORMAT,
  REQUIRED,
} from '@src/utils/validation'
import { Box } from '@mui/material'
import { wifiTypeHandler } from '../utils'

export default function WifiSetting({
  control,
  disabled = false,
}: {
  control: Control<DeviceEnroll>
  disabled?: boolean
}) {
  const { formatMessage: f } = useIntl()
  const name = DeviceKeys.WifiSetting

  return (
    <Box sx={{ filter: disabled ? 'opacity(25%)' : undefined }}>
      <FieldRow label={f(messages.ssdId)}>
        <ControlledTextInput
          name={`${name}.${DeviceKeys.SSDId}`}
          control={control}
          required={REQUIRED}
          disabled={disabled}
        />
      </FieldRow>
      <FieldRow label={f(messages.wifiType)}>
        <ControlledSelect
          name={`${name}.${DeviceKeys.Type}`}
          options={Object.values(WifiType).map((item) => ({
            label: wifiTypeHandler[item],
            value: item,
          }))}
          control={control}
          required={REQUIRED}
          disabled={disabled}
        />
      </FieldRow>

      <Condition
        control={control}
        name={`${name}.${DeviceKeys.Type}`}
        is={WifiType.WEP}>
        <FieldRow
          label={f(messages.password)}
          children={
            <ControlledTextInput
              name={`${name}.${DeviceKeys.Password}`}
              control={control}
              required={REQUIRED}
              disabled={disabled}
              type="password"
            />
          }
        />
      </Condition>

      <Condition
        control={control}
        name={`${name}.${DeviceKeys.Type}`}
        is={WifiType.PSK}>
        <FieldRow
          label={f(messages.password)}
          children={
            <ControlledTextInput
              name={`${name}.${DeviceKeys.Password}`}
              control={control}
              required={REQUIRED}
              disabled={disabled}
              type="password"
            />
          }
        />
      </Condition>

      <Condition
        control={control}
        name={`${name}.${DeviceKeys.Type}`}
        is={WifiType.SAE}>
        <FieldRow
          label={f(messages.password)}
          children={
            <ControlledTextInput
              name={`${name}.${DeviceKeys.Password}`}
              control={control}
              required={REQUIRED}
              disabled={disabled}
              type="password"
            />
          }
        />
      </Condition>

      <Condition
        control={control}
        name={`${name}.${DeviceKeys.Type}`}
        is={WifiType.EAP}>
        <FieldRow
          label={f(messages.eapMethod)}
          children={
            <ControlledSelect
              name={`${name}.${DeviceKeys.EAPMethod}`}
              control={control}
              options={Object.values(EAPMethod).map((item) => ({
                label: item,
                value: item,
              }))}
              required={REQUIRED}
              disabled={disabled}
            />
          }
        />
        <FieldRow
          label={f(messages.phase2Method)}
          children={
            <ControlledSelect
              name={`${name}.${DeviceKeys.Phase2Method}`}
              control={control}
              options={Object.values(Phase2Method).map((item) => ({
                label: item,
                value: item,
              }))}
              required={REQUIRED}
              disabled={disabled}
            />
          }
        />
        <FieldRow
          label={f(messages.domain)}
          children={
            <ControlledTextInput
              name={`${name}.${DeviceKeys.Domain}`}
              control={control}
              disabled={disabled}
              rules={{
                maxLength: {
                  value: CHARACTER_LIMIT,
                  message: INVALID_FORMAT,
                },
              }}
            />
          }
        />
        <FieldRow
          label={f(messages.cert)}
          children={
            <ControlledSelect
              name={`${name}.${DeviceKeys.Cert}`}
              control={control}
              options={Object.values(Cert).map((item) => ({
                label: item,
                value: item,
              }))}
              required={REQUIRED}
              disabled={disabled}
            />
          }
        />
        <FieldRow
          label={f(messages.clientCert)}
          children={
            <ControlledSelect
              name={`${name}.${DeviceKeys.ClientCert}`}
              control={control}
              options={Object.values(ClientCert).map((item) => ({
                label: item,
                value: item,
              }))}
              required={REQUIRED}
              disabled={disabled}
            />
          }
        />
        <FieldRow
          label={f(messages.identify)}
          children={
            <ControlledTextInput
              name={`${name}.${DeviceKeys.Identify}`}
              control={control}
              disabled={disabled}
            />
          }
        />
        <FieldRow
          label={f(messages.anonymousIdentify)}
          children={
            <ControlledTextInput
              name={`${name}.${DeviceKeys.AnonymousIdentify}`}
              control={control}
              disabled={disabled}
            />
          }
        />
        <FieldRow
          label={f(messages.password)}
          children={
            <ControlledTextInput
              name={`${name}.${DeviceKeys.Password}`}
              control={control}
              required={REQUIRED}
              disabled={disabled}
              type="password"
            />
          }
        />
      </Condition>
    </Box>
  )
}
