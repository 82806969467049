import { createIntl, createIntlCache } from 'react-intl'
import { LANG, LOCALE_MESSAGE } from '../components/LangProvider/LangProvider'
import messages from './messages'

const cache = createIntlCache()
const intl = createIntl(
  {
    locale: LANG,
    messages: LOCALE_MESSAGE,
  },
  cache
)

export const CHARACTER_LIMIT = 60
export const DESCRIPTIONd_LIMIT = 500

export const REQUIRED = intl.formatMessage(messages.required)
export const INVALID_FORMAT = intl.formatMessage(messages.invalidFormat)

export const emailPattern = {
  value: /^[A-Z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Z0-9.-]+$/gi,
  message: intl.formatMessage(messages.invalidEmail),
}
