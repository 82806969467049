import { JSON_CONTENT } from '@src/utils/ajaxUtils'
import authAjax from '@admin/utils/ajaxUtils'
import { objToQueryStr } from '@src/utils/function'
import { ManagerDetail } from '@src/admin/models/manager'

const API_PATH = '/manager'

export const getListAjax = (params: object) =>
  authAjax.get(`${API_PATH}/jump${objToQueryStr(params)}`, JSON_CONTENT)

export const createManagerAjax = (data: object) =>
  authAjax.post(API_PATH, data, JSON_CONTENT)

export const getManagerAjax = (id: string) =>
  authAjax.get(`${API_PATH}/${id}`, JSON_CONTENT)

export const updateManagerAjax = ({
  id,
  data,
}: {
  id: string
  data: Partial<ManagerDetail>
}) => authAjax.put(`${API_PATH}/${id}`, data, JSON_CONTENT)

export const deleteManagerAjax = (id: string) =>
  authAjax.delete(`${API_PATH}/${id}`, JSON_CONTENT)
