import { AnyAction } from 'redux'
import { ofType } from 'redux-observable'
import { empty, map, Observable, switchMap, tap } from 'rxjs'
import {
  DashboardActionType,
  getDashboardSuccessAction,
} from '@user/reducers/dashboard/dashboardActions'
import { catchErrorHandler } from '@user/utils/ajaxUtils'
import { errorSubject, successSubject } from '@src/utils/responseSubject'
import * as dashboardServices from './dashboardServices'

const getDashboard = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(DashboardActionType.GET),
    switchMap(({ payload }) =>
      dashboardServices.getAjax(payload).pipe(
        map((res) => getDashboardSuccessAction(res.response.Message)),
        tap(() =>
          successSubject.next({ type: DashboardActionType.GET_SUCCESS })
        ),
        catchErrorHandler((error) => {
          errorSubject.next({
            type: DashboardActionType.GET_ERROR,
            error: error.response?.message,
          })
          return empty()
        })
      )
    )
  )

const dashboardEpics = [getDashboard]

export default dashboardEpics
