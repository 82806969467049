import React from 'react'
import { Snackbar as MuiSnackbar, Alert } from '@mui/material'

export enum SnackbarState {
  Success = 'success',
  Error = 'error',
  Info = 'info',
  Warning = 'warning',
}

interface Props {
  onClose: () => void
  severity: SnackbarState
  message: string
}

export default function Snackbar({ onClose, severity, message }: Props) {
  return (
    <MuiSnackbar
      open={true}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
      <Alert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </MuiSnackbar>
  )
}
