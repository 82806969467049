import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { vbcPath } from '../common/appConfig'
import Login, { LoginFailed, LoginSuccess } from './Login'

function Vbc() {
  return (
    <Routes>
      <Route path={vbcPath.login.root} element={<Login />} />
      <Route path={vbcPath.login.success} element={<LoginSuccess />} />
      <Route path={vbcPath.login.failed} element={<LoginFailed />} />
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  )
}

export default Vbc
