import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import DataTable from '@src/components/DataTable'
import { managerPath, toManagerRoute } from '@src/common/appConfig'
import commonMessages from '@common/messages'
import {
  UserDashboardDetail,
  UserDashboardKeys,
} from '@src/manager/models/userDashboard'
import { StatusCapsule } from '@src/components/Capsule'
import SubTitle from './SubTitle'

export default function UserInfo({
  userInfo,
}: {
  userInfo: UserDashboardDetail
}) {
  const { formatMessage: f } = useIntl()
  const navigate = useNavigate()
  const { id } = useParams()

  return (
    <>
      <SubTitle
        title={f(commonMessages.basicInfo)}
        onEdit={() => {
          navigate(
            generatePath(toManagerRoute(managerPath.userDashboard.userEdit), {
              id,
            })
          )
        }}
      />
      <DataTable
        dataSets={[
          {
            label: f(commonMessages.name),
            content: userInfo[UserDashboardKeys.Name],
          },
          {
            label: f(commonMessages.status),
            content: (
              <StatusCapsule isActivated={userInfo[UserDashboardKeys.Active]} />
            ),
          },
          {
            label: f(commonMessages.tokenLifeTime),
            content: f(commonMessages.timeMinutes, {
              num: userInfo[UserDashboardKeys.TokenLifeTime],
            }),
          },
          {
            label: f(commonMessages.maxDeviceCount),
            content: f(commonMessages.itemAmount, {
              num: userInfo[UserDashboardKeys.MaxDeviceCount],
            }),
          },
          {
            label: f(commonMessages.registeredDeviceCount),
            content: f(commonMessages.itemAmount, {
              num: userInfo[UserDashboardKeys.RegisteredDeviceCount],
            }),
          },
          {
            label: f(commonMessages.description),
            content: userInfo[UserDashboardKeys.Description],
          },
        ]}
      />
    </>
  )
}
