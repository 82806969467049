import { SidebarTabProps } from '@components/Layout'
import messages from './messages'
import { managerPath } from '@common/appConfig'
import { ReactComponent as IconAgency } from '@src/assets/icons/iconViewList.svg'

export enum StorageKey {
  Token = 'MangerAuthorization',
  Profile = 'MangerProfile',
}

export const SIDEBAR_TABS: SidebarTabProps[] = [
  {
    icon: IconAgency,
    title: messages.agency,
    basePath: managerPath.agencyList.list,
    route: managerPath.agencyList.list,
  },
]

export const AGENCY_REGEXP = /\/\w+\/\w+-\w+/gi
