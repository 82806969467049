import { AnyAction } from 'redux'
import { ofType } from 'redux-observable'
import { catchError, empty, exhaustMap, map, Observable, of, tap } from 'rxjs'
import {
  AccountActionType,
  getProfileSuccessAction,
  logoutErrorAction,
  logoutSuccessAction,
} from '@manager/reducers/account/accountActions'
import { errorSubject, successSubject } from '@src/utils/responseSubject'
import * as accountServices from './accountServices'

const getProfile = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(AccountActionType.GET_PROFILE),
    exhaustMap(() =>
      accountServices.getProfileAjax().pipe(
        map((res) => getProfileSuccessAction(res.response.Message)),
        tap(() =>
          successSubject.next({ type: AccountActionType.GET_PROFILE_SUCCESS })
        ),
        catchError((error) => {
          errorSubject.next({
            type: AccountActionType.GET_PROFILE_ERROR,
            error: error.response?.message,
          })
          return empty()
        })
      )
    )
  )

const logout = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(AccountActionType.LOGOUT),
    exhaustMap(() =>
      accountServices.logoutAjax().pipe(
        map(() => logoutSuccessAction()),
        catchError(() => of(logoutErrorAction()))
      )
    )
  )

const accountEpics = [getProfile, logout]

export default accountEpics
