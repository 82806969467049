import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import DataTableRow, { DataSet } from './DataTableRow'

interface Props {
  title?: string
  dataSets: DataSet[]
  marginTop?: boolean
}

export default function DataTable({
  title,
  dataSets,
  marginTop = true,
}: Props) {
  return (
    <Box mt={marginTop ? 2 : 0}>
      {title && (
        <Typography
          component="div"
          variant="h6"
          sx={{
            marginBottom: 1,
          }}>
          {title}
        </Typography>
      )}
      <Grid
        container
        sx={{
          border: '2px solid',
          borderColor: 'grey.200',
          borderRadius: 1,
        }}>
        {dataSets.map((item, idx) => (
          <DataTableRow key={`${item.label}_${idx}`} {...item} />
        ))}
      </Grid>
    </Box>
  )
}
