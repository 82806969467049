import { defineMessages } from 'react-intl'

const PREFIX = 'common.'
const ERR_PREFIX = 'error.'

export default defineMessages({
  login: {
    id: PREFIX + 'login',
    defaultMessage: 'SIGN IN',
  },
  loginOut: {
    id: PREFIX + 'login_out',
    defaultMessage: 'SIGN OUT',
  },
  termsAndCondition: {
    id: PREFIX + 'terms_and_condition',
    defaultMessage:
      'By signing in, you agree to {terms} and acknowledge the {policy}.',
  },
  accountTerms: {
    id: PREFIX + 'account_terms',
    defaultMessage: 'Account Terms and Conditions',
  },
  privacyPolicy: {
    id: PREFIX + 'privacy_policy',
    defaultMessage: 'Privacy Policy',
  },
  loginSuccess: {
    id: PREFIX + 'login_success',
    defaultMessage:
      'Login successfully.\nPlease close this page, and return to App.',
  },
  loginFailed: {
    id: PREFIX + 'login_failed',
    defaultMessage: 'Login failed, please contact our support.',
  },
  vbcLoginSuccess: {
    id: PREFIX + 'vbc_login_success',
    defaultMessage:
      'Sign-in successful. You can close this page and return to the app.',
  },
  create: {
    id: PREFIX + 'create',
    defaultMessage: 'Create',
  },
  edit: {
    id: PREFIX + 'edit',
    defaultMessage: 'Edit',
  },
  delete: {
    id: PREFIX + 'delete',
    defaultMessage: 'Delete',
  },
  apply: {
    id: PREFIX + 'apply',
    defaultMessage: 'Apply',
  },
  abort: {
    id: PREFIX + 'abort',
    defaultMessage: 'Abort',
  },
  save: {
    id: PREFIX + 'save',
    defaultMessage: 'Save',
  },
  search: {
    id: PREFIX + 'search',
    defaultMessage: 'Search',
  },
  reset: {
    id: PREFIX + 'reset',
    defaultMessage: 'Reset',
  },
  name: {
    id: PREFIX + 'name',
    defaultMessage: 'Name',
  },
  email: {
    id: PREFIX + 'email',
    defaultMessage: 'Email',
  },
  createDate: {
    id: PREFIX + 'create_date',
    defaultMessage: 'Create Date',
  },
  status: {
    id: PREFIX + 'status',
    defaultMessage: 'Status',
  },
  basicInfo: {
    id: PREFIX + 'basic_info',
    defaultMessage: 'Basic Information',
  },
  active: {
    id: PREFIX + 'active',
    defaultMessage: 'Active',
  },
  inactive: {
    id: PREFIX + 'inactive',
    defaultMessage: 'Inactive',
  },
  description: {
    id: PREFIX + 'description',
    defaultMessage: 'Description',
  },
  maxDeviceCount: {
    id: PREFIX + 'max_device_count',
    defaultMessage: 'Max Device Count',
  },
  registeredDeviceCount: {
    id: PREFIX + 'registered_device_count',
    defaultMessage: 'Registered Device Count',
  },
  itemAmount: {
    id: PREFIX + 'item_amount',
    defaultMessage: '{num}',
  },
  timeMinutes: {
    id: PREFIX + 'time_minutes',
    defaultMessage: '{num} Minutes',
  },
  minutes: {
    id: PREFIX + 'minutes',
    defaultMessage: 'Minutes',
  },
  disagree: {
    id: PREFIX + 'disagree',
    defaultMessage: 'Disagree',
  },
  agree: {
    id: PREFIX + 'agree',
    defaultMessage: 'Agree',
  },
  close: {
    id: PREFIX + 'close',
    defaultMessage: 'Close',
  },
  confirm: {
    id: PREFIX + 'confirm',
    defaultMessage: 'Confirm',
  },
  unauthorized: {
    id: ERR_PREFIX + 'unauthorized',
    defaultMessage: 'Unauthorized',
  },
  expired: {
    id: ERR_PREFIX + 'expired',
    defaultMessage: 'Expired',
  },
  unknownError: {
    id: ERR_PREFIX + 'unknown_error',
    defaultMessage: 'Unknown Error',
  },
  createSuccess: {
    id: PREFIX + 'create_success',
    defaultMessage: 'Create Success',
  },
  createFailed: {
    id: PREFIX + 'create_failed',
    defaultMessage: 'Create Failed',
  },
  updateSuccess: {
    id: PREFIX + 'update_success',
    defaultMessage: 'Update Success',
  },
  updateFailed: {
    id: PREFIX + 'update_failed',
    defaultMessage: 'Update Failed',
  },
  deleteSuccess: {
    id: PREFIX + 'delete_success',
    defaultMessage: 'Delete Success',
  },
  deleteFailed: {
    id: PREFIX + 'delete_failed',
    defaultMessage: 'Delete Failed',
  },
  tokenLifeTime: {
    id: PREFIX + 'token_life_time',
    defaultMessage: 'Quick login life time',
  },
  defaultCFG: {
    id: PREFIX + 'dashboard.defaultCFG',
    defaultMessage: 'Default Config',
  },
  denyCFG: {
    id: PREFIX + 'dashboard.denyCFG',
    defaultMessage: 'Deny Config',
  },
  cfgCode: {
    id: PREFIX + 'dashboard.cfg_code',
    defaultMessage: 'Code',
  },
  memberList: {
    id: PREFIX + 'dashboard.member_list',
    defaultMessage: 'Member List',
  },
  confirmDeleteUser: {
    id: PREFIX + 'dashboard.confirm_delete',
    defaultMessage: 'Would you want to delete this user?',
  },
  confirmDeleteConfig: {
    id: PREFIX + 'dashboard.confirm_delete_config',
    defaultMessage: 'Would you want to delete this config?',
  },
  confirmDeleteDevice: {
    id: PREFIX + 'dashboard.confirm_delete_device',
    defaultMessage: 'Would you want to delete this device?',
  },
  confirmDeleteMember: {
    id: PREFIX + 'dashboard.confirm_delete_member',
    defaultMessage: 'Would you want to delete this Member?',
  },
  activatedDevice: {
    id: PREFIX + 'dashboard.activated_device',
    defaultMessage: 'Activated Devices',
  },
  deviceSN: {
    id: PREFIX + 'device.device_SN',
    defaultMessage: 'SN',
  },
  deviceType: {
    id: PREFIX + 'device.device_type',
    defaultMessage: 'Device Type',
  },
})
