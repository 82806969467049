export enum AdminKeys {
  Name = 'FullName',
  Id = 'ID',
  Email = 'Email',
  Active = 'Active',
}

export interface AdminDetail {
  [AdminKeys.Id]: string
  [AdminKeys.Email]: string
  [AdminKeys.Name]: string
  [AdminKeys.Active]: boolean
}
