import { PAGE_LIMIT } from '@common/constants'
import { MapKeys } from '@src/user/models/map'
import { _range } from '@src/utils/function'

export const mockMapList = {
  count: 10,
  items: _range(0, PAGE_LIMIT).map((num) => ({
    [MapKeys.Id]: `map_${num}`,
    [MapKeys.UserId]: 'hx_no_1',
    [MapKeys.MapName]: `Map Name ${num}`,
    [MapKeys.CreateTime]: '2022-04-08T10:29:59.138Z',
    [MapKeys.FromDeviceName]: 'FromDeviceName',
    [MapKeys.FromDeviceSN]: 'FromDeviceSN',
    [MapKeys.Description]: 'Description',
    [MapKeys.DownloadLink]: 'DownloadLink',
  })),
}
