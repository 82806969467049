import React from 'react'
import { UseControllerProps } from 'react-hook-form'
import { TextField } from '@mui/material'
import FieldController from './FieldController'

interface Props<T> extends UseControllerProps<T> {
  required?: boolean | string
  disabled?: boolean
  fullWidth?: boolean
}

export default function ControlledTextArea<T>({
  fullWidth = true,
  required = false,
  disabled = false,
  rules,
  ...rest
}: Props<T>) {
  return (
    <FieldController
      rules={{ required: disabled ? undefined : required, ...rules }}
      {...rest}>
      <TextField
        fullWidth={fullWidth}
        disabled={disabled}
        multiline
        maxRows={5}
      />
    </FieldController>
  )
}
