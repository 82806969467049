import React from 'react'
import { useIntl } from 'react-intl'
import { MemberDetail, DashboardKeys } from '@user/models/dashboard'
import { Box, IconButton } from '@mui/material'
import { StatusCapsule } from '@components/Capsule'
import ListTable from '@src/components/ListTable'
import GlobalDialog from '@src/components/GlobalDialog'
import { ReactComponent as IconDelete } from '@src/assets/icons/iconDelete.svg'
import { ReactComponent as IconEdit } from '@src/assets/icons/iconEdit.svg'
import SubTitle from './SubTitle'
import commonMessages from '@common/messages'
import messages from '../messages'

export default function MemberTable({
  memberList,
  onCreate,
  onEdit,
  onDelete,
}: {
  memberList: MemberDetail[]
  onCreate: () => void
  onEdit: (email: string) => void
  onDelete: (email: string) => void
}) {
  const { formatMessage: f } = useIntl()

  const handleDelete = (id: string) => {
    onDelete(id)
  }

  const beforeDelete = (id: string) => {
    GlobalDialog.open({
      content: f(messages.confirmDeleteMember),
      onAgree: () => handleDelete(id),
    })
  }

  return (
    <>
      <SubTitle title={f(messages.memberList)} onCreate={onCreate} />
      <ListTable
        noMarginTop
        dataList={memberList}
        theadList={[
          {
            id: DashboardKeys.Name,
            label: f(commonMessages.name),
          },
          {
            id: DashboardKeys.Email,
            label: f(commonMessages.email),
          },
          {
            id: DashboardKeys.Active,
            label: f(commonMessages.status),
            formatter: (isActivated: boolean) => (
              <StatusCapsule isActivated={isActivated} />
            ),
          },
          {
            id: DashboardKeys.Description,
            label: f(commonMessages.description),
          },
          {
            id: DashboardKeys.Email,
            label: '',
            formatter: (id: string) => (
              <Box display="flex" flexDirection="row-reverse">
                <IconButton
                  sx={({ palette }) => ({
                    marginLeft: 1,
                    bgcolor: palette.error.main,
                    '&>svg': { width: 15, height: 15 },
                  })}
                  onClick={(e) => beforeDelete(id)}>
                  <IconDelete />
                </IconButton>
                <IconButton
                  sx={({ palette }) => ({
                    bgcolor: palette.primary.main,
                    '&>svg': { width: 15, height: 15 },
                  })}
                  onClick={() => onEdit(id)}>
                  <IconEdit />
                </IconButton>
              </Box>
            ),
          },
        ]}
      />
    </>
  )
}
