import { AnyAction } from 'redux'
import { ofType } from 'redux-observable'
import {
  delay,
  empty,
  exhaustMap,
  from,
  iif,
  map,
  mergeMap,
  Observable,
  of,
  repeatWhen,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs'
import { AjaxResponse } from 'rxjs/ajax'
import {
  createAgencyFailedAction,
  createAgencySuccessAction,
  deleteAgencySuccessAction,
  getListSuccessAction,
  stopPollingListAction,
  AgencyActionType,
} from '@manager/reducers/agency/agencyActions'
import { errorSubject, successSubject } from '@src/utils/responseSubject'
import { catchErrorHandler } from '@manager/utils/ajaxUtils'
import { POLLING_TIME, SUCCESS_BRIEF_CODE } from '@src/utils/ajaxUtils'
import * as agencyServices from './agencyServices'

const getList = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(AgencyActionType.GET_LIST),
    switchMap(({ payload }) =>
      agencyServices.getListAjax(payload).pipe(
        map((res) => getListSuccessAction(res.response.Message)),
        tap(() =>
          successSubject.next({ type: AgencyActionType.GET_LIST_SUCCESS })
        ),
        catchErrorHandler((error) => {
          errorSubject.next({
            type: AgencyActionType.GET_LIST_ERROR,
            error: error.response?.message,
          })
          return empty()
        })
      )
    )
  )

const pollList = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(AgencyActionType.START_POLLING_LIST),
    exhaustMap((action) => {
      const payload = action.payload
      return of(action).pipe(
        mergeMap(() =>
          from(agencyServices.getListAjax(payload)).pipe(
            mergeMap((res) => of(getListSuccessAction(res.response.Message))),
            repeatWhen((complete$) => complete$.pipe(delay(POLLING_TIME))),
            catchErrorHandler(
              () => of(stopPollingListAction()),
              [stopPollingListAction()]
            )
          )
        ),
        takeUntil(action$.pipe(ofType(AgencyActionType.STOP_POLLING_LIST)))
      )
    })
  )

const createAgency = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(AgencyActionType.CREATE_AGENCY),
    exhaustMap(({ payload }) =>
      agencyServices.createAgencyAjax(payload).pipe(
        mergeMap((result: AjaxResponse<any>) =>
          iif(
            () => result?.response?.BriefCode === SUCCESS_BRIEF_CODE,
            of(createAgencySuccessAction()).pipe(
              tap(() =>
                successSubject.next({
                  type: AgencyActionType.CREATE_AGENCY_SUCCESS,
                })
              )
            ),
            of(createAgencyFailedAction()).pipe(
              tap(() =>
                errorSubject.next({
                  type: AgencyActionType.CREATE_AGENCY_ERROR,
                  error: result?.response?.Message,
                })
              )
            )
          )
        ),
        catchErrorHandler((error) => {
          errorSubject.next({
            type: AgencyActionType.CREATE_AGENCY_ERROR,
            error: error.response?.data,
          })
          return empty()
        })
      )
    )
  )

const deleteAgency = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(AgencyActionType.DELETE_AGENCY),
    exhaustMap(({ payload }) =>
      agencyServices.deleteAgencyAjax(payload).pipe(
        map((res) => deleteAgencySuccessAction()),
        tap(() =>
          successSubject.next({
            type: AgencyActionType.DELETE_AGENCY_SUCCESS,
          })
        ),
        catchErrorHandler((error) => {
          errorSubject.next({
            type: AgencyActionType.DELETE_AGENCY_ERROR,
            error: error.response?.data,
          })
          return empty()
        })
      )
    )
  )

const agencyEpics = [getList, pollList, createAgency, deleteAgency]

export default agencyEpics
