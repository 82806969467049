import { defineMessages } from 'react-intl'

const PREFIX = 'validation.'

export default defineMessages({
  required: {
    id: PREFIX + 'required',
    defaultMessage: 'Field required',
  },
  invalidEmail: {
    id: PREFIX + 'invalid_mail',
    defaultMessage: 'Invalid Email',
  },
  invalidFormat: {
    id: PREFIX + 'invalid_format',
    defaultMessage: 'Invalid Format',
  },
})
