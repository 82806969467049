import { Agency } from '@src/manager/models/agency'

export enum AgencyActionType {
  GET_LIST = '@agency/GET_LIST',
  GET_LIST_SUCCESS = '@agency/GET_LIST_SUCCESS',
  GET_LIST_ERROR = '@agency/GET_LIST_ERROR',

  START_POLLING_LIST = '@agency/START_POLLING_LIST',
  STOP_POLLING_LIST = '@agency/STOP_POLLING_LIST',

  CREATE_AGENCY = '@agency/CREATE_AGENCY',
  CREATE_AGENCY_SUCCESS = '@agency/CREATE_AGENCY_SUCCESS',
  CREATE_AGENCY_ERROR = '@agency/CREATE_AGENCY_ERROR',

  DELETE_AGENCY = '@userDashboard/DELETE_AGENCY',
  DELETE_AGENCY_SUCCESS = '@userDashboard/DELETE_AGENCY_SUCCESS',
  DELETE_AGENCY_ERROR = '@userDashboard/DELETE_AGENCY_ERROR',
}

export interface ListParams {
  Count: number
  Items: Agency[]
}

export const getListAction = (payload: Object) => ({
  type: AgencyActionType.GET_LIST,
  payload,
})

export const getListSuccessAction = (payload: Object) => ({
  type: AgencyActionType.GET_LIST_SUCCESS,
  payload,
})

export const startPollingListAction = (payload: Object) => ({
  type: AgencyActionType.START_POLLING_LIST,
  payload,
})

export const stopPollingListAction = () => ({
  type: AgencyActionType.STOP_POLLING_LIST,
})

export const createAgencyAction = (payload: Partial<Agency>) => ({
  type: AgencyActionType.CREATE_AGENCY,
  payload,
})

export const createAgencySuccessAction = () => ({
  type: AgencyActionType.CREATE_AGENCY_SUCCESS,
})

export const createAgencyFailedAction = () => ({
  type: AgencyActionType.CREATE_AGENCY_ERROR,
})

export const deleteAgencyAction = (payload: string) => ({
  type: AgencyActionType.DELETE_AGENCY,
  payload,
})

export const deleteAgencySuccessAction = () => ({
  type: AgencyActionType.DELETE_AGENCY_SUCCESS,
})

export const deleteAgencyErrorAction = () => ({
  type: AgencyActionType.DELETE_AGENCY_ERROR,
})
