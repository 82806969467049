export enum AccountKeys {
  Email = 'Email',

  Name = 'Name',
}

export enum AccountType {
  Google = 'google',
  Viveport = 'htc',
}

export interface Profile {
  [AccountKeys.Email]: string
  [AccountKeys.Name]?: string
}
