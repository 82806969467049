import { combineReducers } from 'redux'
import accountReducer, {
  AccountPreloadState,
  AccountState,
} from './account/accountReducer'
import adminReducer, {
  AdminPreloadState,
  AdminState,
} from './admin/adminReducers'
import managerReducer, {
  ManagerPreloadState,
  ManagerState,
} from './manager/managerReducers'

export interface StoreState {
  account: AccountState
  admin: AdminState
  manager: ManagerState
}

export const storePreloadState: StoreState = {
  account: AccountPreloadState,
  admin: AdminPreloadState,
  manager: ManagerPreloadState,
}

export const rootReducer = combineReducers<StoreState>({
  account: accountReducer,
  admin: adminReducer,
  manager: managerReducer,
})
