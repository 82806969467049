import { AnyAction } from 'redux'
import { ofType } from 'redux-observable'
import { empty, map, Observable, switchMap, tap } from 'rxjs'
import {
  getListSuccessAction,
  AgencyActionType,
} from '@user/reducers/agency/agencyActions'
import { errorSubject, successSubject } from '@src/utils/responseSubject'
import { catchErrorHandler } from '@user/utils/ajaxUtils'
import * as agencyServices from './agencyServices'

const getList = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(AgencyActionType.GET_LIST),
    switchMap(({ payload }) =>
      agencyServices.getListAjax(payload).pipe(
        map((res) => getListSuccessAction(res.response.Message)),
        tap(() =>
          successSubject.next({ type: AgencyActionType.GET_LIST_SUCCESS })
        ),
        catchErrorHandler((error) => {
          errorSubject.next({
            type: AgencyActionType.GET_LIST_ERROR,
            error: error.response?.message,
          })
          return empty()
        })
      )
    )
  )

const agencyEpics = [getList]

export default agencyEpics
