import { Agency } from '@user/models/agency'

export enum AgencyActionType {
  GET_LIST = '@agency/GET_LIST',
  GET_LIST_SUCCESS = '@agency/GET_LIST_SUCCESS',
  GET_LIST_ERROR = '@agency/GET_LIST_ERROR',
}

export interface ListParams {
  Count: number
  Items: Agency[]
}

export const getListAction = (payload: Object) => ({
  type: AgencyActionType.GET_LIST,
  payload,
})

export const getListSuccessAction = (payload: Object) => ({
  type: AgencyActionType.GET_LIST_SUCCESS,
  payload,
})
