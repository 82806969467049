import { AnyAction } from 'redux'
import { ofType } from 'redux-observable'
import {
  delay,
  empty,
  exhaustMap,
  from,
  iif,
  map,
  mergeMap,
  Observable,
  of,
  repeatWhen,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs'
import {
  createManagerFailedAction,
  createManagerSuccessAction,
  deleteManagerSuccessAction,
  getListSuccessAction,
  getManagerSuccessAction,
  ManagerActionType,
  stopPollingListAction,
  stopPollingManagerAction,
  updateManagerFailedAction,
  updateManagerSuccessAction,
} from '@src/admin/reducers/manager/managerActions'
import { errorSubject, successSubject } from '@src/utils/responseSubject'
import * as managerServices from './managerServices'
import { catchErrorHandler } from '@src/admin/utils/ajaxUtils'
import { toCamelCase } from '@src/utils/function'
import { POLLING_TIME, SUCCESS_BRIEF_CODE } from '@src/utils/ajaxUtils'
import { AjaxResponse } from 'rxjs/ajax'

const getManagerList = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(ManagerActionType.GET_LIST),
    switchMap(({ payload }) =>
      managerServices.getListAjax(payload).pipe(
        map((res) => getListSuccessAction(res.response.Message)),
        tap(() =>
          successSubject.next({ type: ManagerActionType.GET_LIST_SUCCESS })
        ),
        catchErrorHandler((error) => {
          errorSubject.next({
            type: ManagerActionType.GET_LIST_ERROR,
            error: error.response?.data,
          })
          return empty()
        })
      )
    )
  )

const pollManagerList = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(ManagerActionType.START_POLLING_LIST),
    exhaustMap((action) =>
      of(action).pipe(
        delay(1000),
        mergeMap(({ payload }) =>
          from(managerServices.getListAjax(payload)).pipe(
            mergeMap((res) => of(getListSuccessAction(res.response.Message))),
            repeatWhen((complete$) => complete$.pipe(delay(POLLING_TIME))),
            catchErrorHandler(
              () => of(stopPollingListAction),
              [stopPollingListAction()]
            )
          )
        ),
        takeUntil(action$.pipe(ofType(ManagerActionType.STOP_POLLING_LIST)))
      )
    )
  )

const createManager = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(ManagerActionType.CREATE_MANAGER),
    exhaustMap(({ payload }) =>
      managerServices.createManagerAjax(toCamelCase(payload)).pipe(
        mergeMap((result: AjaxResponse<any>) =>
          iif(
            () => result?.response?.briefCode === SUCCESS_BRIEF_CODE,
            of(createManagerSuccessAction()).pipe(
              tap(() =>
                successSubject.next({
                  type: ManagerActionType.CREATE_MANAGER_SUCCESS,
                })
              )
            ),
            of(createManagerFailedAction()).pipe(
              tap(() =>
                errorSubject.next({
                  type: ManagerActionType.CREATE_MANAGER_ERROR,
                  error: result?.response?.message,
                })
              )
            )
          )
        ),
        catchErrorHandler((error) => {
          errorSubject.next({
            type: ManagerActionType.CREATE_MANAGER_ERROR,
            error: error.response?.data,
          })
          return empty()
        })
      )
    )
  )

const getManager = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(ManagerActionType.GET_MANAGER),
    switchMap(({ payload }) =>
      managerServices.getManagerAjax(payload).pipe(
        map((res) => getManagerSuccessAction(res.response.Message)),
        tap((res) =>
          successSubject.next({
            type: ManagerActionType.GET_MANAGER_SUCCESS,
            message: res.payload,
          })
        ),
        catchErrorHandler((error) => {
          errorSubject.next({
            type: ManagerActionType.GET_MANAGER_ERROR,
            error: error.response?.data,
          })
          return empty()
        })
      )
    )
  )

const pollManager = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(ManagerActionType.START_POLLING_MANAGER),
    exhaustMap((action) =>
      of(action).pipe(
        delay(1000),
        mergeMap(({ payload }) =>
          from(managerServices.getManagerAjax(payload)).pipe(
            mergeMap((res) =>
              of(getManagerSuccessAction(res.response.Message))
            ),
            repeatWhen((complete$) => complete$.pipe(delay(POLLING_TIME))),
            catchErrorHandler(
              () => of(stopPollingManagerAction),
              [stopPollingManagerAction()]
            )
          )
        ),
        takeUntil(action$.pipe(ofType(ManagerActionType.STOP_POLLING_MANAGER)))
      )
    )
  )

const updateManager = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(ManagerActionType.UPDATE_MANAGER),
    exhaustMap(({ payload: { id, data } }) =>
      managerServices.updateManagerAjax({ id, data: toCamelCase(data) }).pipe(
        mergeMap((result: AjaxResponse<any>) =>
          iif(
            () => result?.response?.briefCode === SUCCESS_BRIEF_CODE,
            of(updateManagerSuccessAction()).pipe(
              tap(() =>
                successSubject.next({
                  type: ManagerActionType.UPDATE_MANAGER_SUCCESS,
                })
              )
            ),
            of(updateManagerFailedAction()).pipe(
              tap(() =>
                errorSubject.next({
                  type: ManagerActionType.UPDATE_MANAGER_ERROR,
                  error: result?.response?.message,
                })
              )
            )
          )
        ),
        catchErrorHandler((error) => {
          errorSubject.next({
            type: ManagerActionType.UPDATE_MANAGER_ERROR,
            error: error.response?.data,
          })
          return empty()
        })
      )
    )
  )

const deleteManager = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(ManagerActionType.DELETE_MANAGER),
    exhaustMap(({ payload }) =>
      managerServices.deleteManagerAjax(payload).pipe(
        map((res) => deleteManagerSuccessAction()),
        tap(() =>
          successSubject.next({
            type: ManagerActionType.DELETE_MANAGER_SUCCESS,
          })
        ),
        catchErrorHandler((error) => {
          errorSubject.next({
            type: ManagerActionType.DELETE_MANAGER_ERROR,
            error: error.response?.data,
          })
          return empty()
        })
      )
    )
  )

const managerEpics = [
  getManagerList,
  pollManagerList,
  createManager,
  getManager,
  pollManager,
  updateManager,
  deleteManager,
]

export default managerEpics
